import React from "react";

// import ant design
import { Spin } from "antd";

class LoadingBlock extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{
          height: this.props.height ? this.props.height : "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
}

export default LoadingBlock;
