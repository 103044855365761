import React, { Component } from "react";
import { Col, Row } from "antd";
import Settings from "../Config/Settings";

class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleColor: this.props.titleColor,
      matches: window.matchMedia("(min-width: 750px)").matches,
    };
  }
  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 750px)").addListener(handler);
  }
  render() {
    const {
      background,
      title,
      borderRadius,
      fontSizeTitle,
      style,
      version,
      rightImage,
    } = this.props;
    const titleStyle = {
      color: this.state.titleColor
        ? this.state.titleColor
        : Settings.colors.darkGrey,
      fontWeight: this.state.titleColor ? "bold" : "600",
      fontSize: fontSizeTitle ? fontSizeTitle : "28px",
    };
    const wrapperStyle = {
      borderRadius: borderRadius ? borderRadius : "4px",
      borderBottom: borderRadius ? "none" : `4px solid ${Settings.colors.cyan}`,
    };
    return (
      <>
        <div
          style={{
            background: background,
            ...styles.wrapper,
            ...wrapperStyle,
            ...style,
          }}
        >
          <img
            style={{
              zIndex: 0,
              position: "absolute",
              right: 35,
              top: 15,
              paddingBottom: "15px",
              height: "95%",
              display: this.state.matches ? "block" : "none",
            }}
            src={rightImage}
          ></img>

          {title && (
            <Row
              style={{
                marginBottom: "5px",
                padding: "0px 15px",
                marginTop: "15px",
                paddingBottom: "10px",
              }}
            >
              <Col span={24}>
                <h2
                  style={{
                    ...styles.title,
                    ...titleStyle,
                    width: this.state.matches ? "50%" : "100%",
                  }}
                >
                  {title}
                </h2>
              </Col>
            </Row>
          )}
          <Row style={{ marginBottom: "15px" }}>
            <Col span={24}>{this.props.children}</Col>
          </Row>
        </div>
      </>
    );
  }
}

const styles = {
  wrapper: {
    marginLeft: "-15px",
    marginRight: "-15px",
    // padding: "25px 40px",
    paddingTop: "25px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "25px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    borderTop: `1px solid ${Settings.colors.lightGrey}`,
    borderBottom: `1px solid ${Settings.colors.lightGrey}`,
    zIndex: 1,
  },
  title: {
    //fontWeight: "600",
    //fontSize: "28px",
    margin: 0,
    padding: 0,
  },
};

export default Panel;
