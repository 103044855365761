import React, { Component } from "react";
import { Tag } from "antd";
import Settings from "../Config/Settings";
class Chip extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  _format = (titleFormat) => {
    if (titleFormat) {
      return (
        // titleFormat.charAt(0).toUpperCase() + titleFormat.slice(1).toLowerCase()
        titleFormat.toUpperCase()
      );
    }
    return "";
  };
  render() {
    const { title, onClick, backgroundColor } = this.props;

    const colorStyle = {
      backgroundColor: backgroundColor
        ? backgroundColor
        : Settings.colors.darkGrey,
      border: backgroundColor || "none",
    };

    return (
      <Tag style={{ ...styles.tag, ...colorStyle }} onClick={onClick}>
        {this._format(title)}
      </Tag>
    );
  }
}

const styles = {
  tag: {
    fontSize: "14px",
    color: Settings.colors.white,
    cursor: "pointer",
    margin: 0
  },
};

export default Chip;
