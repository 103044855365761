// This is the default state of the app i.e. when the app starts for the first time
const initialState = {
  orders: null,
};

// This is a reducer which listens to actions and modifies the state
const OrdiniReducer = (state = initialState, action) => {
  // A switch is used since if more actions are added in the future, it will be easy
  // to be able to handle this in the reducer since we just add another 'case'.
  switch (action.type) {
    case "SET_ORDERS":
      return {
        ...state,
        orders: action.payload.orders,
        toReadNumber: action.payload.toReadNumber,
      };
    default:
      return state;
  }
};

// export reducer
export default OrdiniReducer;
