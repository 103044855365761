import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  Row,
  Col,
  Input,
  Select,
  notification,
  Divider,
} from "antd";
import { Form, Formik } from "formik";

import React, { Component } from "react";
import { connect } from "react-redux";
import Settings from "../Config/Settings";
import { validationSchemaAggiungiIndirizzo } from "../Config/Validation";
import { postIndirizzi } from "../Controllers/Utente";

const comuni_json = require("../Assets/comuni.json");
const { Option } = Select;

class AggiungiIndirizzo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_visible: false,
      aggiungi_loading: false,
      act_indirizzi: this.props.auth.user.indirizzi,
    };
  }

  openModal = () => {
    this.setState({ modal_visible: true });
  };

  closeModal = () => {
    this.setState({ modal_visible: false });
  };

  _aggiungiIndirizzo = async (values, { setSubmitting }) => {
    const { indirizzo, cap, citta, civico } = values;

    this.setState({ aggiungi_loading: true });
    setSubmitting(true);
    try {
      const res = await postIndirizzi({
        id: this.props.auth.user.uid,
        indirizzi: [
          ...this.state.act_indirizzi,
          {
            indirizzo: indirizzo.trim(),
            cap: cap,
            citta: citta,
            civico: civico.trim(),
          },
        ],
      });
      this.props.setIndirizzi([
        ...this.state.act_indirizzi,
        {
          indirizzo: indirizzo.trim(),
          cap: cap,
          citta: citta,
          civico: civico.trim(),
        },
      ]);

      notification.destroy();
      notification.success({
        message: "Indirizzo aggiunto",
        description: `Hai aggiunto con successo l'indirizzo ${indirizzo.trim()} ${civico.trim()}, ${citta} ${cap}`,
      });
      this.setState({
        modal_visible: false,
        act_indirizzi: [
          ...this.state.act_indirizzi,
          {
            indirizzo: indirizzo.trim(),
            cap: cap,
            citta: citta,
            civico: civico.trim(),
          },
        ],
      });
    } catch (error) {
      notification.destroy();
      notification.error({
        message: "Errore!",
        description:
          "Errore nell'aggiunta dell'indirizzo, riprova o contatta l'assistenza.",
      });
    } finally {
      this.setState({ aggiungi_loading: false });
    }
  };

  render() {
    const { modal_visible } = this.state;
    const { setIndirizzi } = this.props;
    return (
      <>
        <Modal
          style={{ zIndex: 1000 }}
          destroyOnClose
          footer={null}
          onCancel={this.closeModal}
          transitionName="zoom"
          title="Aggiungi indirizzo"
          visible={modal_visible}
        >
          <Formik
            initialValues={{}}
            validationSchema={validationSchemaAggiungiIndirizzo}
            validateOnMount={false}
            validateOnChange={true}
            onSubmit={this._aggiungiIndirizzo}
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isValid,
              isSubmitting,
              dirty,
            }) => (
              <Form>
                <Row gutter={[15, 15]}>
                  <Col span={16}>
                    <span style={styles.inputLabel}>Indirizzo</span>
                    <Input
                      placeholder="Inserisci indirizzo"
                      value={values.indirizzo}
                      onChange={handleChange("indirizzo")}
                      onBlur={handleBlur("indirizzo")}
                    />
                    <span style={styles.inputError}>
                      {touched.indirizzo && errors.indirizzo}
                    </span>
                  </Col>
                  <Col span={8}>
                    <span style={styles.inputLabel}>Civico</span>
                    <Input
                      placeholder="Inserisci civico"
                      value={values.civico}
                      onChange={handleChange("civico")}
                      onBlur={handleBlur("civico")}
                    />
                    <span style={styles.inputError}>
                      {touched.civico && errors.civico}
                    </span>
                  </Col>
                </Row>
                <Row gutter={[15, 15]}>
                  <Col span={16}>
                    <span style={styles.inputLabel}>Città</span>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={(value) => {
                        setFieldValue("citta", value);
                        let comune = comuni_json.find((i) => i.nome == value);
                        setFieldValue(
                          "cap",
                          comune && Array.isArray(comune.cap)
                            ? comune.cap[0]
                            : comune.cap
                        );
                        this.setState({
                          selected_citta_caps:
                            comune && Array.isArray(comune.cap)
                              ? comune.cap
                              : [comune.cap],
                        });
                        setFieldValue("provincia", comune.provincia.nome);
                      }}
                      size="default"
                      placeholder="Scegli la città"
                      style={{ width: "100%" }}
                      onBlur={handleBlur("citta")}
                      value={values.citta}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {comuni_json.map((c) => (
                        <Option key={c.nome}>{c.nome}</Option>
                      ))}
                    </Select>
                    <span style={styles.inputError}>
                      {touched.citta && errors.citta}
                    </span>
                  </Col>
                  <Col span={8}>
                    <span style={styles.inputLabel}>CAP</span>
                    <Select
                      onChange={(value) => setFieldValue("cap", value)}
                      size="default"
                      placeholder="Seleziona il CAP"
                      style={{ width: "100%" }}
                      onBlur={handleBlur("cap")}
                      value={values.cap}
                    >
                      {values.citta
                        ? this.state.selected_citta_caps.map((c) => (
                            <Option key={c}>{c}</Option>
                          ))
                        : null}
                    </Select>
                    <span style={styles.inputError}>
                      {touched.cap && errors.cap}
                    </span>
                  </Col>
                </Row>

                <Divider />
                <Row justify="center" align="middle">
                  <Col span={24}>
                    <Button
                      icon={<PlusOutlined />}
                      loading={this.state.aggiungi_loading}
                      disabled={!(isValid && dirty) || isSubmitting}
                      block={true}
                      size="large"
                      onClick={() => {
                        if (!isValid)
                          notification.error({
                            message: "Errore",
                            description: "Ricontrolla i dati inseriti",
                          });
                        handleSubmit();
                      }}
                      type="primary"
                    >
                      {this.state.aggiungi_loading ? "Attendi..." : "Aggiungi"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal>
        <Button
          icon={<PlusOutlined />}
          type="secondary"
          onClick={this.openModal}
          block
        >
          Aggiungi indirizzo
        </Button>
      </>
    );
  }
}

const styles = {
  inputLabel: {
    paddingBottom: "10px",
    fontWeight: 600,
    color: Settings.colors.darkGrey,
  },
  inputError: {
    color: Settings.colors.red,
    fontSize: "14px",
  },
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, null)(AggiungiIndirizzo);
