import * as Yup from "yup";
export const validationSchemaAggiornaPassword = Yup.object().shape({
  password: Yup.string()
    .required("Inserisci una password")
    .test(
      "len",
      "La password deve contenere almeno 8 caratteri",
      (val) => val && val.toString().length >= 8
    ),
  conferma_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Le password non coincidono")
    .required("Ripeti la password"),
});

export const validationSchemaRegistraUtente = Yup.object().shape({
  nome: Yup.string()
    .matches(/^[A-Za-zÀ-ÿ]+$/i, "Inserisci un nome valido")
    .required("Inserisci un nome"),
  cognome: Yup.string()
    .required("Inserisci un cognome")
    .matches(/^[A-Za-zÀ-ÿ]+$/i, "Inserisci un cognome valido"),
  cellulare: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali o finali")
    .matches(/[1-9]\d{4,14}$/i, "Inserisci un numero di cellulare valido")
    .required("Inserisci il tuo numero di cellulare"),
  email: Yup.string()
    .email("Inserire un'email valida")
    .required("Inserire un'email"),
  password: Yup.string()
    .required("Inserisci una password")
    .test(
      "len",
      "La password deve essere di almeno 8 caratteri",
      (val) => val && val.toString().length >= 8
    ),
  confermaPassword: Yup.string()
    .required("Le password non coincidono")
    .oneOf([Yup.ref("password"), null], "Le password non coincidono"),
  privacy: Yup.bool().oneOf(
    [true],
    "Devi accettare i termini di servizio e la privacy policy"
  ),
  citta: Yup.string().required("Devi inserire una città di residenza"),
});
export const validationSchemaSubmitOrdine = Yup.object().shape({
  consegna: Yup.string()
    .required("Selezionare una modalità di consegna")
    .oneOf(["Ritira in negozio", "Consegna a domicilio"]),
  // tipo_pagamento: Yup.string()
  //   .required("Selezionare una modalità di pagamento")
  //   .oneOf([
  //     "carta",
  //     "contante"
  //   ]),
  indirizzo: Yup.string()
    .nullable()
    .when("consegna", {
      is: "Consegna a domicilio",
      then: Yup.string()
        .nullable()
        .required("Inserisci l'indirizzo di consegna"),
    }),
});
export const validationSchemaRecuperaPassword = Yup.object().shape({
  email: Yup.string()
    .email("Inserisci una email valida.")
    .required("Inserire una email."),
});

export const validationSchemaLoginUtente = Yup.object().shape({
  email: Yup.string()
    .email("Inserisci una email valida")
    .required("Inserire una email"),
  password: Yup.string().required("Inserire una password"),
});

export const validationSchemaAggiungiIndirizzo = Yup.object().shape({
  citta: Yup.string().nullable().required("Seleziona la città"),
  cap: Yup.string().nullable().required("Seleziona il CAP"),
  indirizzo: Yup.string()
    .strict(true)
    .required("Inserisci l'indirizzo")
    .max(255, "Il campo può contenere massimo 255 caratteri"),
  civico: Yup.string()
    .strict(true)
    .required("Inserisci il numero civico")
    .max(15, "Il campo può contenere massimo 15 caratteri"),
});
