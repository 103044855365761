import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/app";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";
import Settings from "./Settings";

const {
  REACT_APP_AUTHDOMAIN,
  REACT_APP_DATABASEURL,
  REACT_APP_PROJECTID,
  REACT_APP_STORAGEBUCKET,
  REACT_APP_MESSAGINGSENDERID,
  REACT_APP_APPID,
  REACT_APP_MEASUREMENTID,
} = process.env;

var firebaseConfig = {
  apiKey: Settings.get_google_api_key(),
  authDomain: REACT_APP_AUTHDOMAIN,
  databaseURL: REACT_APP_DATABASEURL,
  projectId: REACT_APP_PROJECTID,
  storageBucket: REACT_APP_STORAGEBUCKET,
  messagingSenderId: REACT_APP_MESSAGINGSENDERID,
  appId: REACT_APP_APPID,
  measurementId: REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// export
export const Db = firebase.firestore();
export const Auth = firebase.auth;

if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
) {
  firebase.app().functions("europe-west1").useEmulator("localhost", 5000);
}

const func = firebase.app().functions("europe-west1");

export const Functions = func;
