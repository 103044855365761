import React, { Component } from "react";
import { Helmet } from "react-helmet";
import OrderList from "../Components/OrderList";
import Header from "../Components/Header";

import Loading from "../Components/Loading";
import SideMenu from "../Components/SideMenu";
import Settings from "../Config/Settings";

class Chats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible_menu: false,
    };
  }

  _closeMenu = () => {
    this.setState({ visible_menu: false });
  };

  _openMenu = () => {
    this.setState({ visible_menu: true });
  };

  render() {
    const { loading, visible_menu } = this.state;

    return (
      <>
        <Helmet>
          <title>Garzone Store - Chat</title>
        </Helmet>
        <Header account onMenuClick={this._openMenu} />
        <SideMenu
          slug={this.props.slug}
          visible_menu={visible_menu}
          _closeMenu={this._closeMenu}
        />
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "400px",
              height: "100%",
              overflowY: "scroll",
              top: 0,
              left: 0,
              padding: "15px",
              paddingTop: "159px",
              zIndex: 9999,
              backgroundColor: Settings.colors.white,
              borderRight: "1px solid",
              borderColor: Settings.colors.lightGrey,
            }}
            className="chatList"
          >
            <OrderList />
          </div>
        </div>

        <Loading loading={loading} />
      </>
    );
  }
}

export default Chats;
