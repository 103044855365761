import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Popover,
  notification,
  Select,
  Image,
  Badge,
  Tooltip,
  Alert,
} from "antd";
import {
  MenuOutlined,
  MessageFilled,
  ShoppingFilled,
  LogoutOutlined,
  LeftCircleOutlined,
  BellOutlined,
} from "@ant-design/icons";
import Settings from "../Config/Settings";
import { logout } from "../Controllers/Auth";
import { Link } from "react-router-dom";
import firebase from "firebase";

// import redux
import { connect } from "react-redux";
import Modal from "antd/lib/modal/Modal";
import ModaleLoginUtente from "./ModaleLoginUtente";
import { getComuni } from "../Controllers/Comuni";
import UserIcon from "./UserIcon";
import { withRouter } from "react-router-dom";
import { isIOS, isMobile, isSafari } from "react-device-detect";

const width = window.innerWidth;
const OneSignal = window.OneSignal || [];

async function getSubscriptionState() {
  var isPushEnabled = await OneSignal.isPushNotificationsEnabled();
  var isOptedOut = await OneSignal.isOptedOut();
  return {
    isPushEnabled: isPushEnabled,
    isOptedOut: isOptedOut,
  };
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      comuni: [],
      loginVisible: false,
      buttonDisabled: false,
      logged: false,
      shopIconActive: true,
      userActionsPopoverVisible: false,
      changeMarketplaceVisible: false,
      opted_out: false,
      partecipants: []
    };
  }

  _initNotificationEnabled = () => {
    const self = this;
    OneSignal.push(function () {
      getSubscriptionState().then(function (state) {
        self.setState({
          enabled: state.isPushEnabled,
          opted_out: state.isOptedOut,
        });
      });
    });
  };
  _clearTags = () => {
    OneSignal.push(function () {
      OneSignal.deleteTags(["uid", "citta"], function (tagsDeleted) {
        console.log("tags deleted", tagsDeleted);
      });
    });
  };
  _subscribeOneSignal = () => {
    if (Notification.permission === "denied") {
      notification.destroy();
      notification.error({
        message: "Errore nelle autorizzazioni del browser",
        description:
          "Devi autorizzare la ricezione delle notifiche dal tuo browser per poter abilitare la funzionalità",
      });
    }
    OneSignal.push(function () {
      OneSignal.showNativePrompt();
    });
  };

  _closeLoginModal = (e) => {
    this.setState({ loginVisible: false });
  };
  _changeLoginState = () => {
    this.setState({ logged: true });
  };

  _onChangeComune = (slug) => {
    this.props.history.push("/comuni/" + slug);
    this.setState({ changeMarketplaceVisible: false });
  };

  _loader = async () => {
    try {
      this.setState({ loadingMarketplaces: true });
      let partecipants = [];
      if (this.state.comuni.length === 0) {
        const comuni = await getComuni();
        comuni.data.map((item, index) =>
          JSON.parse(item.comuni_partecipanti).map(
            (partecipant, indexPart) => partecipants.push({ slug: item.slug, partecipant: partecipant })
          )
        )
        console.log(partecipants)
        partecipants = partecipants.sort((a, b) => (a.partecipant.toLowerCase() > b.partecipant.toLowerCase()) ? 1 : -1)
        console.log(partecipants)
        this.setState({ comuni: comuni.data, partecipants: partecipants });
      }

    } catch (error) {
      console.log(error);
      notification.destroy();
      notification.error({
        message: "Errore!",
        description: "Contatta l'assistenza e descrivi il problema.",
      });
    } finally {
      this.setState({ loadingMarketplaces: false });
    }
  };

  componentDidMount = () => {
    var user = firebase.auth().currentUser;
    if (user) {
      this.setState({ logged: true });
    } else {
      this.setState({ logged: false });
    }
    this._initNotificationEnabled();
    // this._loader();
  };

  render() {
    const { onMenuClick } = this.props;
    return (
      <>
        <div style={styles.wrapper}>
          <Row style={styles.topHeader} align="middle" justify="space-between">
            <Col>
              <Link to="/">
                <img
                  style={styles.logoGarzone}
                  className="logo"
                  src={require("../Assets/logo-cyan.png")}
                />
                <img
                  style={styles.logoGarzone}
                  className="logoMobile"
                  src={require("../Assets/logo-single.jpg")}
                />
              </Link>
            </Col>
            <Col>
              <Row align="middle" justify="end" gutter={30}>
                {this.props.auth.user ? (
                  <>
                    <Col>
                      <Link to="/chats">
                        <Badge count={this.props.chats.toReadNumber}>
                          <Tooltip
                            title="Chat"
                            placement="bottomRight"
                            color={Settings.colors.darkGrey}
                          >
                            <MessageFilled style={styles.headerIcon} />
                          </Tooltip>
                        </Badge>
                      </Link>
                    </Col>
                    {this.state.shopIconActive && (
                      <Col>
                        <Link to="/ordini">
                          <Badge count={this.props.ordini.toReadNumber}>
                            <Tooltip
                              title="Ordini"
                              placement="bottomRight"
                              color={Settings.colors.darkGrey}
                            >
                              <ShoppingFilled style={styles.headerIcon} />
                            </Tooltip>
                          </Badge>
                        </Link>
                      </Col>
                    )}

                    <Col>
                      <Popover
                        placement="bottomRight"
                        content={
                          <Button
                            type="text"
                            block
                            style={{ color: Settings.colors.darkGrey }}
                            icon={<LogoutOutlined />}
                            loading={this.state.logoutLoading}
                            disabled={this.state.logoutLoading}
                            onClick={async () => {
                              this.setState({ logoutLoading: true });
                              try {
                                await logout();
                                this._clearTags();
                                this.setState({ logged: false });
                                notification.destroy();
                                notification.success({
                                  message: "Logout Eseguito!",
                                  description: "Logout eseguito correttamente.",
                                });
                              } catch (error) {
                                notification.destroy();
                                notification.error({
                                  message: "Errore!",
                                  description:
                                    "Errore in fase di logout, riprova.",
                                });
                              } finally {
                                this.setState({ logoutLoading: false });
                              }
                            }}
                          >
                            Esci
                          </Button>
                        }
                        title={
                          <Row
                            justify="left"
                            align="middle"
                            gutter={[15, 0]}
                            style={{ padding: "10px", minWidth: "200px" }}
                          >
                            <Col>
                              <UserIcon
                                size={34}
                                char={
                                  this.props.auth.user &&
                                    this.props.auth.user.nome
                                    ? this.props.auth.user.nome[0]
                                    : null
                                }
                              />
                            </Col>
                            <Col>
                              <Row>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: Settings.colors.darkGrey,
                                  }}
                                >
                                  {this.props.auth.user &&
                                    this.props.auth.user.nome
                                    ? `${this.props.auth.user.nome} ${this.props.auth.user.cognome}`
                                    : null}
                                </span>
                              </Row>
                              <Row>
                                <Link to="/account" style={{ fontSize: 14 }}>
                                  Vedi Profilo
                                </Link>
                              </Row>
                            </Col>
                          </Row>
                        }
                        trigger="click"
                        visible={this.state.userActionsPopoverVisible}
                        handleChangeVisible={(visible) =>
                          this.setState({ userActionsPopoverVisible: visible })
                        }
                      >
                        <UserIcon
                          size={26}
                          style={{
                            backgroundColor: Settings.colors.darkGrey,
                            cursor: "pointer",
                          }}
                          char={
                            this.props.auth.user && this.props.auth.user.nome
                              ? this.props.auth.user.nome[0]
                              : null
                          }
                          onClick={() =>
                            this.setState({
                              userActionsPopoverVisible:
                                !this.state.userActionsPopoverVisible,
                            })
                          }
                        />
                      </Popover>
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Button
                      type="primary"
                      block
                      onClick={() => {
                        this.setState({ loginVisible: true });
                      }}
                    >
                      Accedi
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          {!this.props.homepage && !this.props.account ? (
            <div style={styles.bottomHeader}>
              <Row
                gutter={30}
                align="middle"
                justify="left"
                style={{ boxShadow: "15px solid black" }}
              >
                <Col onClick={onMenuClick} style={styles.hamburgerIcon}>
                  <Row justify="center" align="middle">
                    <MenuOutlined style={styles.menuIcon} />
                  </Row>
                  <Row justify="center" align="middle">
                    <b
                      style={styles.menuText}
                      className="labelMenuBottomHeader"
                    >
                      Menù
                    </b>
                  </Row>
                </Col>

                <Col className="imageBottomHeaderContainer">
                  <Link to={"/comuni/" + this.props.slug}>
                    <img style={styles.logoComune} src={this.props.logo} />
                  </Link>
                </Col>
                <Col style={{ lineHeight: "32px" }}>
                  <Link to={"/comuni/" + this.props.slug}>
                    <h2 style={styles.title} className="titleBottomHeader">
                      {this.props.nome}
                    </h2>
                  </Link>
                  <a
                    style={styles.subtitle}
                    className="subtitleBottomHeader"
                    onClick={() => {
                      this.setState({
                        changeMarketplaceVisible: true,
                      });
                      this._loader();
                    }}
                  >
                    Cambia marketplace
                  </a>

                  <Modal
                    destroyOnClose
                    footer={null}
                    onCancel={() => {
                      this.setState({ changeMarketplaceVisible: false });
                    }}
                    transitionName="zoom"
                    title={
                      <Image
                        style={styles.modalLogo}
                        width="158px"
                        onClick={null}
                        src={require("../Assets/logo-cyan.png")}
                      />
                    }
                    visible={this.state.changeMarketplaceVisible}
                  >
                    <Row justify="center" align="middle" gutter={[15, 15]}>
                      <Col span={24}>
                        <Select
                          disabled={this.state.comuni.length === 0}
                          showSearch
                          size="large"
                          style={{ width: "100%" }}
                          placeholder="Scegli città/comune"
                          optionFilterProp="children"
                          onChange={this._onChangeComune}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {/* {this.state.comuni.length > 0 &&
                            this.state.comuni.map((item, index) =>
                              JSON.parse(item.comuni_partecipanti).map(
                                (partecipant, indexPart) => (
                                  <Select.Option
                                    key={partecipant}
                                    value={item.slug}
                                  >
                                    {partecipant}
                                  </Select.Option>
                                )
                              )
                            )} */}
                          {this.state.partecipants.map(({ slug, partecipant }) => (
                            <Select.Option
                              key={partecipant}
                              value={slug}
                            >
                              {partecipant}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </Modal>
                </Col>
              </Row>
            </div>
          ) : this.props.homepage ? null : this.props.account ? (
            <div style={styles.bottomHeader}>
              <Row
                gutter={30}
                align="middle"
                justify="left"
                style={{ boxShadow: "15px solid black" }}
              >
                <Col
                  onClick={() => {
                    this.props.slug !== null
                      ? this.props.history.push("/comuni/" + this.props.slug)
                      : this.props.history.push("/");
                  }}
                  style={styles.hamburgerIcon}
                >
                  <Row justify="center" align="middle">
                    <Tooltip
                      title="Torna al marketplace"
                      placement="bottomLeft"
                      color={Settings.colors.darkGrey}
                    >
                      <LeftCircleOutlined style={styles.menuIcon} />
                    </Tooltip>
                  </Row>
                </Col>
                <Col className="imageBottomHeaderContainer">
                  {
                    <UserIcon
                      size={76}
                      char={
                        this.props.auth.user && this.props.auth.user.nome
                          ? this.props.auth.user.nome[0]
                          : null
                      }
                    />
                  }
                </Col>
                <Col style={{ lineHeight: "32px" }}>
                  <Link to={"/account"}>
                    <h2 style={styles.title} className="titleBottomHeader">
                      {this.props.auth.user && this.props.auth.user.nome
                        ? `${this.props.auth.user.nome} ${this.props.auth.user.cognome}`
                        : null}
                    </h2>
                    <span
                      style={{
                        ...styles.subtitle,
                        color: Settings.colors.grey,
                      }}
                      className="subtitleBottomHeader"
                    >
                      {this.props.auth.user && this.props.auth.user.email
                        ? this.props.auth.user.email
                        : null}
                    </span>
                  </Link>
                </Col>
              </Row>
            </div>
          ) : null}
          {!isMobile &&
            !isSafari &&
            !isIOS &&
            !this.state.enabled &&
            !this.state.opted_out ? (
            <Row align="middle">
              <Col span={24}>
                <Alert
                  message={
                    <Row
                      justify="space-between"
                      align="middle"
                      style={{ marginBottom: 0 }}
                    >
                      <Col>
                        <BellOutlined
                          style={{
                            marginRight: 5,
                            color: Settings.colors.cyan,
                          }}
                        />{" "}
                        Tieniti aggiornato con le notifiche di Garzone
                      </Col>
                      <Col>
                        <Button onClick={this._subscribeOneSignal}>
                          Abilita le notifiche
                        </Button>
                      </Col>
                    </Row>
                  }
                  type="info"
                  showIcon={false}
                  closable
                  style={{
                    zIndex: 2,
                    width: "100%",
                    borderRadius: 0,
                    padding: "5px 15px",
                    paddingRight: "50px",
                  }}
                />
              </Col>
            </Row>
          ) : null}
          {this.props.auth.user &&
            this.props.auth.user.is_logged_in &&
            this.props.auth.user.email_verified === false ? (
            <Row>
              <Col span={24}>
                <Alert
                  message="Verifica la tua mail"
                  description={
                    <>
                      <span style={{ fontSize: "16px", lineHeight: "12px" }}>
                        <Link to="/conferma-mail">
                          Premi qui per confermare la tua mail
                        </Link>{" "}
                        e accedere a tutte le funzionalità di Garzone
                        <br />
                      </span>
                    </>
                  }
                  type="warning"
                  showIcon
                  style={{
                    zIndex: 2,
                    width: "100%",
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                />
              </Col>
            </Row>
          ) : null}
        </div>

        <ModaleLoginUtente
          visible={this.state.loginVisible}
          buttonDisabled={this.state.buttonDisabled}
          callbackClose={this._closeLoginModal}
          callbackLogin={this._changeLoginState}
        />
      </>
    );
  }
}

const styles = {
  wrapper: {},
  topHeader: {
    padding: "0 15px",
    height: "48px",
    backgroundColor: Settings.colors.white,
    zIndex: 3,
    position: "relative",
    borderBottom: "1px solid",
    borderColor: Settings.colors.lightGrey,
  },
  logoGarzone: {
    height: "35px",
  },
  bottomHeader: {
    padding: "10px 15px",
    backgroundColor: Settings.colors.white,
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    position: "relative",
    zIndex: 1,
  },
  menuIcon: {
    fontSize: "40px",
    color: Settings.colors.darkGrey,
  },
  menuText: {
    textAlign: "center",
    color: Settings.colors.darkGrey,
  },
  logoComune: {
    height: "76px",
  },
  hamburgerIcon: {
    cursor: "pointer",
    color: Settings.colors.darkGrey,
  },
  title: {
    fontSize: "32px",
    fontWeight: "bold",
    color: Settings.colors.darkGrey,
    marginBottom: 0,
  },
  subtitle: {
    fontSize: "14px",
    marginBottom: 0,
  },
  headerIcon: {
    color: Settings.colors.darkGrey,
    fontSize: "24px",
  },
  userOutlined: {
    width: "76px",
    height: "76px",
    fontSize: 62,
  },
  modalLogo: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
};

function mapStateToProps(state) {
  return {
    id: state.comuni.id,
    slug: state.comuni.slug,
    nome: state.comuni.nome,
    logo: state.comuni.logo,
    auth: state.auth,
    chats: state.chat,
    ordini: state.ordini,
  };
}

export default withRouter(connect(mapStateToProps, null)(Header));
