import { Functions } from "../Config/Fire";

// ritorna tutte le promozioni di un comune
export function getPromozioniComune(slug) {
  const promozioni = Functions.httpsCallable("utente-getPromozioniWithNegozio");
  return promozioni(slug);
}

// ritorna una promozione
export function getPromozione(id) {
  const promozione = Functions.httpsCallable("utente-getPromozione");
  return promozione(id);
}

// ritorna tutte le promozioni di un negozio
export function getPromozioniNegozio(id) {
  const promozioni = Functions.httpsCallable("utente-getPromozioniNegozio");
  return promozioni({ id: id });
}
