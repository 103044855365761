import React from "react";

// Ant design components
import { Row, Col, Tag, Skeleton, Button } from "antd";

// ant design icons

// Custom components
import Wrapper from "../Components/Wrapper";

import LoadingBlock from "../Components/LoadingBlock";

// import controllers functions
import { getPromozione } from "../Controllers/Promozioni";

// import settings
import Settings from "../Config/Settings";

import moment from "moment";

class Promozione extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading_promozione: true,
      promozione: {},
    };
  }

  componentDidMount() {
    this._getPromozione();
  }
  _goToNegozio = () => {};
  _getColor = (data_scadenza) => {
    var dataCorrente = moment().format("DD/MM/YYYY");
    var dataScadenza = moment(data_scadenza, "DD/MM/YYYY").format("DD/MM/YYYY");
    if (dataCorrente < dataScadenza) {
      return Settings.colors.cyan;
    } else {
      return Settings.colors.red;
    }
  };

  _getPromozione = async () => {
    this.setState({ loading_promozione: true });
    // get "id" promozione from url
    const { id } = this.props.match.params;
    try {
      const promozione = await getPromozione(parseInt(id));
      // tutto ok
      this.setState({ promozione: promozione.data, loading_promozione: false });
    } catch (e) {
      this.setState({ loading_promozione: false });
      console.log(e);
    }
  };

  render() {
    const { loading_promozione, promozione } = this.state;
    const { slug } = this.props.match.params;
    return (
      <Wrapper>
        {loading_promozione ? (
          // <Row>
          //   <Col md={8}>
          //     <Skeleton
          //       avatar={{ shape: "square", size: 200 }}
          //       paragraph={false}
          //       title={false}
          //     />
          //   </Col>
          //   <Col md={16}>
          //     <Skeleton avatar={false} title={false} paragraph={{ rows: 10 }} />
          //   </Col>
          // </Row>
          <LoadingBlock height="300px" />
        ) : (
          <>
            <Row
              gutter={[0, 30]}
              justify="space-between"
              align="bottom"
              style={styles.headerPromozione}
            >
              <Col span={24}>
                {
                  // DATA DI SCADENZA
                  promozione && promozione.data_scadenza !== "" && (
                    <Row justify="left" align="middle" gutter={[15, 15]}>
                      <span style={styles.spanValida}>
                        Valida fino al {promozione.data_scadenza.split(" ")[0]}
                      </span>
                    </Row>
                  )
                }
                {
                  // TITOLO DELLA PROMOZIONE
                  promozione && promozione.titolo !== "" && (
                    <Row justify="left" align="middle" gutter={[15, 15]}>
                      <h1
                        style={{
                          fontWeight: "bold",
                          color: Settings.colors.white,
                          fontSize: "48px",
                        }}
                      >
                        {promozione.titolo}
                      </h1>
                    </Row>
                  )
                }
                {
                  // NOME DEL NEGOZIO
                  promozione && promozione.nome_negozio !== "" && (
                    <Row justify="space-between" align="middle">
                      <p
                        style={{
                          color: Settings.colors.white,
                          fontSize: "17px",
                          marginBottom: "0px",
                        }}
                      >
                        Offerta da{" "}
                        <a
                          target="_blank"
                          href={
                            Settings.base_url.utente +
                            "/comuni/" +
                            slug +
                            "/negozi/" +
                            promozione.slug
                          }
                          style={{
                            color: Settings.colors.white,
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          {promozione.nome_negozio}
                        </a>
                      </p>
                      <Button
                        onClick={() => {
                          window.open(
                            Settings.base_url.utente +
                              "/comuni/" +
                              slug +
                              "/negozi/" +
                              promozione.slug,
                            "_blank"
                          );
                        }}
                      >
                        Visita il negozio
                      </Button>
                    </Row>
                  )
                }
              </Col>
            </Row>
            <Row gutter={[20, 30]}>
              <Col xs={{ span: 24 }} md={{ span: 10 }} lg={{ span: 8 }}>
                {
                  // IMMAGINE
                  promozione &&
                  promozione.immagine &&
                  promozione.immagine !== "" ? (
                    <div style={styles.block}>
                      <img
                        src={promozione.immagine}
                        style={{ width: "100%", borderRadius: "4px" }}
                      />
                    </div>
                  ) : (
                    <div style={styles.block}>
                      <img
                        src={require("../Assets/Background/placeholder.jpg")}
                        style={{ width: "100%", borderRadius: "4px" }}
                      />
                    </div>
                  )
                }
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 14 }} lg={{ span: 16 }}>
                {
                  // DESCRIZIONE
                  promozione && promozione.descrizione !== "" && (
                    <div style={styles.block}>
                      <span style={{ ...styles.info, fontSize: "18px" }}>
                        {promozione.descrizione}
                      </span>
                    </div>
                  )
                }
              </Col>
            </Row>
          </>
        )}
      </Wrapper>
    );
  }
}

const styles = {
  block: {
    marginBottom: "5px",
    whiteSpace: "pre-wrap",
  },
  section_info: {
    color: Settings.colors.darkGrey,
    fontWeight: "bold",
    marginTop: "50px",
    marginBottom: "25px",
  },
  icon: {
    fontSize: "18px",
    color: Settings.colors.grey,
  },
  info: {
    color: Settings.colors.darkGrey,
  },
  descrizione_block: {
    color: Settings.colors.darkGrey,
    fontSize: "18px",
    marginBottom: "50px",
  },
  label: {
    color: Settings.colors.grey,
  },
  headerPromozione: {
    backgroundColor: Settings.colors.cyan,
    marginTop: "0px",
    marginLeft: "-15px",
    marginRight: "-15px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  spanValida: {
    color: Settings.colors.white,
    fontSize: "14px",
  },
};

export default Promozione;
