import React, { Component } from "react";

import { HomeOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";

import CustomResult from "../Components/CustomResult";

class NonAbilitato extends Component {
  render() {
    return (
      <div style={styles.loginBackground}>
        <Row justify="center" align="middle">
          <Col>
            <img
              src={require("../Assets/logo-white.png")}
              style={styles.logoStyle}
              alt="logo"
            />
          </Col>
        </Row>
        <Row justify="center" align="middle" gutter={[15, 15]}>
          <CustomResult
            icon="info"
            title="Accesso negato"
            subtitle="La tua utenza non è abilitata, contattare l'amministrazione per l'abilitazione"
            button={{
              title: "Torna alla home",
              route: () => this.props.history.push("/"),
              icon: <HomeOutlined />,
            }}
          />
        </Row>
      </div>
    );
  }
}
const styles = {
  loginBackground: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: `url(${require("../Assets/background.png")})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "15px",
  },
  logoStyle: {
    height: 100,
    marginTop: 35,
    marginBottom: 35,
  },
};

export default NonAbilitato;
