import { Alert } from 'antd';
import React from 'react';
import Settings from '../Config/Settings';

const getStatusText = (status) => {
  switch (status.toLowerCase()) {
    case 'preventivo':
      return "Devi accettare o rifiutare il preventivo proposto dal negozio mediante l'apposito pulsante in fondo alla pagina."
    case 'in attesa di conferma':
      return "Verrai avvisato appena il negozio accetterà l'ordine da te eseguito, inserendo eventualmente un costo di consegna."
    case 'in attesa di pagamento':
      return "Per procedere con l'evasione dell'ordine dovrai pagare online mediante l'apposito pulsante in fondo alla pagina (Paga ora).\nDopo aver pagato, il negozio procederà con l'evasione dell'ordine."
    case 'in lavorazione':
      return "Verrai avvisato appena il negozio proseguirà con l'evasione dell'ordine."
    case 'in consegna':
      return "Il tuo ordine è in consegna."
    case 'consegnato':
      return "Il tuo ordine è stato consegnato."
    default:
      return ""
  }
}

const InfoOrderStatus = ({ status }) => {
  return (
    <Alert
      message={<span style={{ color: Settings.colors.darkGrey }}><b>{`${status.charAt(0).toUpperCase()}${status.substr(1)}`}</b></span>}
      description={
        <span
          style={{ marginBottom: 0, whiteSpace: 'pre-wrap' }}
        >
          {getStatusText(status)}
        </span>
      }
      type="info"
      showIcon={true}
      style={{
        zIndex: 1,
        width: "100%",
        borderRadius: 5,
      }}
    />
  );
}

export default InfoOrderStatus;