import { Functions } from "../Config/Fire";

export function getComuni() {
  const comuni = Functions.httpsCallable("utente-getComuni");
  return comuni();
}

export function getComune(slug) {
  const comune = Functions.httpsCallable("utente-getComune");
  return comune(slug);
}
export function getComuneById(id) {
  const comune = Functions.httpsCallable("utente-getComuneById");
  return comune(id);
}
