import React from "react";

// import ant design components
import { Row, Col, Statistic, Button, Image } from "antd";

import { CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";

// import custom components
import Chip from "../Components/Chip";
import TextCollapse from "../Components/TextCollapse";

// import settings
import Settings from "../Config/Settings";

//reduc connect
import { connect } from "react-redux";

//Modal login
import ModaleLoginUtente from "../Components/ModaleLoginUtente";

class CardProdotto extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginVisible: false,
    };
  }
  _closeLoginModal = () => {
    this.setState({ loginVisible: false });
  };
  _changeLoginState = () => {
    this.setState({ authed: true });
  };
  render() {
    return (
      <div style={{ borderBottom: "1px solid #eee", paddingTop: "20px" }}>
        <Row gutter={[20, 20]}>
          {this.props.immagine ? (
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 9 }}
              xl={{ span: 7 }}
              xxl={{ span: 6 }}
            >
              <img
                src={this.props.immagine}
                style={{ width: "100%", borderRadius: "4px" }}
              />
            </Col>
          ) : (
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 9 }}
              xl={{ span: 7 }}
              xxl={{ span: 6 }}
            >
              <img
                src={require("../Assets/Background/placeholder.jpg")}
                style={{ width: "100%", borderRadius: "4px" }}
              />
            </Col>
          )}
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 15 }}
            xl={{ span: 17 }}
            xxl={{ span: 18 }}
          >
            <Chip title={this.props.categoria || "Nessuna categoria"} />

            <h2 style={styles.title}>{this.props.nome}</h2>
            {this.props.descrizione !== "" && (
              <TextCollapse cut={80} style={styles.description}>
                {this.props.descrizione}
              </TextCollapse>
            )}
            <Row justify="space-between" align="middle" gutter={20}>
              <Col>
                <Row align="middle" gutter={20}>
                  <Col>
                    {this.props.prezzo != 0 &&
                      this.props.prezzo_scontato !== 0 && (
                        <Statistic
                          valueStyle={styles.prezzo}
                          suffix={
                            <span style={{ color: Settings.colors.darkGrey }}>
                              €
                            </span>
                          }
                          value={
                            this.props.prezzo_scontato !== null
                              ? this.props.prezzo_scontato
                              : this.props.prezzo
                          }
                          precision={2}
                          decimalSeparator=","
                        />
                      )}
                  </Col>
                  <Col>
                    {this.props.prezzo_scontato !== null &&
                      this.props.prezzo !== 0 &&
                      this.props.prezzo_scontato !== this.props.prezzo &&
                      this.props.prezzo_scontato !== 0 && (
                        <>
                          <Statistic
                            valueStyle={styles.prezzo_barrato}
                            suffix={
                              <span style={{ color: Settings.colors.grey }}>
                                €
                              </span>
                            }
                            value={
                              this.props.prezzo != 0
                                ? this.props.prezzo ?? ""
                                : ""
                            }
                            precision={2}
                            decimalSeparator=","
                          />
                          <div style={styles.line}></div>
                        </>
                      )}
                  </Col>
                </Row>
              </Col>
              {this.props.prezzo !== 0 &&
              this.props.prezzo !== null &&
              this.props.prezzo_scontato !== 0 &&
              this.props.ordinabile &&
              this.props.ordiniAbilitati ? (
                <Col span={24} style={{ marginTop: "20px" }}>
                  <Button
                    type="secondary"
                    icon={
                      <img
                        style={{ width: "25px" }}
                        src={require("../Assets/carrello.svg")}
                      />
                    }
                    onClick={() => {
                      if (this.props.auth.user) {
                        let prezzo =
                          this.props.prezzo_scontato !== 0 &&
                          this.props.prezzo_scontato !== null
                            ? this.props.prezzo_scontato
                            : this.props.prezzo;
                        this.props.callbackAddProdotto(this.props.id, prezzo);
                      } else {
                        this.setState({ loginVisible: true });
                      }
                    }}
                  >
                    {" "}
                    Aggiungi al carrello
                  </Button>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Col>
        </Row>
        <ModaleLoginUtente
          visible={this.state.loginVisible}
          buttonDisabled={true}
          callbackClose={this._closeLoginModal}
          callbackLogin={this._changeLoginState}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}
export default connect(mapStateToProps, null)(CardProdotto);

const styles = {
  image: {
    width: "100%",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: 0,
    marginTop: "5px",
    color: Settings.colors.darkGrey,
  },
  description: {
    display: "block",
    fontSize: "16px",
    color: Settings.colors.darkGrey,
    marginTop: "5px",
    marginBottom: 0,
  },
  prezzo: {
    fontSize: "28px",
    fontWeight: "600",
    color: Settings.colors.darkGrey,
    marginTop: "5px",
  },
  prezzo_barrato: {
    fontSize: "18px",
    color: Settings.colors.grey,
    marginTop: "12px",
    textDecoration: "line-through",
    position: "relative",
  },
  line: {
    position: "absolute",
    bottom: "13px",
    left: 0,
    background: Settings.colors.grey,
    height: "1px",
    width: "100%",
  },
};
