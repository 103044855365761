import React, { Component } from "react";
import firebase from "firebase";
import { connect } from "react-redux";
import { Link, NavLink, withRouter } from "react-router-dom";

import { CalendarFilled, MessageFilled, ShoppingFilled } from "@ant-design/icons";
import { Button, Col, Divider, Drawer, Row, Tag, Badge } from "antd";
import Settings from "../Config/Settings";
import { setActualComune } from "../Actions/ComuniActions";
import UserIcon from "./UserIcon";
import ModaleLoginUtente from "./ModaleLoginUtente";
import { checkAuthState } from "../Controllers/Auth";

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logged: false,
      loginVisible: false,
      buttonDisabled: false,
      buttonOrdiniVisible: true,
    };
  }

  _closeLoginModal = (e) => {
    this.setState({ loginVisible: false });
  };
  _changeLoginState = () => {
    this.setState({ logged: true });
  };

  componentDidMount() {
    this.authListener = checkAuthState((user) => {
      if (user) {
        this.setState({ logged: true });
      } else {
        this.setState({ logged: false });
      }
    });
  }

  render() {
    const { auth, slug, visible_menu, _closeMenu } = this.props;
    const { logged } = this.state;
    return (
      <>
        <Drawer
          headerStyle={styles.headerStyle}
          title={
            <>
              {logged ? (
                <Row justify="left" align="middle" style={styles.titleWrapper}>
                  <Col span={24}>
                    <Row
                      justify="left"
                      align="middle"
                      gutter={[15, 0]}
                      style={styles.userRow}
                    >
                      <Col>
                        <UserIcon
                          size={46}
                          char={
                            auth.user && auth.user.nome
                              ? auth.user.nome[0]
                              : null
                          }
                        />
                      </Col>
                      <Col>
                        <Row>
                          <span
                            style={{
                              fontWeight: "bold",
                              color: Settings.colors.darkGrey,
                            }}
                          >
                            {auth.user && auth.user.nome
                              ? `${auth.user.nome} ${auth.user.cognome}`
                              : null}
                          </span>
                        </Row>
                        <Row>
                          <Link
                            to="/account"
                            onClick={_closeMenu}
                            style={{ fontSize: 14 }}
                          >
                            Vedi Profilo
                          </Link>
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      justify="left"
                      align="middle"
                      gutter={[15, 15]}
                      style={styles.subtitleUserRow}
                    >
                      <Col span={24}>
                        <Row justify="start" align="middle" gutter={15}>
                          <Col>
                            {this.props.chats.toReadNumber !== 0 ? (
                              <Badge count={this.props.chats.toReadNumber}>
                                <MessageFilled
                                  style={{
                                    color: Settings.colors.darkGrey,
                                    fontSize: 24,
                                  }}
                                />
                              </Badge>
                            ) : (
                              <MessageFilled
                                style={{
                                  color: Settings.colors.darkGrey,
                                  fontSize: 24,
                                }}
                              />
                            )}
                          </Col>
                          <Col>
                            <NavLink
                              to={"/chats"}
                              activeClassName="is-active-side-menu-item"
                            >
                              <span style={{ color: Settings.colors.darkGrey }}>
                                Chat
                              </span>
                            </NavLink>
                          </Col>
                        </Row>
                      </Col>
                      <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
                      <Col span={24}>
                        <Row justify="start" align="middle" gutter={15}>
                          <Col>
                            <CalendarFilled
                              style={{
                                color: Settings.colors.darkGrey,
                                fontSize: 24,
                              }}
                            />
                          </Col>
                          <Col>
                            <NavLink
                              to={"/agenda"}
                              activeClassName="is-active-side-menu-item"
                            >
                              <span style={{ color: Settings.colors.darkGrey }}>
                                Appuntamenti
                              </span>
                            </NavLink>
                          </Col>
                        </Row>
                      </Col>
                      <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
                      {this.state.buttonOrdiniVisible && (
                        <Col span={24}>
                          <Row justify="start" align="middle" gutter={15}>
                            <Col>
                              <Badge count={this.props.ordini.toReadNumber}>
                                <ShoppingFilled
                                  style={{
                                    color: Settings.colors.darkGrey,
                                    fontSize: 24,
                                  }}
                                />
                              </Badge>
                            </Col>
                            <Col>
                              <NavLink
                                to={"/ordini"}
                                activeClassName="is-active-side-menu-item"
                              >
                                <span
                                  style={{ color: Settings.colors.darkGrey }}
                                >
                                  Ordini
                                </span>
                              </NavLink>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Row
                  justify="left"
                  align="middle"
                  style={styles.titleWrapperAccedi}
                >
                  <Col>
                    <Button
                      type="primary"
                      block
                      onClick={() => {
                        this.setState({ loginVisible: true });
                      }}
                    >
                      Accedi
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          }
          placement="left"
          closable={false}
          onClose={_closeMenu}
          visible={visible_menu}
          bodyStyle={{ padding: 0 }}
        >
          <Divider style={{ marginTop: "0px", marginBottom: "14px" }} />
          <span
            style={{
              color: Settings.colors.grey,
              fontWeight: "bold",
              fontSize: "14px",
              padding: "10px 20px",
            }}
          >
            MENU'
          </span>
          <NavLink
            exact
            to={slug !== null ? "/comuni/" + slug : "/"}
            activeClassName="is-active-side-menu-item"
          >
            <div style={styles.menu_item}>
              <span style={styles.menu_item_title} className="menu-item">
                Homepage
              </span>
            </div>
          </NavLink>
          <NavLink
            exact
            to={"/comuni/" + slug + "/negozi"}
            activeClassName="is-active-side-menu-item"
          >
            <div style={styles.menu_item}>
              <span style={styles.menu_item_title} className="menu-item">
                Negozi
              </span>
            </div>
          </NavLink>
          <NavLink
            exact
            to={"/comuni/" + slug + "/comunicazioni"}
            activeClassName="is-active-side-menu-item"
          >
            <div style={styles.menu_item}>
              <span style={styles.menu_item_title} className="menu-item">
                Comunicazioni
              </span>
            </div>
          </NavLink>
          <NavLink
            exact
            to={"/comuni/" + slug + "/promozioni"}
            activeClassName="is-active-side-menu-item"
          >
            <div style={styles.menu_item}>
              <span style={styles.menu_item_title} className="menu-item">
                Promozioni{" "}
              </span>
            </div>
          </NavLink>

          {!this.state.logged && (
            <div
              style={styles.menu_item}
              onClick={() => {
                this.setState({ loginVisible: true });
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                  display: "block",
                  cursor: "pointer",
                }}
                className="menu-item"
              >
                Chat <Tag color={Settings.colors.cyan}>Novità!</Tag>
              </span>
            </div>
          )}
          {!this.state.logged && (
            <div
              style={styles.menu_item}
              onClick={() => {
                this.setState({ loginVisible: true });
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                  display: "block",
                  cursor: "pointer",
                }}
                className="menu-item"
              >
                Ordini <Tag color={Settings.colors.cyan}>Novità!</Tag>
              </span>
            </div>
          )}
        </Drawer>
        <ModaleLoginUtente
          visible={this.state.loginVisible}
          buttonDisabled={this.state.buttonDisabled}
          callbackClose={this._closeLoginModal}
          callbackLogin={this._changeLoginState}
        />
      </>
    );
  }
}

const styles = {
  menu_item: {
    borderBottom: "1px solid " + Settings.colors.lightGrey,
    padding: "10px 20px",
    color: Settings.colors.darkGrey,
  },
  menu_item_title: {
    fontSize: "18px",
    display: "block",
  },
  menu_item_subtitle: {
    fontSize: "14px",
    color: Settings.colors.red,
    display: "block",
  },
  headerStyle: {
    padding: "0",
    border: "none",
  },
  userRow: {
    padding: "10px",
  },
  subtitleUserRow: {
    fontSize: "17px",
    padding: "10px",
    borderTop: "1px solid",
    borderColor: Settings.colors.lightGrey,
  },
  titleWrapper: {
    padding: "10px",
    minWidth: "200px",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    position: "relative",
    paddingBottom: 0,
  },
  titleWrapperAccedi: {
    padding: "10px",
    minWidth: "200px",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    position: "relative",
    paddingBottom: "10px",
  },
};

function mapStateToProps(state) {
  return {
    id: state.comuni.id,
    slug: state.comuni.slug,
    nome: state.comuni.nome,
    logo: state.comuni.logo,
    auth: state.auth,
    chats: state.chat,
    ordini: state.ordini,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setActualComune: (comune) => dispatch(setActualComune(comune)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideMenu)
);
