import { useSelector } from "react-redux";
import React, { useState } from "react";
import * as Yup from "yup";
import { Button, Col, Divider, Image, notification, Row } from "antd";
import { MessageOutlined, SendOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { Form, Formik } from "formik";
import TextArea from "antd/lib/input/TextArea";
import {
  getUtente,
  postFirstMessage,
  postMessage,
} from "../Controllers/Utente";
import Settings from "../Config/Settings";

import { Redirect, withRouter } from "react-router";

const ModalChatConNegozio = ({ negozio, history, callBackLogin }) => {
  //States
  const [modalVisible, setModalVisible] = React.useState(false);
  const [first, setFirst] = React.useState(false);
  const [chatUid, setChatUid] = React.useState(null);
  const [sendMessageLoading, setsendMessageLoading] = useState(false);
  //-----------------------------
  //reducer values
  const auth = useSelector((state) => state.auth);
  const chats = useSelector((state) => state.chat.chats);

  /************************
   * ------FUNZIONI------ *
   ************************/
  const _sendMessage = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setsendMessageLoading(true);
    let uid_chat;
    try {
      const { messaggio, start } = values;

      //-> Chiamata function POST message che restituisce l'uid firebase della chat
      const user = await getUtente(auth.user.uid);

      if (!first) {
        const res = await postMessage({
          chat_uid: chatUid,
          id_negozio: negozio.id,
          uid_from: auth.user.uid,
          messaggio: `Buongiorno, vorrei prenotare un appuntamento alla data ${start} per: ${messaggio}`,
        });
        uid_chat = res.data.firebase_db_chat_id;
      } else {
        const res = await postFirstMessage({
          id_negozio: negozio.id,
          id_utente: user.data.id,
          uid_from: auth.user.uid,
          messaggio: messaggio,
        });
        uid_chat = res.data;
      }
      setsendMessageLoading(false);
      setModalVisible(false);

      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setsendMessageLoading(false);

      notification.destroy();
      notification.error({
        message: "Errore!",
        description: "Contatta l'assistenza e descrivi il problema.",
      });
    }

    if (uid_chat) history.push(`/chats/${uid_chat}`);
  };
  /************************
   * ------FUNZIONI------ *
   ************************/
  return (
    <>
      {/* ------main button------ */}
      <Button
        type="secondary"
        style={{ marginRight: "10px" }}
        onClick={() => {
          if (auth.user != null) {
            if (auth.user.email_verified) {
              const chatExists = chats.find((c) => c.user_sql_id == negozio.id);
              if (chatExists) {
                history.push(`/chats/${chatExists.chat_uid}`);
              } else {
                setFirst(true);
                setModalVisible(true);
              }
            } else {
              return <Redirect to="/conferma-mail" />;
            }
          } else {
            callBackLogin();
          }
        }}
        icon={<MessageOutlined />}
      >
        Chat con il negozio
      </Button>
      {/* ------modal------ */}
      <Modal
        destroyOnClose
        footer={null}
        onCancel={() => {
          setModalVisible(false);
        }}
        transitionName="zoom"
        title={
          <Image
            width="158px"
            onClick={null}
            src={require("../Assets/logo-cyan.png")}
          />
        }
        visible={modalVisible}
      >
        <Row justify="space-between" align="middle" gutter={[15, 15]}>
          <Col span={24}>
            <Formik
              validationSchema={Yup.object().shape({
                messaggio: Yup.string()
                  .required("Inserisci il tuo messaggio")
                  .max(500, "Messaggio > 500 caratteri"),
              })}
              initialValues={{ messaggio: "" }}
              validateOnMount={false}
              validateOnChange={true}
              onSubmit={_sendMessage}
            >
              {({
                touched,
                errors,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isValid,
                isSubmitting,
                dirty,
              }) => (
                <Form>
                  <Row>
                    <Col span={24}>
                      <TextArea
                        rows={6}
                        placeholder="Inserisci qui il testo del tuo messaggio..."
                        value={values.messaggio}
                        onChange={handleChange("messaggio")}
                        onBlur={handleBlur("messaggio")}
                        style={{ marginTop: "10px" }}
                      />
                      <span style={styles.inputError}>
                        {touched.messaggio && errors.messaggio}
                      </span>
                    </Col>
                  </Row>
                  <Divider />
                  <Row justify="space-around">
                    <Col span={24}>
                      <Button
                        block
                        size="large"
                        icon={<SendOutlined />}
                        type="primary"
                        onClick={handleSubmit}
                        loading={sendMessageLoading}
                        disabled={!(isValid && dirty) || isSubmitting}
                      >
                        {isSubmitting ? "Attendi..." : "Invia"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
const styles = {
  inputError: {
    color: Settings.colors.red,
    fontSize: "14px",
  },
};

export default withRouter(ModalChatConNegozio);
