import React from "react";
import { Result, Button, Row, Col } from "antd";
import { CheckOutlined, CloseOutlined, InfoOutlined } from "@ant-design/icons";

import Settings from "../Config/Settings";

/*** PROPS: ***
 * icon: icona dello status, può essere "error", "info" oppure "success" -> String
 * title: (Richiesto) titolo del Result -> String || React Node
 * subtitle: (Richiesto) sottotitolo del Result -> String || React Node
 * button: se presente, mostra un tasto con le seguenti props: -> Object
 *  button.route: (Richiesto) indirizzo a cui deve rimandare il tasto -> callback
 *  button.title: (Richiesto) titolo del tasto -> String || React Node
 * buttonStyle: style del tasto -> CSS Object
 * style: oggetto style del Result -> CSS Object
 ***************/

class CustomResult extends React.Component {
  render() {
    const { icon, title, subtitle, button, buttonStyle, style } = this.props;

    return (
      <Result
        status="success"
        icon={
          icon === "success" ? (
            <CheckOutlined style={styles.doneIcon} />
          ) : icon === "error" ? (
            <CloseOutlined
              style={{
                ...styles.doneIcon,
                backgroundColor: Settings.colors.red,
              }}
            />
          ) : icon === "info" ? (
            <InfoOutlined
              style={{ ...styles.doneIcon, backgroundColor: "#0066CC" }}
            />
          ) : null
        }
        title={<span style={{ color: Settings.colors.white }}>{title}</span>}
        subTitle={
          <span style={{ color: Settings.colors.white }}>{subtitle}</span>
        }
        extra={
          button && [
            <Row justify="center" align="top" style={buttonStyle}>
              <Col span={24}>
                <Button
                  size="large"
                  block
                  type="primary"
                  key="console"
                  onClick={button.route}
                  icon={button.icon}
                >
                  {button.title}
                </Button>
              </Col>
            </Row>,
          ]
        }
        style={style}
      />
    );
  }
}

const styles = {
  done: {
    color: Settings.colors.grey,
  },
  doneIcon: {
    width: "80px",
    height: "80px",
    borderRadius: "40px",
    backgroundColor: Settings.colors.cyan,
    color: Settings.colors.white,
    fontSize: "30px !important",
  },
};

export default CustomResult;
