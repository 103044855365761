import React, { Component } from 'react';
import Settings from '../Config/Settings';
class UserIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const {
      char,
      size,
      onClick,
      style
    } = this.props;
    return (
      <div onClick={onClick} style={{ width: size, height: size, borderRadius: size / 2, backgroundColor: Settings.colors.grey, color: Settings.colors.white, textAlign: 'center', display: 'flex', alignItems: 'center', ...style }}>
        <span style={{ width: '100%', fontWeight: 700, fontSize: size / 2 }}>{char}</span>
      </div>
    );
  }
}

export default UserIcon;