import React from 'react';

// import ant design


class CookiePolicy extends React.Component {
	render() {
		return (
			<></>
		);
	}
}

export default CookiePolicy;
