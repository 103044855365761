import React from "react";

// import ant design

class Loading extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      message: "Attendi...",
    };
  }

  componentDidMount() {
    this.setState({ loading: this.props.loading });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ loading: this.props.loading });
      if (this.props.message) {
        this.setState({ message: this.props.message });
      }
    }
  }

  render() {
    return (
      <>
        {this.state.loading == true ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              zIndex: 1000,
              position: "fixed",
              width: "100%",
              height: "100%",
              left: 0,
              top: 0,
              background: this.props.opaque ? "rgba(255,255,255,1)" : "rgba(255,255,255,0.50)",
            }}
          >
            <svg style={{ width: "100px", margin: "0 auto" }}>
              <path
                className="garzone-loader"
                d="M86.1,35.6L70.4,20L39,51.4l11.3,11.3l17.1-17.1l5.9,5.9L50.4,74.3L27.5,51.4l37.2-37.2L50.5,0L13.3,37.2
              c-7.1,7.1-7.1,18.5,0,25.6L50.5,100l35.6-35.6C94,56.5,94,43.6,86.1,35.6z"
              />
              <path className="garzone-loader" d="M70.4,20" />
              <path className="garzone-loader" d="M64.7,14.2" />
            </svg>
            {/* <Spin size="large" tip={this.state.message} /> */}
          </div>
        ) : null}
      </>
    );
  }
}

export default Loading;
