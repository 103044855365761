import {
  InfoCircleFilled,
  SendOutlined,
  LeftOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import {
  Col,
  Divider,
  Image,
  Button,
  notification,
  Row,
  Tooltip,
  Typography,
  Table,
  Alert,
} from "antd";
import TextArea from "antd/lib/input/TextArea";

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import OrderList from "../Components/OrderList";
import Chip from "../Components/Chip";
import Header from "../Components/Header";
import placeholder from "../Assets/Background/placeholder.jpg";
import LoadingBlock from "../Components/LoadingBlock";
import SideMenu from "../Components/SideMenu";
import Settings from "../Config/Settings";
import firebase from "firebase";
import * as Yup from "yup";
import { Formik } from "formik";

import { Link } from "react-router-dom";

import { getComuneById } from "../Controllers/Comuni";
import { getNegozio, getStripeDetails } from "../Controllers/Negozi";
import {
  getOrder,
  getProdotto,
  changeOrderStatus,
  postFirstMessage,
  generaIdPagamentoStripe,
} from "../Controllers/Utente";
import Modal from "antd/lib/modal/Modal";
import Form from "antd/lib/form/Form";
import { _getOrderColor } from "../Utility/utility";
import InfoOrderStatus from "../Components/InfoOrderStatus";

import { loadStripe } from "@stripe/stripe-js";
import { getOrGenerateStripeCustomerId } from "../Controllers/Stripe";
import ModaleRichiestaReso from "../Components/ModaleRichiestaReso";
const moment = require("moment");

class Ordine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ordine: null,
      loadingChat: true,
      loadingMessages: true,
      visible_menu: false,
      chats: this.props.chats,
      chatAbilitata: true,
      chatInfo: null,
      userToData: null,
      currentUser: null,
      sending: false,
      prodotti: [],
      numProd: 0,
      totale: 0,
      costoConsegna: 0,
      infoPressed: window.innerWidth < 992 ? false : true,
      confermaAnnullaVisible: false,
      loadingChangeStatus: false,
      contattaNegozioVisible: false,
      linkPagamento: "",
      loadingGeneraLink: false,
      stripe_response: null,
    };
  }

  _closeMenu = () => {
    this.setState({ visible_menu: false });
  };

  _openMenu = () => {
    this.setState({ visible_menu: true });
  };

  _sendMessage = async (values, { setSubmitting }) => {
    setSubmitting(true);
    this.setState({ sendMessageLoading: true });
    try {
      const { messaggio } = values;
      //id negozio verso
      //uid mio
      //messaggio
      //-> Chiamata function POST message che restituisce l'uid firebase della chat

      const uid_chat = await postFirstMessage({
        id_negozio: this.state.userToData.id,
        id_utente: this.props.auth.user.id,
        uid_from: this.props.auth.user.uid,
        messaggio: messaggio,
      });

      this.setState({
        sendMessageLoading: false,
        contattaNegozioVisible: false,
      });
      setSubmitting(false);

      this.props.history.push(`/chats/${uid_chat.data}`);
    } catch (error) {
      setSubmitting(false);
      this.setState({ sendMessageLoading: false });
      console.log(error);
      notification.destroy();
      notification.error({
        message: "Errore!",
        description: "Contatta l'assistenza e descrivi il problema.",
      });
    }
  };
  annullaOrdine = () => {
    console.log(this.state.ordine.data.status);
    if (
      this.state.ordine.data.status !== "In attesa di conferma" &&
      this.state.ordine.data.status !== "Preventivo" &&
      this.state.ordine.data.status !== "in attesa di pagamento"
    ) {
      notification.destroy();
      notification.error({
        title: "Errore",
        message: "Non puoi annullare un ordine che è già stato confermato",
      });
    } else {
      this.setState({ confermaAnnullaVisible: true });
    }
  };
  accettaPreventivo = () => {
    if (this.state.ordine.data.status !== "Preventivo") {
      notification.destroy();
      notification.error({
        title: "Errore",
        message: "Si è verificato un errore",
      });
    } else {
      this.setState({ confermaAccettaVisible: true });
    }
  };

  pagaOrdine = async () => {
    if (this.state.ordine.data.status !== "in attesa di pagamento") {
      notification.destroy();
      notification.error({
        title: "Errore",
        message: "Si è verificato un errore",
      });
    } else {
      this.setState({ loadingGeneraLink: true });

      try {
        const { data } = await getOrGenerateStripeCustomerId({
          uid: firebase.auth().currentUser.uid,
          email: firebase.auth().currentUser.email,
          phone: firebase.auth().currentUser.phoneNumber,
          name: firebase.auth().currentUser.displayName,
        });
        console.log("ID CUSTOMER", data);
      } catch (error) {
        notification.destroy();
        notification.error({
          title: "Errore",
          message: "Si è verificato un errore",
        });
        this.setState({
          loadingGeneraLink: false,
        });
        return;
      }

      try {
        const { data } = await generaIdPagamentoStripe(
          this.state.ordine.data.firebase_db_order_id
        );
        const res = await getStripeDetails(this.state.userToData.id);
        console.log("ERS", res);
        const stripePromise = loadStripe(
          window.location.hostname === "localhost" ||
            window.location.hostname === "127.0.0.1" ||
            window.location.hostname === "piattaforma-garzone-test.web.app"
            ? "pk_test_51Iqw6WHB02YIjGTooiWyl8I5hUiVYENFKP4Ck9pbWfp03WoEwnShYKpz0R7EBZBJ2Zw1RgStlzRhDt4sT3D9ijg800tHMwGOSS"
            : "pk_live_51Iqw6WHB02YIjGToPzWShf6ksLFEHjphl978lM4Rknggmy5mJddw89tQx6zgSQNBe8lTGjMBKnId3xpfm3TE0CQ6005uiFewcd",
          { stripeAccount: res.data.id_stripe }
        );
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
          sessionId: data.id,
        });

        if (result.error) {
          console.log(result.error.message);
          notification.destroy();
          notification.error({
            title: "Errore",
            message: "Si è verificato un errore",
          });
          this.setState({
            loadingGeneraLink: false,
          });
        }
      } catch (error) {
        console.log(error);
        notification.destroy();
        notification.error({
          title: "Errore",
          message: "Si è verificato un errore",
        });
        this.setState({
          loadingGeneraLink: false,
        });
      }
    }
  };

  _getOrder = async (order_uid) => {
    const { orders } = this.props.ordini;
    const orderInfo = orders.find((i) => i.order_uid == order_uid);
    if (!orderInfo) {
      window.location.href = "/404";
      return;
    }
    const sqlUserTo = await getNegozio(orderInfo.sql_id_to);

    const order = await getOrder({
      firebaseOrderId: order_uid,
      uid: firebase.auth().currentUser.uid,
    });
    let prodottiJson = order.data.dettagli;
    let prodotti = JSON.parse(prodottiJson);
    let res = await this.processProdotti(prodotti);

    const search = this.props.location.search;
    const esito = new URLSearchParams(search).get("esito");

    this.setState({
      chatInfo: {
        ...sqlUserTo.data,
        ...orderInfo,
      },
      loadingChat: false,
      loadingMessages: false,
      ordine: order,
      prodotti: res.prodotti,
      costoConsegna: order.data.costo_consegna,
      numProd: res.numeroProdotti,
      totale: res.totale,
      chatAbilitata: sqlUserTo.data.chat,
      userToData: sqlUserTo.data,
      stripe_response: esito
        ? {
            esito: esito,
          }
        : null,
    });
  };
  processProdotti = async (prodotti) => {
    let prodottiFinal = [];

    let currentNum = 0;
    let currentTot = 0;
    for (let i = 0; i < prodotti.length; i++) {
      currentNum += parseFloat(prodotti[i].quantita);
      currentTot += parseFloat(prodotti[i].totale);
      const currentProd = prodotti[i].nome
        ? null
        : await getProdotto(prodotti[i].id);
      const record = {
        Quantita: prodotti[i].quantita,
        Prodotto:
          currentProd !== null ? currentProd.data.nome : prodotti[i].nome,
        PrezzoUnitario:
          (prodotti[i].totale / prodotti[i].quantita).toFixed(2) + " €",
        PrezzoTotale: prodotti[i].totale.toFixed(2) + " €",
      };
      prodottiFinal.push(record);
    }
    return {
      prodotti: prodottiFinal,
      numeroProdotti: currentNum,
      totale: currentTot,
    };
  };

  _scrollToBottomMessagesBox = () => {
    let objDiv = document.getElementById("chat");
    objDiv.scrollTop = objDiv.scrollHeight;
  };

  confermaAnnulla = async () => {
    this.setState({ loadingChangeStatus: true, loadingChat: true });
    if (
      this.state.ordine.data.status !== "In attesa di conferma" &&
      this.state.ordine.data.status !== "Preventivo" &&
      this.state.ordine.data.status !== "in attesa di pagamento"
    ) {
      notification.destroy();
      notification.error({
        title: "Errore",
        message: "Non puoi annullare un ordine che è già stato confermato",
      });
    } else {
      try {
        await changeOrderStatus({
          id: this.state.ordine.data.firebase_db_order_id,
          status: "annullato",
          from: this.state.ordine.data.from,
          to: this.state.ordine.data.to,
        });
        this.setState({
          loadingChangeStatus: false,
          confermaAnnullaVisible: false,
          loadingChat: false,
        });
        notification.destroy();
        notification.success({
          title: "Perfetto",
          message: "Lo stato del tuo ordine è stato aggiornato correttamente",
        });
        window.location.reload();
      } catch (error) {
        console.log("Errore", error);
      }
    }
  };

  confermaAccetta = async () => {
    this.setState({ loadingChangeStatus: true, loadingChat: true });
    if (this.state.ordine.data.status !== "Preventivo") {
      notification.destroy();
      notification.error({
        title: "Errore",
        message: "Si è verificato un errore",
      });
    } else {
      console.log(this.state.ordine.data);
      try {
        await changeOrderStatus({
          id: this.state.ordine.data.firebase_db_order_id,
          status:
            this.state.ordine.data.tipo_pagamento === "carta"
              ? "in attesa di pagamento"
              : "in lavorazione",
          from: this.state.ordine.data.from,
          to: this.state.ordine.data.to,
        });
        this.setState({
          loadingChangeStatus: false,
          loadingChat: false,
          confermaAccettaVisible: false,
        });
        notification.destroy();
        notification.success({
          title: "Perfetto",
          message: "Lo stato del tuo ordine è stato aggiornato correttamente",
        });
        this._getOrder(this.state.ordine.data.firebase_db_order_id);
      } catch (error) {
        console.log("Errore", error);
      }
    }
  };

  confermaPaga = async () => {
    this.setState({ loadingChangeStatus: true, loadingChat: true });
    if (this.state.ordine.data.status !== "in attesa di pagamento") {
      notification.destroy();
      notification.error({
        title: "Errore",
        message: "Si è verificato un errore",
      });
    } else {
      console.log(this.state.ordine.data);

      try {
        window.open(this.state.linkPagamento, "_self");
      } catch (error) {
        console.log("Errore", error);
      }
    }
  };

  //TODO: button annulla non visibile durante il caricamento
  //TODO: margin top nome negozio
  //TODO: button annulla solo se annullabile
  //component didMount e didUpdate---------------
  componentDidUpdate(prevProps) {
    let {
      match: { params },
    } = this.props;
    const { order_uid } = params;
    if (
      prevProps.ordini.toReadNumber !== this.props.ordini.toReadNumber ||
      prevProps.match.params.order_uid !== order_uid
    ) {
      console.log("UPDATE", this.props);
      //this.setState({ loadingChat: true, loadingMessages: true });
      this._getOrder(order_uid);
    }
  }
  componentDidMount() {
    let {
      match: { params },
    } = this.props;
    const { order_uid } = params;
    this.setState({ loadingChat: true, loadingMessages: true });
    if (this.state.chatInfo === null || this.state.ordine === null)
      this._getOrder(order_uid);
  }
  //---------------------------------------------
  render() {
    const {
      loadingChat,
      loadingMessages,
      visible_menu,
      ordine,
      userToData,
      chatInfo,
    } = this.state;
    let prezzoTotale = (
      parseFloat(this.state.totale) + parseFloat(this.state.costoConsegna)
    ).toFixed(2);
    const columns = [
      { title: "Quantità", dataIndex: "Quantita", key: "Quantita" },
      { title: "Prodotto", dataIndex: "Prodotto", key: "Prodotto" },
      {
        title: "Prezzo Unitario",
        dataIndex: "PrezzoUnitario",
        key: "PrezzoUnitario",
      },
      {
        title: "Prezzo Totale",
        dataIndex: "PrezzoTotale",
        key: "PrezzoTotale",
      },
    ];
    return (
      <>
        <Helmet>
          <title>Garzone Store - Ordini</title>
        </Helmet>
        <Header account onMenuClick={this._openMenu} />
        <SideMenu
          slug={this.props.slug}
          visible_menu={visible_menu}
          _closeMenu={this._closeMenu}
        />
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "400px",
              height: "100%",
              overflowY: "scroll",
              top: 0,
              left: 0,
              padding: "15px",
              paddingTop: "159px",
              zIndex: 8888,
              backgroundColor: Settings.colors.white,
              borderRight: "1px solid",
              borderColor: Settings.colors.lightGrey,
            }}
            className="chatPanel"
          >
            <OrderList />
          </div>
          <div
            style={{
              position: "absolute",
              left: "400px",
              top: 0,
              width: "calc(100% - 400px)",
              height: "100%",
              zIndex: 9999,
            }}
            className="chatList"
          >
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "70px",
                top: "144px",
                left: 0,
                borderBottom: "1px solid",
                zIndex: 9999,
                backgroundColor: Settings.colors.white,
                borderColor: Settings.colors.lightGrey,
              }}
            >
              {loadingChat ? null : (
                <Row
                  justify="space-between"
                  align="middle"
                  style={{
                    height: "100%",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  <Col>
                    <Row justify="start" align="middle" gutter={15}>
                      <Col className="backToChats">
                        <Link to="/ordini">
                          <LeftOutlined />{" "}
                          {window.innerWidth > 512 ? "Ordini" : null}
                        </Link>
                      </Col>
                      <Col>
                        {chatInfo && (
                          <Image
                            height="40px"
                            width="53px"
                            style={styles.chat_image}
                            src={
                              chatInfo.logo
                                ? `https://storage.googleapis.com/piattaforma-garzone${
                                    process.env.REACT_APP_STAGE ===
                                    "development"
                                      ? "-test"
                                      : ""
                                  }.appspot.com/negozi/${
                                    chatInfo.sql_id_to
                                  }/corporate/logo.png`
                                : placeholder
                            }
                            placeholder={placeholder}
                            preview={placeholder}
                            fallback={placeholder}
                          />
                        )}
                      </Col>
                      <Col>
                        <Row>
                          <Typography.Title level={3} style={styles.chat_title}>
                            {chatInfo && chatInfo.order_title}
                          </Typography.Title>
                        </Row>
                        <Row align="middle">
                          <span
                            style={{
                              width: "14px",
                              height: "14px",
                              borderRadius: "14px",
                              marginRight: "10px",
                              backgroundColor: _getOrderColor(
                                ordine.data.status
                              ),
                            }}
                          ></span>
                          <Col>
                            <span style={styles.chat_subtitle}>
                              {ordine.data.status.charAt(0).toUpperCase() +
                                ordine.data.status.slice(1)}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Tooltip
                      title="Informazioni negozio"
                      placement="bottomRight"
                      color={Settings.colors.darkGrey}
                    >
                      <InfoCircleFilled
                        style={{
                          fontSize: 32,
                          color: this.state.infoPressed
                            ? Settings.colors.cyan
                            : Settings.colors.darkGrey,
                        }}
                        onClick={() =>
                          this.setState({
                            infoPressed: !this.state.infoPressed,
                          })
                        }
                      />
                    </Tooltip>
                  </Col>
                </Row>
              )}
            </div>

            <div
              style={{
                position: "absolute",
                width: this.state.infoPressed ? "calc(100% - 300px)" : "100%",
                height: "calc(100% - 284px)",
                padding: "15px",
                top: "214px",
                bottom: "70px",
                left: 0,
                borderBottom: "1px solid",
                overflowY: "scroll",
                borderColor: Settings.colors.lightGrey,
                overflowX: "hidden",
              }}
              id="chat"
              className="chatList"
            >
              {/* Alert informazioni status dell'ordine */}
              {!loadingMessages &&
              this.state.ordine &&
              this.state.ordine.data.status ? (
                <Row align="middle" style={{ marginBottom: 15 }}>
                  <Col span={24}>
                    <InfoOrderStatus status={this.state.ordine.data.status} />
                  </Col>
                </Row>
              ) : null}
              {!loadingMessages && this.state.stripe_response !== null ? (
                <Row align="middle" style={{ marginBottom: 15 }}>
                  <Col span={24}>
                    <Alert
                      message={
                        <span style={{ color: Settings.colors.darkGrey }}>
                          <b>Esito Pagamento</b>
                        </span>
                      }
                      description={
                        <Row
                          justify="space-between"
                          align="middle"
                          style={{ marginBottom: 0 }}
                        >
                          {this.state.stripe_response.esito === "OK"
                            ? "L'ordine è stato pagato con successo"
                            : "L'ordine non è stato pagato con successo. Riprova."}
                        </Row>
                      }
                      type={
                        this.state.stripe_response.esito === "OK"
                          ? "success"
                          : "error"
                      }
                      showIcon={false}
                      style={{
                        zIndex: 1,
                        width: "100%",
                        borderRadius: 5,
                        padding: "5px 15px",
                        paddingRight: "50px",
                      }}
                    />
                  </Col>
                </Row>
              ) : null}
              {loadingMessages ? (
                <LoadingBlock loading={true} />
              ) : (
                <Row gutter={[40, 40]}>
                  <Col span={12}>
                    <Row>
                      <span style={styles.title}>
                        <strong>ID ordine</strong>
                      </span>
                    </Row>
                    <Row>
                      <span style={{ fontSize: 17 }}>
                        {ordine.data.firebase_db_order_id}
                      </span>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <span style={styles.title}>
                        <strong>Data Ordine</strong>
                      </span>
                    </Row>
                    <Row>
                      <span style={{ fontSize: 17 }}>
                        {ordine.data.creazione}
                      </span>
                    </Row>
                  </Col>

                  <Col span={12}>
                    <Row>
                      <span style={styles.title}>
                        <strong>Modalità d'ordine</strong>
                      </span>
                    </Row>
                    <Row>
                      <span style={{ fontSize: 17 }}>
                        {ordine.data.tipo === "ritiro"
                          ? "Ritira in negozio"
                          : "Consegna a domicilio"}
                      </span>
                    </Row>
                  </Col>
                  {ordine.data.tipo !== "ritiro" ? (
                    <Col span={12}>
                      <Row>
                        <span style={styles.title}>
                          <strong>Indirizzo di consegna</strong>
                        </span>
                      </Row>
                      <Row>
                        <span style={{ fontSize: 17 }}>
                          <a
                            target="_blank"
                            href={`http://maps.google.com/?q=${ordine.data.indirizzo}`}
                          >
                            {ordine.data.indirizzo}
                          </a>
                        </span>
                      </Row>
                    </Col>
                  ) : null}
                  <Col span={24}>
                    <span style={styles.title}>
                      <strong>
                        Prodott{this.state.numProd > 1 ? "i" : "o"} (
                        {this.state.numProd})
                      </strong>
                    </span>
                  </Col>
                  <Col span={24}>
                    <Table
                      pagination={false}
                      columns={columns}
                      dataSource={this.state.prodotti}
                    />
                  </Col>
                  <Col span={24}>
                    <Row justify="space-between">
                      <span style={styles.text}>Subtotale</span>
                      <span style={styles.text}>
                        {parseFloat(this.state.totale).toFixed(2)} €
                      </span>
                    </Row>
                  </Col>
                  {ordine.data.tipo !== "ritiro" ? (
                    <>
                      <Col span={24}>
                        <Row justify="space-between">
                          <span style={styles.text}>Costo consegna *</span>
                          <span style={styles.text}>
                            {ordine.data.costo_consegna !== 0
                              ? ordine.data.costo_consegna.toFixed(2) + " €"
                              : "Da definire"}
                          </span>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <span
                              style={{
                                fontSize: "12px",
                                color: Settings.colors.darkGrey,
                              }}
                            >
                              <strong>
                                <i>
                                  *Dettagli consegna:{" "}
                                  {userToData.dettagli_consegna}
                                </i>
                              </strong>
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ) : null}
                  <Col span={24}>
                    <Row justify="space-between">
                      <span style={styles.text}>Tipo di pagamento</span>
                      <span style={styles.text}>
                        <strong>
                          {ordine.data.tipo_pagamento === "carta"
                            ? "Pagamento online"
                            : ordine.data.tipo === "ritiro"
                            ? "Pagamento al ritiro"
                            : "Pagamento alla consegna"}
                        </strong>
                      </span>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row justify="space-between">
                      <span style={styles.text}>Totale</span>
                      <span>
                        <strong>{prezzoTotale} €</strong>
                      </span>
                    </Row>
                  </Col>
                  {ordine.data.note !== "" ? (
                    <Col span={24}>
                      <span>
                        <strong>Note per il negozio</strong>
                      </span>{" "}
                      <br></br>
                      <span>{ordine.data.note}</span>
                    </Col>
                  ) : null}
                  {ordine.data.motivo_annullamento !== "" &&
                  ordine.data.motivo_annullamento !== null ? (
                    <Col span={24}>
                      <span>
                        <strong>Motivo annullamento ordine</strong>
                      </span>{" "}
                      <br></br>
                      <span style={{ color: Settings.colors.red }}>
                        {ordine.data.motivo_annullamento}
                      </span>
                    </Col>
                  ) : null}
                </Row>
              )}
            </div>
            <div
              style={{
                display: this.state.infoPressed ? "initial" : "none",
                padding: "15px",
                position: "absolute",
                width: "300px",
                height: "calc(100% - 214px)",
                right: 0,
                top: "214px",
                borderLeft: "1px solid",
                borderColor: Settings.colors.lightGrey,
                zIndex: 9999,
                overflowY: "scroll",
                paddingTop: "30px",
                textAlign: "center",
              }}
              className="chatDetails"
            >
              {loadingChat ? null : (
                <>
                  <Row justify="center" align="middle" style={styles.section}>
                    {chatInfo && (
                      <Image
                        height="100px"
                        width="133px"
                        src={
                          chatInfo.logo
                            ? `https://storage.googleapis.com/piattaforma-garzone${
                                process.env.REACT_APP_STAGE === "development"
                                  ? "-test"
                                  : ""
                              }.appspot.com/negozi/${
                                chatInfo.sql_id_to
                              }/corporate/logo.png`
                            : placeholder
                        }
                        placeholder={placeholder}
                        className="radius"
                        preview={placeholder}
                        fallback={placeholder}
                      />
                    )}
                  </Row>
                  <Row justify="center" align="middle" style={styles.section}>
                    <Chip title={chatInfo && chatInfo.categoria} />
                  </Row>
                  <Row justify="center" align="middle" style={styles.section}>
                    <Typography.Title level={3} style={styles.rightTitle}>
                      {chatInfo && chatInfo.chat_title}
                    </Typography.Title>
                  </Row>
                  <Row justify="center" align="middle" style={styles.section}>
                    <Typography.Title level={4} style={styles.rightSubtitle}>
                      {chatInfo && (
                        <a
                          target="_blank"
                          href={`http://maps.google.com/?q=${chatInfo.indirizzo} ${chatInfo.civico}, ${chatInfo.cap} ${chatInfo.citta}`}
                        >{`${chatInfo.indirizzo} ${chatInfo.civico}, ${chatInfo.cap} ${chatInfo.citta}`}</a>
                      )}
                    </Typography.Title>
                  </Row>
                  <Row justify="center" align="middle" style={styles.section}>
                    <Link
                      to={
                        "/comuni/" +
                        this.props.slug +
                        "/negozi/" +
                        chatInfo.slug
                      }
                      target="_blank"
                    >
                      <Button type="link" size="small">
                        Vedi scheda negozio
                      </Button>
                    </Link>
                  </Row>
                  <Row justify="center" align="middle">
                    {userToData.chat ? (
                      <Button
                        type="secondary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          const { chats } = this.props.chats;

                          if (this.props.auth.user != null) {
                            const chatExists = chats.find(
                              (c) => c.user_sql_id == userToData.id
                            );
                            if (chatExists) {
                              this.props.history.push(
                                `/chats/${chatExists.chat_uid}`
                              );
                            } else {
                              this.setState({
                                contattaNegozioVisible: true,
                              });
                            }
                          } else {
                            this.setState({ loginVisible: true });
                          }
                        }}
                        icon={<MessageOutlined />}
                      >
                        Chat con il negozio
                      </Button>
                    ) : null}
                    <Modal
                      destroyOnClose
                      footer={null}
                      onCancel={() => {
                        this.setState({ contattaNegozioVisible: false });
                      }}
                      transitionName="zoom"
                      title={
                        <Image
                          style={styles.modalLogo}
                          width="158px"
                          onClick={null}
                          src={require("../Assets/logo-cyan.png")}
                        />
                      }
                      visible={this.state.contattaNegozioVisible}
                    >
                      <Row
                        justify="space-between"
                        align="middle"
                        gutter={[15, 15]}
                      >
                        <Col span={24}>
                          <Formik
                            validationSchema={Yup.object().shape({
                              messaggio: Yup.string()
                                .required("Inserisci il tuo messaggio")
                                .max(500, "Messaggio > 500 caratteri"),
                            })}
                            initialValues={{ messaggio: "" }}
                            validateOnMount={false}
                            validateOnChange={true}
                            onSubmit={this._sendMessage}
                          >
                            {({
                              touched,
                              errors,
                              values,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              setFieldValue,
                              isValid,
                              isSubmitting,
                              dirty,
                            }) => (
                              <Form>
                                <Row>
                                  <Col span={24}>
                                    <TextArea
                                      rows={6}
                                      placeholder="Inserisci qui il testo del tuo messaggio..."
                                      value={values.messaggio}
                                      onChange={handleChange("messaggio")}
                                      onBlur={handleBlur("messaggio")}
                                      style={{ marginTop: "10px" }}
                                    />
                                    <span style={styles.inputError}>
                                      {touched.messaggio && errors.messaggio}
                                    </span>
                                  </Col>
                                </Row>
                                <Divider />
                                <Row justify="space-around">
                                  <Col span={24}>
                                    <Button
                                      block
                                      size="large"
                                      icon={<SendOutlined />}
                                      type="primary"
                                      onClick={handleSubmit}
                                      loading={this.state.sendMessageLoading}
                                      disabled={
                                        !(isValid && dirty) || isSubmitting
                                      }
                                    >
                                      {isSubmitting ? "Attendi..." : "Invia"}
                                    </Button>
                                  </Col>
                                </Row>
                              </Form>
                            )}
                          </Formik>
                        </Col>
                      </Row>
                    </Modal>{" "}
                  </Row>
                </>
              )}
            </div>
            {!loadingChat &&
            !loadingMessages &&
            (this.state.ordine.data.status === "In attesa di conferma" ||
              this.state.ordine.data.status === "Preventivo" ||
              this.state.ordine.data.status === "in attesa di pagamento" ||
              this.state.ordine.data.status === "consegnato") ? (
              <div
                style={{
                  background: Settings.colors.white,
                  position: "absolute",
                  padding: "15px",
                  width: "100%",
                  height: "70px",
                  bottom: 0,
                  paddingRight: this.state.infoPressed ? "315px" : "15px",
                }}
                className="chatList"
              >
                <Row justify="start" align="middle" gutter={[15, 15]}>
                  {this.state.ordine.data.status !== "consegnato" && (
                    <Col style={{ marginTop: "5px" }}>
                      <Button
                        type="primary"
                        danger
                        onClick={this.annullaOrdine}
                      >
                        Annulla
                      </Button>
                    </Col>
                  )}
                  {this.state.ordine.data.status === "Preventivo" && (
                    <Col style={{ marginTop: "5px" }}>
                      <Button
                        type="primary"
                        onClick={this.accettaPreventivo}
                        loading={this.state.accettaPreventivo_loading}
                      >
                        Accetta preventivo
                      </Button>
                    </Col>
                  )}
                  {this.state.ordine.data.status ===
                    "in attesa di pagamento" && (
                    <Col style={{ marginTop: "5px" }}>
                      <Button
                        type="primary"
                        loading={this.state.loadingGeneraLink}
                        onClick={this.pagaOrdine}
                      >
                        Paga ora
                      </Button>
                    </Col>
                  )}

                  {this.state.ordine.data.status === "consegnato" &&
                    moment(this.state.ordine.data.time_consegnato).diff(
                      Date.now(),
                      "days"
                    ) < 15 && (
                      <Col style={{ marginTop: "5px" }}>
                        <ModaleRichiestaReso
                          ordine={ordine.data}
                          negozio={this.state.userToData}
                        />
                      </Col>
                    )}
                </Row>
              </div>
            ) : null}
          </div>
        </div>
        <Modal
          destroyOnClose
          title={<h2 style={styles.rightTitle}>Annulla ordine</h2>}
          visible={this.state.confermaAnnullaVisible}
          footer={null}
          onCancel={() => {
            this.setState({ confermaAnnullaVisible: false });
          }}
        >
          <Row gutter={[0, 30]}>
            <Col span={24}>
              <span>Sei sicuro di voler annullare l'ordine?</span>
            </Col>
            <Col span={24}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                danger
                onClick={() => this.confermaAnnulla()}
                disabled={this.state.loadingChangeStatus}
                loading={this.state.loadingChangeStatus}
              >
                Conferma annullamento
              </Button>
            </Col>
          </Row>
        </Modal>
        <Modal
          destroyOnClose
          title={<h2 style={styles.rightTitle}>Accetta preventivo</h2>}
          visible={this.state.confermaAccettaVisible}
          footer={null}
          onCancel={() => {
            this.setState({ confermaAccettaVisible: false });
          }}
        >
          <Row gutter={[0, 30]}>
            <Col span={24}>
              <span>Sei sicuro di voler accettare il preventivo?</span>
            </Col>
            <Col span={24}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                onClick={() => this.confermaAccetta()}
                disabled={this.state.loadingChangeStatus}
                loading={this.state.loadingChangeStatus}
              >
                Accetta il preventivo
              </Button>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

const styles = {
  chatNonAbilitata: {
    fontSize: 17,
    color: Settings.colors.red,
  },
  text: {
    fontSize: 17,
  },
  chat_image: {
    height: "40px",
    borderRadius: "4px",
    overflow: "hidden",
    marginTop: "7.5px",
  },
  chat_title: {
    fontSize: 17,
    fontWeight: 700,
    color: Settings.colors.darkGrey,
    margin: 0,
  },
  chat_subtitle: {
    fontSize: 14,
    fontWeight: 500,
    color: Settings.colors.grey,
  },
  rightTitle: {
    fontSize: 22,
    fontWeight: 700,
    color: Settings.colors.darkGrey,
    margin: 0,
  },
  rightSubtitle: {
    fontSize: 14,
    fontWeight: 500,
    color: Settings.colors.grey,
  },
  section: {
    marginBottom: "10px",
  },
};

function mapStateToProps(state) {
  return {
    id: state.comuni.id,
    slug: state.comuni.slug,
    nome: state.comuni.nome,
    logo: state.comuni.logo,
    auth: state.auth,
    chats: state.chat,
    ordini: state.ordini,
  };
}

export default connect(mapStateToProps, null)(Ordine);
