import React from "react";

// Third-party components
import { Button, Col, notification, Row } from "antd";

// Custom components
import CustomResult from "../Components/CustomResult";
import CountdownMail from "../Components/CountdownMail";

// Libraries
import { resendEmailVerification, logout } from "../Controllers/Auth";
import Settings from "../Config/Settings";
import { MailOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { successLogin, successLogout } from "../Actions/AuthActions";
const OneSignal = window.OneSignal || [];
class ConfermaMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMailLoading: false,
      resendMailVisible: false,
    };
  }
  _clearTags = () => {
    OneSignal.push(function () {
      OneSignal.deleteTags(["uid", "citta"], function (tagsDeleted) {
        console.log("tags deleted", tagsDeleted);
      });
    });
  };
  render() {
    return (
      <div style={styles.loginBackground}>
        <Row justify="center" align="middle">
          <Col>
            <img
              src={require("../Assets/logo-white.png")}
              style={styles.logoStyle}
              alt="logo"
            />
          </Col>
        </Row>
        <Row justify="center" align="middle" gutter={[15, 15]}>
          <Col span={24}>
            <CustomResult
              icon="info"
              title="Conferma il tuo indirizzo"
              subtitle="Devi confermare il tuo indirizzo E-Mail per accedere a quest'area. Controlla la tua casella di posta. Se la mail non è presente contatta l'assistenza."
            />
          </Col>
        </Row>

        <Row justify="center" align="middle" gutter={[15, 15]}>
          {this.state.resendMailVisible ? (
            <Col xs={24} sm={18} md={12}>
              <Button
                type="primary"
                size="large"
                block
                icon={<MailOutlined />}
                loading={this.state.sendMailLoading}
                disabled={this.state.sendMailLoading}
                onClick={async () => {
                  this.setState({ sendMailLoading: true });
                  try {
                    let email = await resendEmailVerification();
                    this._clearTags();
                    let user = await logout();
                    // mostro il risultato
                    notification.destroy();
                    notification.success({
                      message: "PERFETTO!",
                      description:
                        "Email inviata al tuo indirizzo di posta. Controlla anche la casella spam e conferma il tuo account cliccando sul link che trovi all'interno della email",
                    });
                  } catch (error) {
                    notification.error({
                      message: "Errore",
                      description:
                        "Si è verificato un errore durante l'invio dell'email, prova di nuovo",
                    });
                  } finally {
                    this.setState({
                      sendMailLoading: false,
                      resendMailVisible: false,
                    });
                  }
                }}
              >
                Invia email di conferma
              </Button>
              <Button
                type="link"
                size="large"
                block
                style={{ marginTop: "30px" }}
                onClick={() => this.props.history.push("/accedi")}
              >
                Accedi
              </Button>
            </Col>
          ) : (
            <CountdownMail
              seconds={60}
              textColor={Settings.colors.white}
              onFinish={() =>
                this.setState({
                  resendMailVisible: true,
                })
              }
            />
          )}
        </Row>
      </div>
    );
  }
}

const styles = {
  loginBackground: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: `url(${require("../Assets/background.png")})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "15px",
  },
  logoStyle: {
    height: 100,
    marginTop: 35,
    marginBottom: 35,
  },
  title: {
    color: Settings.colors.white,
    textAlign: "center",
  },
  forgotPassword: {
    textAlign: "center",
    color: "var(--white)",
  },
  alert: {
    marginBottom: "15px",
    alignSelf: "center",
  },
};

function mapDispatchToProps(dispatch) {
  return {
    successLogin: (user) => dispatch(successLogin(user)),
    successLogout: () => dispatch(successLogout()),
  };
}

export default connect(null, mapDispatchToProps)(ConfermaMail);
