import React from "react";
//notification component
import { notification } from "antd";
// import router
import { Route, Redirect } from "react-router-dom";

class PrivateRoute extends React.Component {
  is_mounted = false;

  constructor(props) {
    super(props);
    this.state = {
      not_found: false,
      loading: true,
      notVerified: true,
    };
  }

  componentDidMount() {
    this.is_mounted = true;
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  render() {
    if (this.props.authed === false) {
      return <Redirect to="/" />;
    } else if (this.props.authed === true) {
      if (this.props.abilitato === false) {
        return <Redirect to={"non-abilitato"} />;
      } else if (
        this.props.notVerified &&
        this.props.location.pathname == "/conferma-mail"
      ) {
        return <Route {...this.props} />;
      } else if (this.props.notVerified) {
        return <Redirect to={"conferma-mail"} />;
      } else if (this.props.location.pathname != "/conferma-mail") {
        return <Route {...this.props} />;
      } else {
        return (
          <Redirect
            to={{ pathname: "/", state: { from: this.props.location } }}
          />
        );
      }
    }
  }
}

export default PrivateRoute;
