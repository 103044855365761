import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./Store";
import { browserName } from "react-device-detect";

import { ConfigProvider } from 'antd';
import itIT from 'antd/lib/locale/it_IT';

if (browserName === "IE") {
  ReactDOM.render(
    <h1>
      Internet Explorer non supporta Garzone. Si consiglia l'utlizzo di un altro
      browser
    </h1>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <React.StrictMode> */}
        <ConfigProvider locale={itIT}>
          <App />
        </ConfigProvider>
        {/* </React.StrictMode> */}
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
