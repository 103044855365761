import React from "react";
import { Row, Col } from "antd";
import Loading from "../Components/Loading";

class Imposta extends React.Component {
  _loader = () => {
    let {
      location: { search },
    } = this.props;
    if (search) {
      let dividedSearch = search.split("&");
      let mode = dividedSearch[0].substring(6);
      if (mode === "verifyEmail") {
        this.props.history.push("/verifica-mail/" + search.substring(1));
      } else if (mode === "recoverEmail") {
        this.props.history.push("/modifica-mail/" + search.substring(1));
      } else if (mode === "resetPassword") {
        this.props.history.push("/aggiorna-password/" + search.substring(1));
      } else {
        this.props.history.push("/404");
      }
    }
  };

  componentDidMount() {
    this._loader();
  }
  render() {
    return (
      <Row style={{ paddingTop: 40 }}>
        <Col xs={{ span: 8, offset: 8 }} md={{ span: 2, offset: 11 }}>
          <Loading loading={true} />
        </Col>
      </Row>
    );
  }
}

export default Imposta;
