import { Functions } from "../Config/Fire";

// ritorna tutte le promozioni di un comune
export function getComunicazioniComune(slug) {
    const comunicazioni = Functions.httpsCallable("utente-getComunicazioniComune");
    return comunicazioni(slug);
}

// ritorna una promozione
export function getComunicazione(id) {
    const comunicazione = Functions.httpsCallable("utente-getComunicazione");
    return comunicazione(id);
}