import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Modal,
  Button,
  Row,
  Col,
  Input,
  Divider,
  notification,
  Drawer,
  Typography,
  Table,
  Space,
  Select,
} from "antd";
import * as Yup from "yup";
import { Formik } from "formik";
import Form from "antd/lib/form/Form";
import {
  CloseOutlined,
  SearchOutlined,
  SendOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Settings from "../Config/Settings";
import { createReso, getResoFromOrderUid } from "../Controllers/Resi";
import Chip from "./Chip";
import { _chipResoStatusColorGetter } from "../Utility/utility";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import "moment/locale/it";
const { Option } = Select;

const ModaleRichiestaReso = ({ ordine, negozio }) => {
  const [reso, setReso] = useState(null);

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [filteredInfo, setfilteredInfo] = useState({});
  const [sortedInfo, setsortedInfo] = useState({});
  const [searchText, setsearchText] = useState(null);
  const [searchedColumn, setsearchedColumn] = useState(null);
  const [submitting, setsubmitting] = useState();
  const [searchInput, setSearchInput] = useState(null);

  const openModal = async () => {
    setLoading(true);
    try {
      const { data } = await getResoFromOrderUid(ordine.firebase_db_order_id);

      setReso(data);
      console.log(reso);
      setVisible(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const checkProdottoInReso = (resi, nomeProdotto) => {
    if (resi) {
      for (let i = 0; i < resi.length; i++) {
        if (resi[i].prodotto && resi[i].prodotto[0].nome === nomeProdotto) {
          return true;
        }
      }
    }

    return false;
  };

  useEffect(() => {
    if (reso) {
      for (let i = 0; i < reso.length; i++) {
        reso[i].prodotto =
          reso && reso[i].prodotto ? JSON.parse(reso[i].prodotto) : [];
      }
    }

    console.log("RESO", reso);
  }, [reso]);
  useEffect(() => {
    if (ordine && ordine.dettagli)
      ordine.dettagli = JSON.parse(ordine.dettagli);
  }, [ordine]);
  /*Funzioni tabelle*/
  const _getColumns = () => {
    return [
      {
        title: "Prodotto",
        dataIndex: "prodotto",
        key: "prodotto",
        width: 300,
        sorter: (a, b) =>
          a.prodotto && b.prodotto
            ? a.prodotto[0].nome.localeCompare(b.prodotto[0].nome)
            : 0,
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps("prodotto", "prodotto"),
        filteredValue: filteredInfo.prodotto || null,
        sortOrder: sortedInfo.columnKey === "prodotto" && sortedInfo.order,
        render: (text, record) => {
          console.log(record);
          return record.prodotto ? (
            <span>{record.prodotto[0].nome}</span>
          ) : null;
        },
      },
      {
        title: "Stato",
        width: 150,
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps("status", "status"),
        filteredValue: filteredInfo.status || null,
        sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
        render: (text, record) => {
          return (
            <Chip
              title={text}
              backgroundColor={_chipResoStatusColorGetter(text)}
            />
          );
        },
      },
      {
        title: "Data creazione",
        width: 250,
        dataIndex: "creazione",
        key: "creazione",
        sorter: (a, b) =>
          moment(a.creazione, "DD/MM/YYYY HH:mm").unix() -
          moment(b.creazione, "DD/MM/YYYY HH:mm").unix(),
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps("creazione", "creazione"),
        filteredValue: filteredInfo.creazione || null,
        sortOrder: sortedInfo.columnKey === "creazione" && sortedInfo.order,
      },
      {
        title: "Motivazione",
        dataIndex: "richiesta",
        key: "richiesta",
        width: 500,
        ...getColumnSearchProps("richiesta", "richiesta"),
        filteredValue: filteredInfo.richiesta || null,
        sortOrder: sortedInfo.columnKey === "richiesta" && sortedInfo.order,
      },
      {
        title: "Note",
        width: 500,
        dataIndex: "note",
        key: "note",
        ...getColumnSearchProps("note", "note"),
        filteredValue: filteredInfo.note || null,
        sortOrder: sortedInfo.columnKey === "note" && sortedInfo.order,
      },
    ];
  };
  const _handleChange = (pagination, filters, sorter) => {
    setfilteredInfo(filters);
    setsortedInfo(sorter);
  };

  const _clearFiltri = () => {
    setfilteredInfo({});
    setsortedInfo({});
  };

  //Ricerca
  const getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Cerca ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Cerca
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : null,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible && searchInput) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) =>
      filteredInfo === null
        ? text
        : searchedColumn === dataIndex && filteredInfo[dataIndex] && text
        ? text
        : text,
  });
  //Ricerca
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  };

  //Ricerca
  const handleReset = (clearFilters) => {
    clearFilters();
    setsearchText("");
  };
  //----------------

  //funzioni tabelle
  const _submitReso = async (values, { setSubmitting }) => {
    const { richiesta, prodotto } = values;
    const prodottoRecord = ordine.dettagli.filter((item) => {
      return item.nome === prodotto;
    });
    console.log("VAL", prodottoRecord);
    try {
      const { data } = await createReso({
        id_ordine: ordine.id,
        id_negozio: negozio.id,
        prodotto: JSON.stringify(prodottoRecord),
        uid_ordine: ordine.firebase_db_order_id,
        richiesta: richiesta,
        status: "in sospeso",
      });
      console.log(data);
      notification.destroy();
      notification.success({
        message: "Congratulazioni!",
        description: `Hai creato con successo una richiesta di reso per l'ordine ${ordine.firebase_db_order_id}. Sarai notificato quando l'amministrazione accetterà la richiesta.`,
      });
      setVisible(false);
    } catch (error) {
      console.log(error);
      notification.destroy();
      notification.error({
        message: "Errore!",
        description:
          "Non è stato possibile inserire la tua richiesta di reso, contatta l'assistenza",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {/* <Button type="primary" onClick={openModal} loading={loading}>
        Gestisci resi
      </Button> */}

      <Drawer
        destroyOnClose
        placement="right"
        closable={true}
        onClose={async () => {
          setVisible(false);
          setReso(null);
        }}
        visible={visible}
        width={"100%"}
        closeIcon={<CloseOutlined style={{ fontSize: 24 }} />}
      >
        <Row>
          <Col span={24}>
            <Row>
              <Typography.Title level={3} style={styles.title}>
                Gestisci i resi per l'ordine {ordine.firebase_db_order_id}
              </Typography.Title>
            </Row>
            <Divider />
            <Row justify="space-between" gutter={[15, 15]}>
              <Col md={12} sm={24}>
                <Row>
                  <Typography.Title level={4} style={styles.title}>
                    Nuova richiesta di reso
                  </Typography.Title>
                </Row>
                <Formik
                  validationSchema={Yup.object().shape({
                    richiesta: Yup.string().required(
                      "Inserisci la tua richiesta"
                    ),
                    prodotto: Yup.string()
                      .nullable()
                      .required("Scegli un prodotto"),
                  })}
                  initialValues={{ richiesta: "", prodotto: null }}
                  validateOnMount={true}
                  validateOnChange={true}
                  onSubmit={_submitReso}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isValid,
                    isSubmitting,
                    dirty,
                  }) => (
                    <Form>
                      <Row>
                        <Col span={24}>
                          <Row>
                            <Col span={24}>
                              <Select
                                onChange={(value) => {
                                  setFieldValue("prodotto", value);
                                }}
                                size="default"
                                placeholder="Seleziona un prodotto"
                                style={{ width: "100%", marginTop: "10px" }}
                                onBlur={handleBlur("prodotto")}
                                value={values.prodotto}
                              >
                                {ordine && ordine.dettagli
                                  ? ordine.dettagli.map((c) =>
                                      checkProdottoInReso(
                                        reso,
                                        c.nome
                                      ) ? null : (
                                        <Option key={c.nome}>{c.nome}</Option>
                                      )
                                    )
                                  : null}
                              </Select>
                              <span style={styles.inputError}>
                                {touched.prodotto && errors.prodotto}
                              </span>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "15px" }}>
                            <Col span={24}>
                              <TextArea
                                value={values.richiesta}
                                onChange={handleChange("richiesta")}
                                autoSize={true}
                                style={{ width: "100%", resize: "none" }}
                                placeholder="Inserisci la motivazione del reso"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                              />
                              <span style={styles.inputError}>
                                {touched.richiesta && errors.richiesta}
                              </span>
                            </Col>
                          </Row>

                          <Row>
                            <Button
                              onClick={handleSubmit}
                              disabled={!isValid || isSubmitting}
                              style={{ width: "100%", marginTop: "15px" }}
                              type="primary"
                              size="large"
                              icon={<PlusOutlined />}
                              block
                            >
                              {submitting ? "Attendi..." : "Crea richiesta"}
                            </Button>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
              <Col md={12} sm={24}>
                <Row>
                  <Typography.Title level={4} style={styles.title}>
                    Resi creati
                  </Typography.Title>
                </Row>
                <Row
                  align="middle"
                  justify="end"
                  gutter={[15, 15]}
                  style={{ marginBottom: 15 }}
                >
                  <Col>
                    <Button onClick={_clearFiltri} size="large">
                      Reset Filtri
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Table
                    dataSource={reso}
                    columns={_getColumns()}
                    onChange={_handleChange}
                    size="small"
                    scroll={{ x: 576, y: 400 }}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "50", "100", "200"],
                    }}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

const styles = {
  inputError: {
    marginTop: "5px",
    fontSize: "12px",
    color: Settings.colors.red,
  },
  title: {
    color: Settings.colors.darkGrey,
    fontWeight: 700,
  },
  subTitle: {
    marginTop: "15px",
    color: Settings.colors.darkGrey,
    fontWeight: 600,
  },
  inputError: {
    color: Settings.colors.red,
    fontSize: "14px",
  },
};

export default ModaleRichiestaReso;
