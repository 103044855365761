import React, { Component } from "react";

// import ant design components
import { Row, Col } from "antd";

// import custom components
import Chip from "./Chip";

// import settings
import Settings from "../Config/Settings";

class CardNegozioSearchInput extends Component {
  render() {
    const { nome, categoria, immagine } = this.props;

    return (
      <div style={{ cursor: "pointer" }}>
        <Row align="middle" gutter={20}>
          <Col flex={"80px"} className="imageCardNegozioSearch">
            {immagine && immagine !== "" ? (
              <img
                alt="immagine"
                src={immagine}
                style={{ width: "80px", height: "60px", borderRadius: "4px" }}
              />
            ) : (
              <img
                alt="immagine"
                src={require("../Assets/Background/placeholder.jpg")}
                style={{ width: "80px", height: "60px", borderRadius: "4px" }}
              />
            )}
          </Col>
          <Col flex="auto">
            <Chip title={categoria ?? "Categoria"} />
            <h4
              style={{
                margin: "10px 0 0 0",
                fontWeight: "bold",
                color: Settings.colors.darkGrey,
              }}
            >
              {nome}
            </h4>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CardNegozioSearchInput;
