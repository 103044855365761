import {
  InfoCircleFilled,
  LoadingOutlined,
  SendOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import {
  Col,
  Image,
  Input,
  List,
  Button,
  notification,
  Row,
  Tooltip,
  Typography,
} from "antd";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import ChatList from "../Components/ChatList";
import Chip from "../Components/Chip";
import Header from "../Components/Header";
import placeholder from "../Assets/Background/placeholder.jpg";
import LoadingBlock from "../Components/LoadingBlock";
import SideMenu from "../Components/SideMenu";
import Settings from "../Config/Settings";

import { Link } from "react-router-dom";

import { getNegozio } from "../Controllers/Negozi";
import { getMessages, postMessage } from "../Controllers/Utente";

class Chats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingChat: true,
      loadingMessages: true,
      visible_menu: false,
      chats: this.props.chats,
      chatAbilitata: true,
      chatInfo: null,
      messages: [],
      message: "",
      first: true,
      sending: false,
      infoPressed: window.innerWidth < 992 ? false : true,
    };
  }
  /*******************************
   * FUNZIONE CHIUDI MENU LATERALE *
   *******************************/
  _closeMenu = () => {
    this.setState({ visible_menu: false });
  };
  /*******************************
   * FUNZIONE APRI MENU LATERALE *
   *******************************/
  _openMenu = () => {
    this.setState({ visible_menu: true });
  };
  /***********************************
   * FUNZIONE CHE INVIA UN MESSAGGIO *
   ***********************************/
  _sendMessage = async () => {
    //controllo se un messaggio è stato impostato
    if (this.state.message) {
      //loading invio
      this.setState({ sending: true });
      //ottenimento messaggio
      const msg = this.state.message;
      this.setState({ message: "" });
      let {
        match: { params },
      } = this.props;
      //ottenimento uid chat
      const { chat_uid } = params;
      try {
        //inserimento messaggio direttamente nell'array dei messaggi per rendere immediato l'invio
        let messages = this.state.messages;
        messages.push({
          firebase_db_chat_id: chat_uid,
          id: this.state.chatInfo.id,
          message: msg,
          type: "text",
          from: this.props.auth.user.uid,
        });
        this.setState({ messages: messages }, () =>
          this._scrollToBottomMessagesBox()
        );

        //invio effettivo al server del messaggio di chat
        postMessage({
          chat_uid: chat_uid,
          id_negozio: this.state.chatInfo.id,
          uid_from: this.props.auth.user.uid,
          messaggio: msg,
        });
        //TODO:update messages
      } catch (error) {
        //gestione errori
        notification.destroy();
        notification.error({
          message: "Errore!",
          description:
            "Non è stato possibile inviare il messaggio. Contattare l'assistenza.",
        });
      } finally {
        //stop del loading dell'invio
        this.setState({ sending: false });
      }
    } else {
      //gestione errori
      notification.destroy();
      notification.error({
        message: "Errore!",
        description: "Inserisci il messaggio",
      });
    }
  };
  /**********************************************
   * RECUPERA I MESSAGGI DELLA CHAT SELEZIONATA *
   **********************************************/
  _getMessages = async (chat_uid) => {
    let { data } = await getMessages(chat_uid, this.props.auth.user.uid);
    console.log(data);
    data = data.map((v) => {
      v.extra = JSON.parse(v.extra);
      return v;
    });
    // salvataggio nello stato dei messaggi
    this.setState(
      { messages: data, loadingMessages: false, first: false },
      () => {
        //scoll alla fine della chat
        this._scrollToBottomMessagesBox();
      }
    );

    //function che ritorna i messaggi
  };
  /*****************************************************************
   * OTTIENE TUTTE LE INFORMAZIONI PER IL FUNZIONAMENTO DELLA CHAT *
   *****************************************************************/
  _getChat = async (chat_uid) => {
    //chat in realtime database
    const { chats } = this.props.chats;
    //controllo esistenza chat, se non esiste redirect 404
    const chatInfo = chats.find((i) => i.chat_uid === chat_uid);
    if (!chatInfo) {
      window.location.href = "/404";
      return;
    }
    //ottenimento dati negozio con cui si andrà a chattare
    const sqlUserTo = await getNegozio(chatInfo.user_sql_id);
    //ottenimento dati comune per info
    // const comuni = await getComuneById(sqlUserTo.data.id_comune);
    // const chatSubtitle = comuni.data.nome;
    // const slugComuneDelNegozio = comuni.data.slug;
    //salvataggio nello stato delle informazioni necessarie
    this.setState({
      chatInfo: {
        ...sqlUserTo.data,
        ...chatInfo,
        // chatSubtitle,
        // slugComuneDelNegozio,
      },
      loadingChat: false,
      chatAbilitata: sqlUserTo.data.chat,
    });
  };
  /*****************************************
   * SCROLL ALLA FINE DEI MESSAGGI DI CHAT *
   *****************************************/
  _scrollToBottomMessagesBox = () => {
    let objDiv = document.getElementById("chat");
    objDiv.scrollTop = objDiv.scrollHeight;
  };

  /**********************************
   * COMPONENT DIDMOUNT E DIDUPDATE *
   **********************************/
  componentDidMount() {
    let {
      match: { params },
    } = this.props;
    //ottenimento uid chat
    const { chat_uid } = params;
    console.log(this.props);
    //imposta loading
    this.setState({
      loadingChat: this.state.first ? true : false,
      loadingMessages: this.state.first ? true : false,
    });
    //recupero messaggi della chat
    if (this.state.messages.length === 0) this._getMessages(chat_uid);
    console.log(this.state.messages);
    //recupero dati della chat
    if (this.state.chatInfo === null) this._getChat(chat_uid);
  }
  componentDidUpdate(prevProps) {
    let {
      match: { params },
    } = this.props;
    //ottenimento uid chat
    const { chat_uid } = params;
    if (prevProps.match.params.chat_uid !== chat_uid) {
      //imposta loading
      this.setState({
        loadingChat: true,
        loadingMessages: true,
      });
    }
    if (
      prevProps.chats.toReadNumber !== this.props.chats.toReadNumber ||
      prevProps.match.params.chat_uid !== chat_uid
    ) {
      //recupero messaggi della chat
      console.log("UPDATE", this.props.chats);
      this._getMessages(chat_uid);
      //recupero dati della chat
      this._getChat(chat_uid);
    }
  }
  /**********
   * RENDER *
   **********/
  render() {
    const { loadingChat, loadingMessages, visible_menu, messages, chatInfo } =
      this.state;
    return (
      <>
        <Helmet>
          <title>Garzone Store - Chat</title>
        </Helmet>
        <Header account onMenuClick={this._openMenu} />
        <SideMenu
          slug={this.props.slug}
          visible_menu={visible_menu}
          _closeMenu={this._closeMenu}
        />
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "400px",
              height: "100%",
              overflowY: "scroll",
              top: 0,
              left: 0,
              padding: "15px",
              paddingTop: "159px",
              zIndex: 8888,
              backgroundColor: Settings.colors.white,
              borderRight: "1px solid",
              borderColor: Settings.colors.lightGrey,
            }}
            className="chatPanel"
          >
            <ChatList />
          </div>
          <div
            style={{
              position: "absolute",
              left: "400px",
              top: 0,
              width: "calc(100% - 400px)",
              height: "100%",
              zIndex: 9999,
            }}
            className="chatList"
          >
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "70px",
                top: "144px",
                left: 0,
                borderBottom: "1px solid",
                zIndex: 9999,
                backgroundColor: Settings.colors.white,
                borderColor: Settings.colors.lightGrey,
              }}
            >
              {loadingChat ? null : (
                <Row
                  justify="space-between"
                  align="middle"
                  style={{
                    height: "100%",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  <Col>
                    <Row justify="start" align="middle" gutter={15}>
                      <Col className="backToChats">
                        <Link to="/chats">
                          <LeftOutlined /> Chat
                        </Link>
                      </Col>
                      <Col>
                        {chatInfo && (
                          <Image
                            height="40px"
                            width="53px"
                            style={styles.chat_image}
                            src={
                              chatInfo.logo
                                ? `https://storage.googleapis.com/piattaforma-garzone${process.env.REACT_APP_STAGE ===
                                  "development"
                                  ? "-test"
                                  : ""
                                }.appspot.com/negozi/${chatInfo.user_sql_id
                                }/corporate/logo.png`
                                : placeholder
                            }
                            placeholder={placeholder}
                            preview={placeholder}
                            fallback={placeholder}
                          />
                        )}
                      </Col>
                      <Col>
                        <Row>
                          <Typography.Title level={3} style={styles.chat_title}>
                            {chatInfo && chatInfo.chat_title}
                          </Typography.Title>
                        </Row>
                        {/* <Row>
                          <Typography.Title
                            level={4}
                            style={styles.chat_subtitle}
                          >
                            {chatInfo && chatInfo.chatSubtitle}
                          </Typography.Title>
                        </Row> */}
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Tooltip
                      title="Informazioni negozio"
                      placement="bottomRight"
                      color={Settings.colors.darkGrey}
                    >
                      <InfoCircleFilled
                        style={{
                          fontSize: 32,
                          color: this.state.infoPressed
                            ? Settings.colors.cyan
                            : Settings.colors.darkGrey,
                        }}
                        onClick={() =>
                          this.setState({
                            infoPressed: !this.state.infoPressed,
                          })
                        }
                      />
                    </Tooltip>
                  </Col>
                </Row>
              )}
            </div>

            <div
              style={{
                position: "absolute",
                width: this.state.infoPressed ? "calc(100% - 300px)" : "100%",
                height: "calc(100% - 284px)",
                padding: "15px",
                top: "214px",
                bottom: "70px",
                left: 0,
                borderBottom: "1px solid",
                overflowY: "scroll",
                borderColor: Settings.colors.lightGrey,
              }}
              id="chat"
              className="chatList"
            >
              {loadingMessages ? (
                <LoadingBlock loading={true} />
              ) : (
                <List
                  locale={{ emptyText: "Nessun Messaggio" }}
                  dataSource={this.state.messages}
                  renderItem={({
                    firebase_db_chat_id,
                    from,
                    type,
                    message,
                    extra,
                    timestamp,
                  }) => (
                    <List.Item
                      style={{
                        textAlign:
                          this.props.auth.user.uid === from ? "right" : "left",
                        border: "none",
                        padding: "4px 0px",
                      }}
                    >
                      <List.Item.Meta
                        title={
                          <span
                            style={{
                              color:
                                this.props.auth.user.uid === from
                                  ? Settings.colors.white
                                  : Settings.colors.darkGrey,
                              padding: "5px 10px",
                              background:
                                this.props.auth.user.uid === from
                                  ? Settings.colors.cyan
                                  : Settings.colors.lightGrey,
                              borderRadius: "4px",
                              maxWidth: "80%",
                              display: "inline-block",
                              textAlign: "left",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {type === "text" ? (
                              message
                            ) : type === "preventivo" ? (
                              <>
                                <Col span={24}>
                                  <Row
                                    style={{ marginBottom: 15, fontSize: 18 }}
                                  >
                                    <b>RICHIESTA PREVENTIVO</b>
                                  </Row>
                                  <Row style={{ marginBottom: 30 }}>
                                    {message}
                                  </Row>
                                  <Row style={{ marginBottom: 15 }}>
                                    <b>DETTAGLI</b>
                                  </Row>
                                  {extra && extra.consegna && (
                                    <Row>
                                      <b>Modalità ordine: </b>{" "}
                                      {extra.consegna === "ritiro"
                                        ? "Ritira in negozio"
                                        : "Consegna a domicilio"}
                                    </Row>
                                  )}
                                  {extra && extra.indirizzo && (
                                    <Row>
                                      <b>Indirizzo di consegna: </b>{" "}
                                      {extra.indirizzo}
                                    </Row>
                                  )}
                                  {extra && extra.tipo_pagamento && (
                                    <Row>
                                      <b>Tipo pagamento: </b>{" "}
                                      {extra.tipo_pagamento === "carta"
                                        ? "Pagamento online"
                                        : extra.consegna === "ritiro"
                                          ? "Al ritiro"
                                          : "Alla Consegna"}
                                    </Row>
                                  )}
                                </Col>
                              </>
                            ) : (
                              message
                            )}
                          </span>
                        }
                      />
                    </List.Item>
                  )}
                />
              )}
            </div>
            <div
              style={{
                display: this.state.infoPressed ? "initial" : "none",
                padding: "15px",
                position: "absolute",
                width: "300px",
                height: "calc(100% - 214px)",
                right: 0,
                top: "214px",
                borderLeft: "1px solid",
                borderColor: Settings.colors.lightGrey,
                zIndex: 9999,
                overflowY: "scroll",
                paddingTop: "30px",
                textAlign: "center",
              }}
              className="chatDetails"
            >
              {loadingChat ? null : (
                <>
                  <Row justify="center" align="middle" style={styles.section}>
                    {chatInfo && (
                      <Image
                        height="100px"
                        width="133px"
                        src={
                          chatInfo.logo
                            ? `https://storage.googleapis.com/piattaforma-garzone${process.env.REACT_APP_STAGE === "development"
                              ? "-test"
                              : ""
                            }.appspot.com/negozi/${chatInfo.user_sql_id
                            }/corporate/logo.png`
                            : placeholder
                        }
                        placeholder={placeholder}
                        className="radius"
                        preview={placeholder}
                        fallback={placeholder}
                      />
                    )}
                  </Row>
                  <Row justify="center" align="middle" style={styles.section}>
                    <Chip title={chatInfo && chatInfo.categoria} />
                  </Row>
                  <Row justify="center" align="middle" style={styles.section}>
                    <Typography.Title level={3} style={styles.rightTitle}>
                      {chatInfo && chatInfo.chat_title}
                    </Typography.Title>
                  </Row>
                  <Row justify="center" align="middle" style={styles.section}>
                    <Typography.Title level={4} style={styles.rightSubtitle}>
                      {chatInfo &&
                        `${chatInfo.indirizzo} ${chatInfo.civico}, ${chatInfo.cap} ${chatInfo.citta}`}
                    </Typography.Title>
                  </Row>
                  <Row justify="center" align="middle" style={styles.section}>
                    {chatInfo ? (
                      <Link
                        to={
                          "/comuni/" +
                          this.props.slug +
                          "/negozi/" +
                          chatInfo.slug
                        }
                        target="_blank"
                      >
                        <Button type="link" size="small">
                          Vedi scheda negozio
                        </Button>
                      </Link>
                    ) : null}
                  </Row>
                </>
              )}
            </div>
            <div
              style={{
                background: Settings.colors.white,
                position: "absolute",
                padding: "15px",
                width: "100%",
                height: "70px",
                textAlign: "center",
                bottom: 0,
                paddingRight: this.state.infoPressed ? "315px" : "15px",
              }}
              className="chatList"
            >
              <Row justify="space-between" align="middle" gutter={[15, 15]}>
                {/* <Col span={4}>
                <PlusSquareFilled style={{ fontSize: "40px" }} />
              </Col> */}

                {this.state.chatAbilitata ? (
                  <>
                    <Col flex={1}>
                      <Input
                        disabled={loadingMessages}
                        type="text"
                        placeholder="Digita un messaggio..."
                        style={{ height: "40px" }}
                        onChange={(e) =>
                          this.setState({ message: e.target.value })
                        }
                        value={this.state.message}
                        onPressEnter={
                          this.state.message === "" ||
                            this.state.message.length === 0
                            ? null
                            : this._sendMessage
                        }
                        onFocus={(e) => this._scrollToBottomMessagesBox()}
                      />{" "}
                    </Col>
                    <Col>
                      {this.state.sending ? (
                        <LoadingOutlined style={{ fontSize: "40px" }} />
                      ) : (
                        <SendOutlined
                          style={{ fontSize: "40px" }}
                          onClick={
                            this.state.message === "" ||
                              this.state.message.length === 0
                              ? null
                              : this._sendMessage
                          }
                        />
                      )}
                    </Col>
                  </>
                ) : (
                  <Col span={24}>
                    <span style={styles.chatNonAbilitata}>
                      Il negozio ha disattivato la funzione “Chat”.
                    </span>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  chatNonAbilitata: {
    fontSize: 17,
    color: Settings.colors.red,
  },
  chat_image: {
    height: "40px",
    borderRadius: "4px",
    overflow: "hidden",
    marginTop: "7.5px",
  },
  chat_title: {
    fontSize: 17,
    fontWeight: 700,
    color: Settings.colors.darkGrey,
    margin: 0,
  },
  chat_subtitle: {
    fontSize: 14,
    fontWeight: 500,
    color: Settings.colors.grey,
    margin: 0,
  },
  rightTitle: {
    fontSize: 22,
    fontWeight: 700,
    color: Settings.colors.darkGrey,
    margin: 0,
  },
  rightSubtitle: {
    fontSize: 14,
    fontWeight: 500,
    color: Settings.colors.grey,
  },
  section: {
    marginBottom: "10px",
  },
};

function mapStateToProps(state) {
  return {
    id: state.comuni.id,
    slug: state.comuni.slug,
    nome: state.comuni.nome,
    logo: state.comuni.logo,
    auth: state.auth,
    chats: state.chat,
  };
}

export default connect(mapStateToProps, null)(Chats);
