import Settings from "../Config/Settings";

export function _findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    var items = window.location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

export function _chunkArray(myArray, chunk_size) {
    let index = 0;
    let arrayLength = myArray.length;
    let tempArray = [];
    let myChunk = []

    for (index = 0; index < arrayLength; index += chunk_size) {
        myChunk = myArray.slice(index, index + chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
    }

    return tempArray;
}

export function _isLocalhost() {
    return window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"
}

export function _getOrderColor(orderStatus) {
    switch (orderStatus.toLowerCase()) {
        case 'in attesa di conferma':
            return Settings.colors.yellow
        case 'in attesa di pagamento':
            return Settings.colors.yellow
        case 'in lavorazione':
            return Settings.colors.lightBlue
        case 'pronto per il ritiro':
            return Settings.colors.lightBlue
        case 'in consegna':
            return Settings.colors.lightBlue
        case 'consegnato':
            return Settings.colors.blue
        case 'preventivo':
            return Settings.colors.darkGrey
        default:
            return Settings.colors.statusRed
    }
}

export function _timeDifference(current, previous) {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
        return "Alcuni secondi fa";
    } else if (elapsed < msPerHour) {
        let min = Math.round(elapsed / msPerMinute);
        return min + ` minut${min === 1 ? "o" : "i"} fa`;
    } else if (elapsed < msPerDay) {
        let ore = Math.round(elapsed / msPerHour);
        return ore + ` or${ore === 1 ? "a" : "e"} fa`;
    } else if (elapsed < msPerMonth) {
        let giorni = Math.round(elapsed / msPerDay);
        return giorni + ` giorn${giorni === 1 ? "o" : "i"} fa`;
    } else if (elapsed < msPerYear) {
        let mese = Math.round(elapsed / msPerMonth);
        return mese + ` mes${mese === 1 ? "e" : "i"} fa`;
    } else {
        let anno = Math.round(elapsed / msPerYear);
        return anno + ` ann${anno === 1 ? "o" : "i"} fa`;
    }
};

export function _eventStyleGetter(event, start, end, isSelected) {
    let style = { backgroundColor: Settings.colors.grey };
    return { style: style };
};

export function _chipResoStatusColorGetter(status) {
    switch (status.toLowerCase()) {
        case "in sospeso":
            return Settings.colors.yellow;
        case "rifiutato":
            return Settings.colors.red;
        case "accettato":
            return Settings.colors.cyan;
        default:
            return Settings.colors.darkGrey;
    }
};
