import React from "react";
import firebase from "firebase";

// Third-party components
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Alert, Button, notification, Row } from "antd";

// Custom components
import PrivateRoute from "./Components/PrivateRoute";
import Loading from "./Components/Loading";
import ScrollToTop from "./Components/ScrollToTop";

//Auth
import { checkAuthState, getClaims, logout } from "./Controllers/Auth";
import { successLogin, successLogout } from "./Actions/AuthActions";
import { connect } from "react-redux";

import {
  getUserChatsRef,
  getUtente,
  getUserOrdersRef,
} from "./Controllers/Utente";

// Libraries
import Cookies from "universal-cookie";
//browser check
import { isIE } from "react-device-detect";

// Screens
import Comune from "./Screens/Comune";
import Homepage from "./Screens/Homepage";
import Negozi from "./Screens/Negozi";
import Negozio from "./Screens/Negozio";
import Promozioni from "./Screens/Promozioni";
import Promozione from "./Screens/Promozione";
import AggiornaPassword from "./Screens/AggiornaPassword";
import VerificaMail from "./Screens/VerificaMail";
import ModificaMail from "./Screens/ModificaMail";
import Imposta from "./Screens/Imposta";
import InfoComune from "./Screens/InfoComune";
import Account from "./Screens/Account";
import ConfermaMail from "./Screens/ConfermaMail";
import PaginaNonTrovata from "./Screens/PaginaNonTrovata";
import Privacy from "./Screens/Privacy";
import Chats from "./Screens/Chats";
import Chat from "./Screens/Chat";
import Orders from "./Screens/Orders";
import Ordine from "./Screens/Order";

import "./App.less";
import "./App.css";
import NonAbilitato from "./Screens/NonAbilitato";
import { setChats } from "./Actions/ChatActions";
import { setOrders } from "./Actions/OrdiniActions";
import Agenda from "./Screens/Agenda";
import Comunicazioni from "./Screens/Comunicazioni";
import Comunicazione from "./Screens/Comunicazione";
import Settings from "./Config/Settings";
import { _isLocalhost } from "./Utility/utility";
import CondizioniVendita from "./Screens/CondizioniVendita";
import CookiePolicy from "./Screens/CookiePolicy";

const cookies = new Cookies();
const OneSignal = window.OneSignal || [];
OneSignal.push(function () {
  OneSignal.init({
    appId: process.env.REACT_APP_ONESIGNAL_UTENTE_APP_ID,
    safari_web_id: process.env.REACT_APP_ONESIGNAL_UTENTE_SAFARI_ID,
    autoResubscribe: true,
  });
});

if (OneSignal.installServiceWorker) {
  OneSignal.installServiceWorker();
} else {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.register(
      `/OneSignalSDKWorker.js?appId=${process.env.REACT_APP_ONESIGNAL_UTENTE_APP_ID}`
    );
  }
}
class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authed: false,
      loading: true,
      not_verified: true,
      abilitato: false,
      cookie: false,
      chats: [],
    };
    // append noindex for google robots if env is development
    if (process.env.REACT_APP_STAGE == "development") {
      var noIndex = document.createElement("meta");
      noIndex.setAttribute("name", "robots");
      noIndex.content = "noindex";
      document.getElementsByTagName("head")[0].appendChild(noIndex);
    }
  }
  _clearTags = () => {
    OneSignal.push(function () {
      OneSignal.deleteTags(["uid", "citta"], function (tagsDeleted) {
        console.log("tags deleted", tagsDeleted);
      });
    });
  };
  _setChats = (chats) => {
    let chatsArray = [];
    for (const key in chats) {
      if (Object.hasOwnProperty.call(chats, key)) {
        chatsArray.push({ chat_uid: key, ...chats[key] });
      }
    }
    chatsArray.sort((a, b) =>
      a.last_update < b.last_update ? 1 : b.last_update < a.last_update ? -1 : 0
    );
    return chatsArray;
  };
  _setOrders = (orders) => {
    let ordersArray = [];
    for (const key in orders) {
      if (Object.hasOwnProperty.call(orders, key)) {
        ordersArray.push({ order_uid: key, ...orders[key] });
      }
    }
    ordersArray.sort((a, b) =>
      a.last_update < b.last_update ? 1 : b.last_update < a.last_update ? -1 : 0
    );
    return ordersArray;
  };
  _setCookie = () => {
    cookies.set("alert", true);
    this.setState({ cookie: !this.state.cookie })
  };

  _getUserDb = async () => {
    try {
      const { data } = await getUtente(firebase.auth().currentUser.uid);
      return data;
    } catch (error) {
      console.log(error);
      notification.destroy();
      notification.error({
        message: "Errore",
        description: "Errore nella richiesta, contattare l'assistenza",
      });
      await logout();
      this.props.successLogout();
    }
  };
  _getToReadNumber = (chats) => {
    var num = 0;
    for (const key in chats) {
      const item = chats[key];
      if (item.to_read === true) {
        num++;
      }
    }
    return num;
  };
  _getClaims = async () => {
    try {
      let { claims } = await getClaims();

      const userDb = await this._getUserDb();

      const providers = [];
      const providerData = firebase.auth().currentUser.providerData;
      for (const key in providerData) {
        providers.push({
          name: providerData[key].providerId,
          value:
            providerData[key].providerId === "google.com"
              ? providerData[key].email
              : providerData[key].uid,
        });
      }
      let logged_user;
      if (claims.utente) {
        logged_user = {
          is_logged_in: true,
          email: claims.email,
          email_verified: claims.email_verified,
          uid: claims.user_id,
          nome: userDb.nome,
          cognome: userDb.cognome,
          citta: userDb.citta,
          id: userDb.id,
          indirizzi: JSON.parse(userDb.indirizzi),
          phoneNumber: claims.phone_number,
          providers: providers,
        };
        //invio tag OneSignal
        try {
          OneSignal.push(function () {
            OneSignal.sendTags({
              uid: logged_user.uid,
              citta: logged_user.citta,
            });
          });
        } catch (error) {
          console.log("Error", error);
        }

        //email, phone, google.com
        this.setState({ abilitato: userDb.abilitato });

        //Check if user's email is verified
        if (!logged_user.email_verified) {
          this.setState({ authed: true, loading: false, not_verified: true });
        } else {
          this.setState({ authed: true, loading: false, not_verified: false });
        }
        //Save user in global state
        this.props.successLogin(logged_user);
      } else if (claims.negozio) {
        // change local state
        this.setState({ authed: false, loading: false });
        // change global state
        this._clearTags();
        await logout();
        this.props.successLogout();
        window.location.reload();
      } else if (claims.firebase.sign_in_provider) {
        this.props.successLogin(logged_user);

        if (!claims.email_verified) {
          this.setState({ authed: true, loading: false, not_verified: true });
        } else {
          this.setState({ authed: true, loading: false, not_verified: false });
        }
      } else {
        // change local state
        this.setState({ authed: false, loading: false });
        // change global state
        this._clearTags();
        await logout();
        this.props.successLogout();
      }
    } catch (error) {
      console.log("errore", error);
      this._clearTags();
      await logout();
      this.props.successLogout();
    }
  };

  componentDidMount() {
    if (cookies.get("alert") == "true") {
      this.setState({ cookie: true });
    }

    const self = this;
    OneSignal.push(function () {
      OneSignal.on("notificationPermissionChange", function (permissionChange) {
        var currentPermission = permissionChange.to;
        if (self.state.authed) {
          const user = firebase.auth().currentUser;
          OneSignal.sendTags(
            {
              uid: user.uid,
              citta: self.props.auth.user.citta,
            },
            function (tagsSent) {
              console.log("Tag spediti", tagsSent);
            }
          );
        }

        window.location.reload();
      });
    });

    this.authListener = checkAuthState((user) => {
      if (user) {
        this._getClaims();

        const chatsRealtimeRef = getUserChatsRef(
          firebase.auth().currentUser.uid
        );
        chatsRealtimeRef.on("value", (snapshot) => {
          const chats = snapshot.val();
          const toReadNumber = this._getToReadNumber(chats);
          this.props.setChats({
            chats: this._setChats(chats),
            toReadNumber: toReadNumber,
          });
        });
        const ordersRealtimeRef = getUserOrdersRef(
          firebase.auth().currentUser.uid
        );
        ordersRealtimeRef.on("value", (snapshot) => {
          const orders = snapshot.val();
          const toReadNumber = this._getToReadNumber(orders);
          this.props.setOrders({
            orders: this._setOrders(orders),
            toReadNumber: toReadNumber,
          });
        });
      } else {
        this.setState({ authed: false, loading: false });
        // change global state
        this._clearTags();
        this.props.successLogout();
      }
    });
  }

  render() {
    if (isIE)
      return (
        <div>
          {" "}
          Internet Explorer non è supportato. Download Chrome/Opera/Firefox{" "}
        </div>
      );
    return this.state.loading === true ? (
      <div>
        <Loading loading={this.state.loading} />
      </div>
    ) : (
      <Router>
        <ScrollToTop />
        <div>
          {process.env.REACT_APP_STAGE == "development" ? (
            <div
              style={{
                width: "150px",
                display: "inline-block",
                transform: "rotate(45deg)",
                background: "yellow",
                top: 30,
                right: -30,
                zIndex: 5000000,
                position: "fixed",
                textAlign: "center",
                fontSize: "10pt",
              }}
            >
              {process.env.REACT_APP_STAGE.toUpperCase().substring(0, 3)}
            </div>
          ) : null}{" "}
          <Switch>
            {/* Public routes */}

            <Route exact path="/" component={Homepage} />
            <Route exact path="/comuni/:slug" component={Comune} />
            <Route exact path="/comuni/:slug/negozi" component={Negozi} />
            <Route
              path="/comuni/:slug/negozi/:slugNegozio"
              component={Negozio}
            />
            <Route
              exact
              path="/comuni/:slug/promozioni"
              component={Promozioni}
            />
            <Route path="/comuni/:slug/promozioni/:id" component={Promozione} />

            <Route
              exact
              path="/comuni/:slug/comunicazioni"
              component={Comunicazioni}
            />
            <Route
              path="/comuni/:slug/comunicazioni/:id"
              component={Comunicazione}
            />

            <Route
              path="/aggiorna-password/:mode"
              component={AggiornaPassword}
            />
            <Route path="/verifica-mail/:mode" component={VerificaMail} />
            <Route path="/modifica-mail" component={ModificaMail} />
            <Route path="/imposta" component={Imposta} />
            <Route path="/per-i-comuni" component={InfoComune} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/condizioni-vendita" component={CondizioniVendita} />
            <Route path="/cookie-policy" component={CookiePolicy} />
            <Route path="/non-abilitato" component={NonAbilitato} />

            <PrivateRoute
              exact
              path="/chats"
              abilitato={this.state.abilitato}
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Chats}
              chat
            />

            <PrivateRoute
              exact
              path="/chats/:chat_uid"
              abilitato={this.state.abilitato}
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Chat}
              chat
            />

            <PrivateRoute
              path="/account"
              abilitato={this.state.abilitato}
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Account}
              account={true}
            />
            <PrivateRoute
              exact
              path="/ordini/:order_uid"
              abilitato={this.state.abilitato}
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              funzioneNonAbilitata={this.state.ordiniNonAbilitata}
              component={Ordine}
              chat
            />
            <PrivateRoute
              exact
              path="/ordini"
              abilitato={this.state.abilitato}
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              funzioneNonAbilitata={this.state.ordiniNonAbilitata}
              component={Orders}
            />
            <PrivateRoute
              exact
              path="/agenda"
              abilitato={this.state.abilitato}
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Agenda}
            />

            <PrivateRoute
              path="/conferma-mail"
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              abilitato={this.state.abilitato}
              component={ConfermaMail}
            />
            {/* 404 */}
            <Route component={PaginaNonTrovata} />
            <Route path="/404" component={PaginaNonTrovata} />
          </Switch>
          {/* Cookies banner */}
          <Alert
            message="Informativa"
            description={
              <>
                <Row>
                  <span style={{ fontSize: "12px", lineHeight: "12px", marginBottom: '15px' }}>
                    Questo sito utilizza cookie, anche di terze parti, per inviarti pubblicità in linea con le tue preferenze. Per saperne di più o negare il consenso a tutti o ad alcuni dei cookie clicca <Link to="/cookie-policy"> qui</Link>.
                    Cliccando su “Ok, ho capito” acconsenti all’uso dei cookie.
                  </span>
                </Row>
                <Row>
                  <Button type="primary" onClick={this._setCookie}>Ok, ho capito</Button>
                </Row>
              </>
            }
            type="info"
            showIcon
            style={{
              zIndex: 888888888888,
              position: "fixed",
              top: 0,
              left: 0,
              width: '100%',
              borderRadius: 0,
              border: 'none',
              display: this.state.cookie ? "none" : "block",
              boxShadow: '0 10px 25px rgba(0,0,0,0.5)'
            }}
          />
        </div>
      </Router>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successLogin: (user) => dispatch(successLogin(user)),
    successLogout: () => dispatch(successLogout()),
    setChats: (chats) => dispatch(setChats(chats)),
    setOrders: (orders) => dispatch(setOrders(orders)),
  };
}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
