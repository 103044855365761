export default {
  colors: {
    cyan: "#00BECE",
    lightCyan: "rgba(0, 190, 206, 0.1)",
    darkGrey: "#516375",
    grey: "#C0C1C2",
    lightGrey: "#F2F3F4",
    red: "#CF230A",
    white: "#FFFFFF",
    black: "#000000",
    darkerGrey: "#485968",
    yellow: "#E6B82C",
    lightBlue: "#18E6BB",
    blue: "#0D9AE6",
    statusRed: "#CF2313",
  },
  base_url: {
    admin:
      window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1"
        ? "http://localhost:3000"
        : process.env.REACT_APP_BASE_URL_ADMIN,
    negozio:
      window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1"
        ? "http://localhost:3000"
        : process.env.REACT_APP_BASE_URL_NEGOZIO,
    comune:
      window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1"
        ? "http://localhost:3000"
        : process.env.REACT_APP_BASE_URL_COMUNE,
    utente:
      window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1"
        ? "http://localhost:3000"
        : process.env.REACT_APP_BASE_URL_UTENTE,
  },
  get_google_api_key: () => {
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
    ) {
      return process.env.REACT_APP_GOOGLEAPIKEY;
    } else {
      return process.env.REACT_APP_GOOGLEAPIKEY_CLOUD;
    }
  },
};
