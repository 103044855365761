import React from "react";
import { Formik } from "formik";
import { Button, Col, notification, Row, Switch, Typography } from "antd";
import { aggiornaPermessi } from "../Controllers/Utente";
import Settings from "../Config/Settings";
import { EditOutlined } from "@ant-design/icons";

const PermessiUtente = ({ marketing, profilazione }) => {
  const _submitForm = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      const record = {
        marketing: values.marketing,
        profilazione: values.profilazione,
      };
      await aggiornaPermessi(record);
      notification.destroy();
      notification.success({
        message: "Perfetto!",
        description: "Hai aggiornato i permessi correttamente",
      });
      resetForm();
    } catch (error) {
      console.log(error);
      notification.destroy();
      notification.error({
        message: "Errore",
        description: "Errore nella richiesta, contattare l'assistenza",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        validateOnMount={false}
        validateOnChange={true}
        initialValues={{ marketing: marketing, profilazione: profilazione }}
        onSubmit={_submitForm}
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          isValid,
          isSubmitting,
          dirty,
        }) => (
          <>
            <Typography.Title level={3} style={styles.title}>
              Permessi
            </Typography.Title>
            <Col span={24}>
              <Row
                align="middle"
                justify="start"
                gutter={15}
                style={{ marginBottom: "15px" }}
              >
                <Col>
                  <Switch
                    defaultChecked={marketing}
                    onChange={(checked) => setFieldValue("marketing", checked)}
                  />
                </Col>
                <Col>
                  <span>Marketing</span>
                </Col>
              </Row>
              <Row
                align="middle"
                justify="start"
                gutter={15}
                style={{ marginBottom: "15px" }}
              >
                <Col>
                  <Switch
                    defaultChecked={profilazione}
                    onChange={(checked) =>
                      setFieldValue("profilazione", checked)
                    }
                  />
                </Col>
                <Col>
                  <span>Profilazione</span>
                </Col>
              </Row>
              <Button
                icon={<EditOutlined />}
                loading={isSubmitting}
                type="primary"
                disabled={!(isValid && dirty) || isSubmitting}
                onClick={handleSubmit}
              >
                {isSubmitting ? "Attendi..." : "Aggiorna"}
              </Button>
            </Col>
          </>
        )}
      </Formik>
    </>
  );
};
const styles = {
  wrapper: {
    padding: "25px 15px",
  },
  menu_item: {
    borderBottom: "1px solid " + Settings.colors.lightGrey,
    padding: "10px 20px",
    color: Settings.colors.darkGrey,
  },
  menu_item_title: {
    fontSize: "18px",
    display: "block",
  },
  menu_item_subtitle: {
    fontSize: "14px",
    color: Settings.colors.red,
    display: "block",
  },
  title: {
    color: Settings.colors.darkGrey,
  },
  label: {
    color: Settings.colors.darkGrey,
    fontWeight: 700,
  },
  inputError: {
    color: Settings.colors.red,
    fontSize: "14px",
  },
};
export default PermessiUtente;
