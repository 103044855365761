import {
  EditOutlined,
  GoogleCircleFilled,
  MailFilled,
  PhoneFilled,
  DeleteOutlined,
  ExclamationCircleOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";
import firebase from "firebase";
import * as Yup from "yup";

import {
  Button,
  Col,
  Divider,
  Image,
  Input,
  notification,
  Popover,
  Row,
  Select,
  Typography,
  List,
  Modal,
  Switch,
} from "antd";
import React from "react";
import Header from "../Components/Header";
import Loading from "../Components/Loading";
import Settings from "../Config/Settings";
import { connect } from "react-redux";
import SideMenu from "../Components/SideMenu";
import { Helmet } from "react-helmet";
import {
  aggiornaUtente,
  getUtente,
  updateUserMailchimp,
  postIndirizzi,
} from "../Controllers/Utente";
import {
  logout,
  requestResetPassword,
  resendEmailVerification,
  updateUserEmail,
} from "../Controllers/Auth";
import CountdownMail from "../Components/CountdownMail";
import PhoneInput from "react-phone-input-2";
import { Formik } from "formik";
import AggiungiIndirizzo from "../Components/AggiungiIndirizzo";
import { successLogin } from "../Actions/AuthActions";
import { isIOS, isMobile, isSafari } from "react-device-detect";
import Form from "antd/lib/form/Form";
import PermessiUtente from "../Components/PermessiUtente";

const comuni_json = require("../Assets/comuni.json");
const { Option } = Select;

const OneSignal = window.OneSignal || [];

const { Title } = Typography;
async function getSubscriptionState() {
  var isPushEnabled = await OneSignal.isPushNotificationsEnabled();
  var isOptedOut = await OneSignal.isOptedOut();
  return {
    isPushEnabled: isPushEnabled,
    isOptedOut: isOptedOut,
  };
}

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {},

      indirizzi: this.props.auth.user.indirizzi,
      notificationEnabled: false,
      loading: false,
      visible_menu: false,
      userDb: null,
      cambiaMailVisible: false,
      countdownMailVisible: false,

      recuperaPasswordVisible: false,
      countdownPasswordVisible: false,

      selected_indirizzo: null,
      edited_indirizzo: null,
      edit_indirizzo_loading: false,
      edit_indirizzo_visible: false,
      delete_indirizzo_loading: false,
    };
  }

  _cambiaMail = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    this.setState({ loadingMail: true });
    const { mail } = values;

    try {
      await updateUserMailchimp(this.props.auth.user.email, mail);
      await updateUserEmail(mail);
      await resendEmailVerification();
      this.setState({ cambiaMailVisible: false });
      notification.success({
        message: "E-mail aggiornata",
        description:
          "Il tuo indirizzo email è stato correttamente aggiornato. Tra 5 secondi dovrai riloggare",
      });
      setTimeout(async () => {
        this._clearTags();
        await logout();
      }, 5000);
    } catch (e) {
      if (e.code == "auth/invalid-email") {
        resetForm();
        notification.error({
          message: "Email non valida",
          description:
            "L'email che hai inserito non è valida. Inseriscine un'altra.",
        });
      } else if (e.code == "auth/requires-recent-login") {
        notification.warn({
          message: "Login richiesto",
          description:
            "Per effettuare il cambio mail, devi rifare l'accesso a Garzone. Verrai reindirizzato alla pagina di login tra pochi secondi.",
        });
        this.setState({ loadingMail: true });
        setTimeout(() => {
          logout();
          this.props.history.push("/");
        }, 5000);
      } else if (e.code == "auth/email-already-in-use") {
        resetForm();
        notification.error({
          message: "Email già in uso",
          description:
            "L'email inserita è già stata usata da un'altro utente. Inseriscine un'altra.",
        });
      } else {
        notification.error({
          message: "Errore",
          description:
            "Si è verificato un errore durante l'aggiornamento dell'indirizzo email",
        });
      }

      console.log("Error:", e);
    } finally {
      this.setState({ loadingMail: false });
    }
  };

  _subscribeOneSignal = () => {
    OneSignal.push(function () {
      OneSignal.showNativePrompt();
    });
  };

  setIndirizzi = (indirizzi) => {
    this.setState({ indirizzi: indirizzi });
  };

  editIndirizzo = () => {
    this.setState({ edit_indirizzo_loading: true });
    this.setState({
      edit_indirizzo_loading: false,
      edited_indirizzo: "",
      edit_indirizzo_visible: false,
    });

    // if(res.data.status == "success") //TODO
  };

  openEditModal = () => {
    this.setState({ edit_indirizzo_visible: true });
  };

  closeEditModal = () => {
    this.setState({ edit_indirizzo_visible: false });
  };

  deleteIndirizzo = async (indirizzo, civico, citta, cap) => {
    try {
      this.setState({ delete_indirizzo_loading: true });
      let nuoviIndirizzi = [];
      for (let i = 0; i < this.state.indirizzi.length; i++) {
        if (
          this.state.indirizzi[i].indirizzo !== indirizzo ||
          this.state.indirizzi[i].civico !== civico ||
          this.state.indirizzi[i].citta !== citta ||
          this.state.indirizzi[i].cap !== cap
        ) {
          nuoviIndirizzi.push(this.state.indirizzi[i]);
        }
      }
      let res = await postIndirizzi({
        id: firebase.auth().currentUser.uid,
        indirizzi: nuoviIndirizzi,
      });
      this.setState({
        delete_indirizzo_loading: false,
        indirizzi: nuoviIndirizzi,
      });
      let authUser = this.props.auth.user;
      authUser.indirizzi = nuoviIndirizzi;
      this.props.successLogin(authUser);
      notification.destroy();
      notification.success({
        message: "Perfetto",
        description: "L'indirizzo selezionato è stato eliminato correttamente!",
      });
    } catch (error) {
      this.setState({ delete_indirizzo_loading: false });
      console.log(error);
      notification.destroy();
      notification.error({
        message: "Errore!",
        description: "Contatta l'assistenza e descrivi il problema.",
      });
    }
  };

  _closeMenu = () => {
    this.setState({ visible_menu: false });
  };

  _openMenu = () => {
    this.setState({ visible_menu: true });
  };
  _clearTags = () => {
    OneSignal.push(function () {
      OneSignal.deleteTags(["uid", "citta"], function (tagsDeleted) {
        console.log("tags deleted", tagsDeleted);
      });
    });
  };
  _submitForm = async (values, { setSubmitting }) => {
    this.setState({ aggiornaLoading: true });
    try {
      const { nome, cognome, cellulare, citta, marketing, profilazione } =
        values;
      const user = firebase.auth().currentUser;
      const utenteRecord = {
        uid: user.uid,
        nome: nome,
        cognome: cognome,
        telefono: `+${cellulare}`,
        citta: citta,
        marketing: marketing,
        profilazione: profilazione,
      };

      await aggiornaUtente(utenteRecord);
      window.location.reload();
      notification.destroy();
      notification.success({
        message: "Congratulazioni!",
        description: "Dati account aggiornati correttamente",
      });
    } catch (error) {
      console.log(error);
      setSubmitting(false);

      let description =
        "Errore in fase di registrazione, contattare l'assistenza";
      if (error.toString().indexOf("phone") !== -1) {
        description =
          "Esiste già un account associato a questo numero di telefono";
      } else if (error.toString().indexOf("email") !== -1) {
        description =
          "Esiste già un account associato a questo indirizzo email";
      } else if (error.toString().match("TOO_SHORT")) {
        description = "Il numero di telefono è troppo corto";
      } else if (error.toString().match("Missing recipients")) {
        description = "L'indirizzo email inserito è inseistente";
      }

      notification.destroy();
      notification.error({
        message: "Errore",
        description: description,
      });
    } finally {
      this.setState({
        aggiornaLoading: false,
      });
    }
  };

  _loader = async () => {
    this.setState({ loading: true });

    try {
      const { data } = await getUtente(this.props.auth.user.uid);

      this.setState({
        userDb: data,
      });
    } catch (error) {
      notification.destroy();
      notification.error({
        message: "Errore",
        description: "Errore nella richiesta, contattare l'amminsitrazione",
      });
    }

    this.setState({ loading: false });
  };
  _initOnesignal = async () => {
    const self = this;
    var enabled = false;
    OneSignal.push(function () {
      getSubscriptionState().then(function (state) {
        if (state.isPushEnabled) {
          enabled = true;
          self.setState({ notificationEnabled: true });
        } else {
          self.setState({ notificationEnabled: false });
        }
      });
    });
    return enabled;
  };
  componentDidMount() {
    this._loader();
    this._initOnesignal();
  }

  render() {
    const { visible_menu, loading, userDb, notificationEnabled } = this.state;
    const { auth } = this.props;

    let initialValues = {
      nome: userDb ? userDb.nome : "",
      cognome: userDb ? userDb.cognome : "",
      cellulare: firebase.auth().currentUser.phoneNumber ?? "+39",
      citta: userDb ? userDb.citta : "",
    };

    return (
      <>
        <Helmet>
          <title>
            Garzone Store -{" "}
            {`${this.props.auth.user.nome} ${this.props.auth.user.cognome}`}
          </title>
        </Helmet>
        <Header account onMenuClick={this._openMenu} />
        <SideMenu
          slug={this.props.slug}
          visible_menu={visible_menu}
          _closeMenu={this._closeMenu}
        />
        <div style={styles.wrapper}>
          <Row justify="start" align="top" gutter={[15, 15]}>
            <Col span={24}>
              <Title level={3} style={styles.title}>
                Dati Account
              </Title>
            </Col>
          </Row>
          <Row justify="start" align="top" gutter={[15, 15]}>
            <Col md={8} sm={12} xs={24}>
              <Row style={styles.label}>E-Mail</Row>
              {userDb && userDb.provider_registrazione === "google.com" ? (
                <Row>{auth.user ? auth.user.email : null}</Row>
              ) : (
                <>
                  <Row>
                    <Col span={24}>
                      <Input
                        disabled
                        type="email"
                        value={firebase.auth().currentUser.email ?? null}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <a
                      onClick={() => this.setState({ cambiaMailVisible: true })}
                    >
                      Cambia E-mail
                    </a>
                  </Row>
                </>
              )}
            </Col>
            <Col md={8} sm={12} xs={24}>
              <Row style={styles.label}>Password</Row>
              <Row>
                <Col span={24}>
                  <Input disabled type="password" value="*********" />
                </Col>
              </Row>
              {userDb &&
              userDb.provider_registrazione === "google.com" ? null : (
                <Row>
                  <a
                    onClick={() =>
                      this.setState({ recuperaPasswordVisible: true })
                    }
                  >
                    Cambia Password
                  </a>
                </Row>
              )}
            </Col>
            <Col md={8} sm={12} xs={24}>
              <Row style={styles.label}>Metodi di login</Row>
              <Row>
                {auth.user.providers.map((k) =>
                  k.name === "password" ? (
                    <Popover content={k.value}>
                      <MailFilled
                        style={{ fontSize: "34px", marginRight: "5px" }}
                      />
                    </Popover>
                  ) : k.name === "google.com" ? (
                    <Popover content={k.value}>
                      <GoogleCircleFilled
                        style={{ fontSize: "34px", marginRight: "5px" }}
                      />
                    </Popover>
                  ) : k.name === "phone" ? (
                    <Popover content={k.value}>
                      <PhoneFilled
                        style={{ fontSize: "34px", marginRight: "5px" }}
                      />
                    </Popover>
                  ) : null
                )}
              </Row>
            </Col>
            <Divider />
            <Col span={24}>
              <Title level={3} style={styles.title}>
                Dati personali
              </Title>
              {loading ? null : (
                <Formik
                  initialErrors={initialValues}
                  validateOnMount={false}
                  validateOnChange={true}
                  initialValues={initialValues}
                  validationSchema={Yup.object().shape({
                    nome: Yup.string()
                      .matches(/^[A-Za-zÀ-ÿ]+$/i, "Inserisci un nome valido")
                      .required("Inserisci un nome"),
                    cognome: Yup.string()
                      .required("Inserisci un cognome")
                      .matches(
                        /^[A-Za-zÀ-ÿ]+$/i,
                        "Inserisci un cognome valido"
                      ),
                    cellulare: Yup.string()
                      .strict(true)
                      .nullable()
                      .trim(
                        "Il campo non può avere spazi vuoti iniziali o finali"
                      )
                      .matches(
                        /[1-9]\d{4,14}$/i,
                        "Inserisci un numero di cellulare valido"
                      )
                      .required("Inserisci il tuo numero di cellulare"),
                    citta: Yup.string().required(
                      "Devi inserire una città di residenza."
                    ),
                  })}
                  onSubmit={this._submitForm}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                    isValid,
                    isSubmitting,
                    dirty,
                  }) => (
                    <>
                      <Row justify="start" align="top" gutter={[15, 15]}>
                        <Col md={6} sm={12} xs={24}>
                          <Row style={styles.label}>Nome</Row>
                          <Row>
                            <Input
                              placeholder="Inserisci nome"
                              value={values.nome}
                              onChange={handleChange("nome")}
                              onBlur={handleBlur("nome")}
                            />
                          </Row>
                          <Row>
                            <span style={styles.inputError}>
                              {touched.nome && errors.nome}
                            </span>
                          </Row>
                        </Col>
                        <Col md={6} sm={12} xs={24}>
                          <Row style={styles.label}>Cognome</Row>
                          <Row>
                            <Input
                              placeholder="Inserisci cognome"
                              value={values.cognome}
                              onChange={handleChange("cognome")}
                              onBlur={handleBlur("cognome")}
                            />
                          </Row>
                          <Row>
                            <span style={styles.inputError}>
                              {touched.cognome && errors.cognome}
                            </span>
                          </Row>
                        </Col>
                        <Col md={6} sm={12} xs={24}>
                          <Row style={styles.label}>Telefono</Row>
                          <Row>
                            <Col span={24}>
                              <PhoneInput
                                value={values.cellulare}
                                onChange={handleChange("cellulare")}
                                onBlur={handleBlur("cellulare")}
                                placeholder="Inserisci telefono"
                                country="it"
                                countryCodeEditable={false}
                                inputStyle={{
                                  width: "100%",
                                  height: "31.19px",
                                }}
                              />
                              <span style={styles.inputError}>
                                {touched.cellulare && errors.cellulare}
                              </span>
                            </Col>
                          </Row>
                        </Col>

                        <Col span={6}>
                          <span style={styles.inputLabel}>
                            Città di residenza
                          </span>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            onChange={(value) => {
                              setFieldValue("citta", value);
                              let comune = comuni_json.find(
                                (i) => i.nome == value
                              );
                              setFieldValue(
                                "cap",
                                comune && Array.isArray(comune.cap)
                                  ? comune.cap[0]
                                  : comune.cap
                              );
                              this.setState({
                                selected_citta_caps:
                                  comune && Array.isArray(comune.cap)
                                    ? comune.cap
                                    : [comune.cap],
                              });
                              setFieldValue("provincia", comune.provincia.nome);
                            }}
                            size="default"
                            placeholder="Scegli la città"
                            style={{ width: "100%" }}
                            onBlur={handleBlur("citta")}
                            value={values.citta}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {comuni_json.map((c) => (
                              <Option key={c.nome}>{c.nome}</Option>
                            ))}
                          </Select>
                          <span style={styles.inputError}>
                            {touched.citta && errors.citta}
                          </span>
                        </Col>
                      </Row>
                      <Row justify="start" align="middle">
                        <Col>
                          <Button
                            icon={<EditOutlined />}
                            loading={this.state.aggiornaLoading}
                            type="primary"
                            disabled={
                              !(isValid && dirty) || this.state.aggiornaLoading
                            }
                            onClick={handleSubmit}
                          >
                            {this.state.aggiornaLoading
                              ? "Attendi..."
                              : "Aggiorna"}
                          </Button>
                        </Col>
                      </Row>
                      <Divider />

                      {userDb ? (
                        <PermessiUtente
                          profilazione={userDb.profilazione}
                          marketing={userDb.marketing}
                        />
                      ) : null}

                      <Divider />
                      <Row justify="start" align="top" gutter={[30, 15]}>
                        <Col md={8} sm={24} xs={24}>
                          <Row>
                            <Title level={3} style={styles.title}>
                              Indirizzi di consegna
                            </Title>
                          </Row>
                          <List
                            dataSource={this.state.indirizzi}
                            renderItem={({ indirizzo, cap, citta, civico }) => {
                              return (
                                <List.Item
                                  extra={
                                    <Row gutter={15}>
                                      <Col>
                                        <DeleteOutlined
                                          disabled
                                          onClick={() => {
                                            Modal.confirm({
                                              title: `Stai eliminando l'indirizzo "${indirizzo}"`,
                                              icon: (
                                                <ExclamationCircleOutlined />
                                              ),
                                              content: `Sei sicuro di voler eliminare l'indirizzo ${indirizzo}?`,
                                              okText: "Conferma",
                                              cancelText: "Annulla",
                                              onOk: async () => {
                                                this.deleteIndirizzo(
                                                  indirizzo,
                                                  civico,
                                                  citta,
                                                  cap
                                                );
                                              },
                                            });
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  }
                                >
                                  <List.Item.Meta
                                    title={`${indirizzo} ${civico}, ${citta} ${cap}`}
                                  />
                                </List.Item>
                              );
                            }}
                          ></List>

                          <Row>
                            <AggiungiIndirizzo
                              setIndirizzi={this.setIndirizzi}
                            />
                          </Row>
                        </Col>
                        {!isMobile && !isSafari && !isIOS ? (
                          <Col md={8} sm={24} xs={24}>
                            <Title level={3} style={styles.title}>
                              Notifiche
                            </Title>
                            <Row gutter={[40, 40]}>
                              {Notification.permission !== "default" ? (
                                <Col>
                                  <span>Abilita le notifiche push</span>
                                </Col>
                              ) : null}
                              <Col>
                                {Notification.permission === "default" ? (
                                  <Button onClick={this._subscribeOneSignal}>
                                    Consenti l'invio di notifiche da browser
                                  </Button>
                                ) : (
                                  <Switch
                                    defaultChecked={notificationEnabled}
                                    disabled={
                                      Notification.permission === "denied"
                                    }
                                    onChange={(checked) => {
                                      OneSignal.push(function () {
                                        OneSignal.setSubscription(checked);
                                      });
                                      this.setState({
                                        notificationEnabled: checked,
                                      });
                                    }}
                                  />
                                )}
                              </Col>
                              {Notification.permission === "denied" ? (
                                <Col>
                                  <Popover content="Per attivare le notifiche, abilita il permesso relativo nelle impostazioni del tuo browser">
                                    <QuestionCircleFilled />
                                  </Popover>
                                </Col>
                              ) : null}
                            </Row>
                          </Col>
                        ) : null}
                      </Row>
                      <Divider />
                      <Row
                        justify="center"
                        align="top"
                        gutter={[15, 15]}
                        style={{ marginTop: "15px" }}
                      ></Row>
                    </>
                  )}
                </Formik>
              )}
            </Col>
          </Row>

          <Modal
            destroyOnClose
            visible={this.state.cambiaMailVisible}
            footer={null}
            onCancel={() => {
              this.setState({ cambiaMailVisible: false });
            }}
            title={
              <Image
                style={styles.modalLogo}
                width="158px"
                src={require("../Assets/logo-cyan.png")}
              />
            }
          >
            <Row justify="start" gutter={[0, 15]}>
              <Col span={24}>
                <Title level={3} style={styles.title}>
                  Cambia E-mail
                </Title>
              </Col>
            </Row>
            <Row justify="space-between" align="middle" gutter={[15, 15]}>
              <Col span={24}>
                <Formik
                  validationSchema={Yup.object().shape({
                    mail: Yup.string()
                      .email("Inserisci una mail valida")
                      .required("Nuova mail richiesta"),
                    conferma_mail: Yup.string()
                      .oneOf(
                        [Yup.ref("mail"), null],
                        "Le mail non corrispondono"
                      )
                      .required("Conferma mail richiesta"),
                  })}
                  initialValues={{ mail: "", conferma_mail: "" }}
                  validateOnMount
                  validateOnBlur
                  validateOnChange
                  onSubmit={this._cambiaMail}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isValid,
                    isSubmitting,
                    dirty,
                  }) => (
                    <Form>
                      <Row gutter={[15, 15]}>
                        <Col span={24}>
                          <span style={styles.inputLabel}>Nuova e-mail</span>
                          <Input
                            placeholder="Inserisci la nuova mail..."
                            value={values.mail}
                            onChange={handleChange("mail")}
                            onBlur={handleBlur("mail")}
                          />
                          <span style={styles.inputError}>
                            {touched.mail && errors.mail}
                          </span>
                        </Col>
                        <Col span={24}>
                          <span style={styles.inputLabel}>Conferma mail</span>
                          <Input
                            placeholder="Conferma nuova mail"
                            value={values.conferma_mail}
                            onChange={handleChange("conferma_mail")}
                            onBlur={handleBlur("conferma_mail")}
                          />
                          <span style={styles.inputError}>
                            {touched.conferma_mail && errors.conferma_mail}
                          </span>
                        </Col>
                      </Row>

                      <Divider />
                      <Row justify="space-around">
                        <Col span={24}>
                          <Button
                            block
                            size="large"
                            icon={<EditOutlined />}
                            type="primary"
                            onClick={handleSubmit}
                            loading={this.state.loadingMail}
                            disabled={
                              !(isValid && dirty) ||
                              isSubmitting ||
                              this.state.loadingMail
                            }
                          >
                            {isSubmitting ? "Attendi..." : "Aggiorna Mail"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Modal>
          {/*MODAL CAMBIA PASSWORD*/}
          <Modal
            destroyOnClose
            visible={this.state.recuperaPasswordVisible}
            footer={null}
            onCancel={() => {
              this.setState({ recuperaPasswordVisible: false });
            }}
            title={
              <Image
                style={styles.modalLogo}
                width="158px"
                src={require("../Assets/logo-cyan.png")}
              />
            }
          >
            <Row justify="start" gutter={[0, 15]}>
              <Col span={24}>
                <Title level={3} style={styles.title}>
                  Cambia Password
                </Title>
              </Col>
            </Row>
            <Row justify="space-around" gutter={[0, 10]}>
              <Col md={24} sm={24}>
                {this.state.countdownPasswordVisible ? (
                  <CountdownMail
                    seconds={60}
                    onFinish={() =>
                      this.setState({
                        countdownPasswordVisible: false,
                      })
                    }
                  />
                ) : (
                  <Button
                    block
                    type="primary"
                    loading={this.state.loadingPassword}
                    disabled={this.state.loadingPassword}
                    onClick={async () => {
                      try {
                        this.setState({ loadingPassword: true });
                        await requestResetPassword(this.props.auth.user.email);
                        notification.destroy();
                        notification.success({
                          message: "Mail di recupero password inviata",
                          description: "Controlla la tua casella email!",
                        });
                      } catch (error) {
                        notification.destroy();
                        notification.error({
                          message: "Mail di recupero password non inviata",
                          description: "Contatta l'assistenza",
                        });
                      }
                      this.setState({
                        countdownPasswordVisible: true,
                        loadingPassword: false,
                      });
                    }}
                  >
                    {" "}
                    Invia il link
                  </Button>
                )}
              </Col>
            </Row>
          </Modal>
          {/* <Row justify="start" align="top">
            <Col span={24}>
              <Title level={3} style={styles.title}>Indirizzi</Title>
            </Col>
          </Row> */}
        </div>

        <Loading loading={loading} />
      </>
    );
  }
}
const styles = {
  wrapper: {
    padding: "25px 15px",
  },
  menu_item: {
    borderBottom: "1px solid " + Settings.colors.lightGrey,
    padding: "10px 20px",
    color: Settings.colors.darkGrey,
  },
  menu_item_title: {
    fontSize: "18px",
    display: "block",
  },
  menu_item_subtitle: {
    fontSize: "14px",
    color: Settings.colors.red,
    display: "block",
  },
  title: {
    color: Settings.colors.darkGrey,
  },
  label: {
    color: Settings.colors.darkGrey,
    fontWeight: 700,
  },
  inputError: {
    color: Settings.colors.red,
    fontSize: "14px",
  },
};

function mapStateToProps(state) {
  return {
    id: state.comuni.id,
    slug: state.comuni.slug,
    nome: state.comuni.nome,
    logo: state.comuni.logo,
    auth: state.auth,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    successLogin: (user) => dispatch(successLogin(user)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Account);
