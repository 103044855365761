import React, { Component } from "react";

import * as Yup from "yup";

// import ant design components
import {
  Row,
  Col,
  Button,
  Tooltip,
  notification,
  Modal,
  Image,
  Divider,
} from "antd";

// import custom components
import Chip from "./Chip";

// import settings
import Settings from "../Config/Settings";
import { MessageOutlined, SendOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { postFirstMessage, getUtente } from "../Controllers/Utente";
import { Formik } from "formik";
import Form from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import ModaleLoginUtente from "../Components/ModaleLoginUtente";
import { withRouter } from "react-router-dom";

class CardNegozio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postFirstMeggageVisible: false,
      sendMessageLoading: false,
      loginVisible: false,
    };
  }

  _closeLoginModal = (e) => {
    this.setState({ loginVisible: false });
  };
  _changeLoginState = () => {
    this.setState({ logged: true });
  };
  handleChat = () => {
    const { chats } = this.props.chats;

    if (this.props.auth.user != null) {
      const chatExists = chats.find((c) => c.user_sql_id == this.props.sql_id);
      if (chatExists) {
        this.props.history.push(`/chats/${chatExists.chat_uid}`);
      } else {
        this.setState({
          postFirstMeggageVisible: true,
        });
      }
    } else {
      this.setState({ loginVisible: true });
    }
  };

  _sendMessage = async (values, { setSubmitting }) => {
    setSubmitting(true);
    this.setState({ sendMessageLoading: true });
    try {
      const { messaggio } = values;
      //id negozio verso
      //uid mio
      //messaggio

      //-> Chiamata function POST message che restituisce l'uid firebase della chat
      const user = await getUtente(this.props.auth.user.uid);

      const uid_chat = await postFirstMessage({
        id_negozio: this.props.sql_id,
        id_utente: user.data.id,
        uid_from: this.props.auth.user.uid,
        messaggio: messaggio,
      });

      this.setState({
        sendMessageLoading: false,
        postFirstMeggageVisible: false,
      });
      setSubmitting(false);

      this.props.history.push(`/chats/${uid_chat.data}`);
    } catch (error) {
      setSubmitting(false);
      this.setState({ sendMessageLoading: false });
      console.log(error);
      notification.destroy();
      notification.error({
        message: "Errore!",
        description: "Contatta l'assistenza e descrivi il problema.",
      });
    }
  };

  render() {
    const {
      nome,
      categoria,
      indirizzo,
      immagine,
      containerStyles,
      target,
    } = this.props;
    return (
      <>
        <div
          style={{ ...styles.container, containerStyles, cursor: "pointer" }}
          onClick={(e, data) => {
            if (target) {
              window.open(target, "_blank");
            }
          }}
        >
          <Row style={styles.section}>
            <Col span={24}>
              {immagine && immagine !== "" ? (
                <img style={styles.image} src={immagine} alt="image" />
              ) : (
                <img
                  style={styles.image}
                  src={require("../Assets/Background/placeholder.jpg")}
                  alt="image"
                />
              )}
            </Col>
          </Row>
          <Row style={styles.section} justify="space-between">
            <Col>
              <Chip title={categoria} />
            </Col>
            <Col>
              {this.props.chatAbilitata ? (
                <Tooltip
                  title="Chat con il negozio"
                  placement="top"
                  color={Settings.colors.darkGrey}
                >
                  <Button
                    type="secondary"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.handleChat();
                    }}
                  >
                    <MessageOutlined />
                  </Button>
                </Tooltip>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <h2 style={styles.title}>{nome}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <span style={styles.description}>{indirizzo}</span>
            </Col>
          </Row>
        </div>
        <Modal
          destroyOnClose
          footer={null}
          onCancel={() => {
            this.setState({ postFirstMeggageVisible: false });
          }}
          transitionName="zoom"
          title={
            <Image
              style={styles.modalLogo}
              width="158px"
              onClick={null}
              src={require("../Assets/logo-cyan.png")}
            />
          }
          visible={this.state.postFirstMeggageVisible}
        >
          <Row justify="space-between" align="middle" gutter={[15, 15]}>
            <Col span={24}>
              <Formik
                validationSchema={Yup.object().shape({
                  messaggio: Yup.string()
                    .required("Inserisci il tuo messaggio")
                    .max(500, "Messaggio > 500 caratteri"),
                })}
                initialValues={{ messaggio: "" }}
                validateOnMount={false}
                validateOnChange={true}
                onSubmit={this._sendMessage}
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isValid,
                  isSubmitting,
                  dirty,
                }) => (
                  <Form>
                    <Row>
                      <Col span={24}>
                        <TextArea
                          rows={6}
                          placeholder="Inserisci qui il testo del tuo messaggio..."
                          value={values.messaggio}
                          onChange={handleChange("messaggio")}
                          onBlur={handleBlur("messaggio")}
                          style={{ marginTop: "10px" }}
                        />
                        <span style={styles.inputError}>
                          {touched.messaggio && errors.messaggio}
                        </span>
                      </Col>
                    </Row>
                    <Divider />
                    <Row justify="space-around">
                      <Col span={24}>
                        <Button
                          block
                          size="large"
                          icon={<SendOutlined />}
                          type="primary"
                          onClick={handleSubmit}
                          loading={this.state.sendMessageLoading}
                          disabled={!(isValid && dirty) || isSubmitting}
                        >
                          {isSubmitting ? "Attendi..." : "Invia"}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Modal>{" "}
        <ModaleLoginUtente
          visible={this.state.loginVisible}
          buttonDisabled={true}
          callbackClose={this._closeLoginModal}
          callbackLogin={this._changeLoginState}
        />
      </>
    );
  }
}

const styles = {
  container: {
    overflow: "hidden",
  },
  section: {
    marginBottom: "15px",
  },
  image: {
    width: "100%",
    borderRadius: "4px",
  },
  title: {
    fontSize: "22px",
    fontWeight: "bold",
    marginBottom: 0,
    color: Settings.colors.darkGrey,
  },
  description: {
    fontSize: "16px",
    color: Settings.colors.darkGrey,
  },
};

function mapStateToProps(state) {
  return {
    id: state.comuni.id,
    slug: state.comuni.slug,
    logo: state.comuni.logo,
    auth: state.auth,
    chats: state.chat,
  };
}
export default withRouter(connect(mapStateToProps, null)(CardNegozio));
