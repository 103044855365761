import React from "react";

// Third-party Components
import { Button, notification, Input, Row, Col } from "antd";
import { Formik } from "formik";

// Custom components
import CustomResult from "../Components/CustomResult";
import Settings from "../Config/Settings";

// Libraries
import { checkCode, resetPassword, userType } from "../Controllers/Auth";
import { validationSchemaAggiornaPassword } from "../Config/Validation";

class AggiornaPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      status: "",
      code: "",
      tipo: "",
    };
  }

  _handleClick = async (values, { setSubmitting }) => {
    if (values.password == values.conferma_password) {
      let result = await resetPassword(this.state.code, values.password);
      if (result == "success") {
        this.setState({ status: "sent" });
      } else {
        notification.destroy();
        if (result.code == "auth/expired-action-code") {
          this.setState({ status: "expired" });
        } else if (result.code == "auth/invalid-action-code") {
          this.setState({ status: "invalid" });
        } else if (result.code == "auth/user-disabled") {
          this.setState({ status: "banned" });
        } else if (result.code == "auth/user-not-found") {
          this.setState({ status: "not-found" });
        } else if (result.code == "auth/weak-password") {
          notification.warn({
            message: "Attenzione",
            description:
              "La password inserita è troppo debole. Scegline un'altra",
          });
          setSubmitting(false);
        } else {
          notification.error({
            message: "Errore",
            description:
              "Si è verificato un errore durante l'aggiornamento della password. Riprova.",
          });
          console.log("Firebase returned an error:", result);
        }
      }
    } else {
      notification.destroy();
      notification.warn({
        message: "Attenzione",
        description:
          'I campi "Password" e "Conferma password" non coincidono. Ricontrolla.',
      });
      setSubmitting(false);
    }
  };

  _goToPanel = async () => {
    if (this.state.tipo == "utente") {
      if (process.env.REACT_APP_STAGE === "development") {
        return window.open(
          "https://piattaforma-garzone-test.web.app/",
          "_self"
        );
      } else {
        return window.open("https://garzone.store/", "_self");
      }
    } else if (this.state.tipo == "negozio") {
      if (process.env.REACT_APP_STAGE === "development") {
        return window.open(
          "https://negozio-garzone-test.web.app/accedi",
          "_self"
        );
      } else {
        return window.open("https://negozio.garzone.store/accedi", "_self");
      }
    } else if (this.state.tipo == "comune") {
      if (process.env.REACT_APP_STAGE == "development") {
        return window.open(
          "https://comune-garzone-test.web.app/accedi",
          "_self"
        );
      } else {
        return window.open("https://comune.garzone.store/accedi", "_self");
      }
    }
  };

  componentDidMount = async () => {
    let {
      match: { params },
    } = this.props;
    if (!params.mode) {
      this.props.history.push("/");
    } else {
      let dividedParams = params.mode.split("&");
      let code = "";
      dividedParams.forEach((item) => {
        if (item.substring(0, 7) == "oobCode") {
          code = item.replace("oobCode=", "");
        }
      });
      let checkResult = await checkCode(code);
      if (checkResult.code == "auth/expired-action-code") {
        this.setState({ status: "expired" });
      } else if (checkResult.code == "auth/invalid-action-code") {
        this.setState({ status: "invalid" });
      } else if (checkResult.code == "auth/user-disabled") {
        this.setState({ status: "banned" });
      } else if (checkResult.code == "auth/user-not-found") {
        this.setState({ status: "not-found" });
      } else {
        let user = await userType(checkResult);
        let tipo = user.data.tipologia;
        this.setState({ status: "valid", code, tipo });
      }
    }
  };

  render() {
    return (
      <div style={styles.loginBackground}>
        <Row justify="center" align="middle">
          <Col>
            <img
              src={require("../Assets/logo-white.png")}
              style={{ height: 100, marginTop: 35, marginBottom: 39 }}
              alt="logo"
            />
          </Col>
        </Row>
        <Row justify="center" align="middle" gutter={[15, 15]}>
          {this.state.status == "valid" ? (
            <div>
              <h1 style={styles.title}>Imposta una nuova password</h1>
              <div>
                <p
                  style={{
                    fontSize: 15,
                    color: Settings.colors.white,
                    textAlign: "center",
                  }}
                >
                  Crea una nuova password per il tuo account.
                  <br />
                  Ricorda che dovrà essere composta da almeno 8 caratteri.
                </p>
                <Formik
                  initialValues={{
                    password: "",
                    conferma_password: "",
                  }}
                  validationSchema={validationSchemaAggiornaPassword}
                  onSubmit={this._handleClick}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                    touched,
                    isValid,
                    dirty,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <div>
                      <Row justify="center" align="middle">
                        <Col span={24} style={{ textAlign: "left" }}>
                          <Input
                            className="input-text"
                            placeholder="Nuova password"
                            value={values.password}
                            type="password"
                            onChange={handleChange("password")}
                            onBlur={handleBlur("password")}
                          />
                          {errors.password && touched.password ? (
                            <div className="input-error">{errors.password}</div>
                          ) : null}
                        </Col>
                      </Row>
                      <Row justify="center" align="middle">
                        <Col span={24} style={{ textAlign: "left" }}>
                          <Input
                            placeholder="Ripeti nuova password"
                            value={values.conferma_password}
                            type="password"
                            onChange={handleChange("conferma_password")}
                            onBlur={handleBlur("conferma_password")}
                            style={{ marginTop: 15 }}
                          />
                          {errors.conferma_password &&
                          touched.conferma_password ? (
                            <div style={styles.inputError}>
                              {errors.conferma_password}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      <Row justify="center" align="middle">
                        <Col span={24} style={{ textAlign: "left" }}>
                          <Button
                            type="primary"
                            onClick={handleSubmit}
                            disabled={!(isValid && dirty) || isSubmitting}
                            style={{ width: "100%", marginTop: 20 }}
                          >
                            Aggiorna password
                          </Button>
                        </Col>
                      </Row>
                      <Row
                        justify="center"
                        align="middle"
                        style={{ marginTop: 109 }}
                      >
                        <Col span={24} style={{ textAlign: "center" }}>
                          <span style={{ color: Settings.colors.white }}>
                            Hai le credenziali?
                            <br />
                            Accedi subito al tuo account.{" "}
                          </span>
                          <a onClick={() => this.props.history.push("/accedi")}>
                            Clicca qui
                          </a>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          ) : this.state.status == "sent" ? (
            <CustomResult
              icon="success"
              title="Password aggiornata!"
              subtitle={
                <span style={{ color: Settings.colors.lightGrey }}>
                  Hai aggiornato correttamente la
                  <br />
                  password per il tuo account.
                </span>
              }
              button={{ title: "Accedi", route: () => this._goToPanel() }}
            />
          ) : this.state.status == "expired" ? (
            <CustomResult
              icon="error"
              title="Link scaduto"
              subtitle={
                <span style={{ color: Settings.colors.lightGrey }}>
                  Il link che hai aperto è scaduto e non è più utilizzabile.
                  Richiedi un nuovo link per resettare la password.
                </span>
              }
              button={{
                title: "Recupera password",
                route: () => this.props.history.push("/recupera-password"),
              }}
            />
          ) : this.state.status == "invalid" ? (
            <CustomResult
              icon="error"
              title="Link non valido"
              subtitle={
                <span style={{ color: Settings.colors.lightGrey }}>
                  Il link che hai aperto non è valido e non può essere usato. Se
                  non ricordi la password, premi qui sotto per recuperarla.
                </span>
              }
              button={{
                title: "Recupera password",
                route: () => this.props.history.push("/recupera-password"),
              }}
            />
          ) : this.state.status == "banned" ? (
            <CustomResult
              icon="info"
              title="Accesso negato"
              subtitle={
                <span>
                  Il tuo utente è stato disattivato, pertanto non puoi accedere
                  a Garzone.
                </span>
              }
            />
          ) : this.state.status == "not-found" ? (
            <CustomResult
              icon="error"
              title="Non trovato"
              subtitle={
                <span style={{ color: Settings.colors.lightGrey }}>
                  L'utente non è stato trovato
                </span>
              }
              button={{
                title: "Torna alla home",
                route: () => this.props.history.push("/"),
              }}
            />
          ) : null}
        </Row>
      </div>
    );
  }
}

const styles = {
  loginBackground: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: `url(${require("../Assets/background.png")})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "15px",
  },
  logoStyle: {
    height: 100,
    marginTop: 35,
    marginBottom: 35,
  },
  title: {
    color: Settings.colors.white,
    textAlign: "center",
  },
  forgotPassword: {
    textAlign: "center",
    color: Settings.colors.white,
  },
  alert: {
    marginBottom: "15px",
    alignSelf: "center",
  },
  inputError: {
    marginTop: "5px",
    fontSize: "12px",
    color: Settings.colors.red,
  },
};

export default AggiornaPassword;
