import React from 'react'

// ant design components
import { Divider, Input, Row } from 'antd'

// ant design icons
import { SearchOutlined } from '@ant-design/icons';

// Import settings
import Settings from '../Config/Settings'

class SearchInput extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      value: '',
      focused: false,
      filtered_results: [],
    }
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue })
    }
    console.log(this.props)
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      if (this.props.defaultValue && this.props.defaultValue !== '') {
        this.setState({ value: this.props.defaultValue })
        this._filter(this.props.defaultValue.toLowerCase())
      }
    }
  }

  _filter = (string) => {
    if (this.props.items.length > 0) {
      const search_string = string.toLowerCase()
      if (search_string == '') {
        this.setState({ filtered_results: [] })
        return
      }
      const fieldsToFilter = this.props.fieldsToFilter

      let results = this.props.items.filter(item => {
        return fieldsToFilter.some(field => JSON.stringify(item[field]).toLowerCase().includes(search_string))
      })

      if (this.props.numberOfResults) {
        results = results.slice(0, this.props.numberOfResults)
      }
      this.setState({ filtered_results: results })
    }
  }

  render() {
    return (
      <>
        <Input
          disabled={this.props.disabled}
          style={{ ...styles.input, zIndex: this.state.focused === true ? 100000 : 1 }}
          onFocus={() => this.setState({ focused: true })}
          //onBlur={() => this.setState({focused: false})}
          onPressEnter={() => window.open(this.props.resultsUrl + this.state.value, '_self')}
          onKeyUp={(e) => this._filter(e.target.value)}
          onChange={(e) => this.setState({ value: e.target.value })}
          value={this.state.value}
          size={'large'}
          placeholder={this.props.placeholder}
          prefix={<SearchOutlined style={{ fontSize: '24px', padding: '16px' }} />}
        />
        {
          this.state.value === "" && this.state.focused === true
            ?
            <div style={{ ...styles.results_box, zIndex: this.state.focused === true ? 100000 : 1 }}>
              {
                this.props.defaultItems && this.props.defaultItems.length > 0
                  ?
                  <>
                    <Row style={{ marginBottom: 5 }}>
                      <span style={{ ...this.props.defaultItemsNameStyle }}>{this.props.defaultItemsName}</span>
                    </Row>
                    <Divider />
                    {
                      this.props.defaultItems.map((item, index) => (
                        <Row key={index}>
                          {this.props.renderDefaultItem({ item })}
                        </Row>
                      ))
                    }
                  </>
                  : null
              }
            </div>
            :
            this.state.value.length > 1 && this.state.focused === true
            &&
            <div style={{ ...styles.results_box, zIndex: this.state.focused === true ? 100000 : 1 }}>
              {
                this.state.filtered_results.length > 0
                  ?
                  <>
                    {
                      this.state.filtered_results.map((item, index) => (
                        <div key={index}>
                          {this.props.renderItem({ item })}
                        </div>
                      ))
                    }
                    <div>
                      <a
                        href={this.props.resultsUrl + this.state.value}
                        style={{ fontSize: '18px' }}>
                        Vedi tutti i risultati per <b>"{this.state.value}"</b>
                      </a>
                    </div>
                  </>
                  :
                  <div>
                    <p>Nessun risultato per la ricerca effettuata.</p>
                  </div>
              }
            </div>
        }
        {
          this.state.focused === true
          &&
          <div onClick={() => this.setState({ focused: false })} style={{ ...styles.mask, zIndex: this.state.focused === true ? 10001 : 1 }}></div>
        }

      </>
    )
  }

}

export default SearchInput

const styles = {
  mask: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: 'rgba(255,255,255,0.9)',
  },
  input: {
    boxShadow: '0 5px 11px rgba(0,0,0,0.12)',
    width: '100%',
    height: '60px',
    color: Settings.colors.darkGrey
  },
  results_box: {
    position: 'absolute',
    width: '100%',
    boxShadow: '0 5px 11px rgba(0,0,0,0.12)',
    border: '1px solid #eee',
    borderRadius: '4px',
    background: 'white',
    padding: '20px'
  }
}