import { Button, Col, notification, Radio, Row, Select, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal/Modal";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import Settings from "../Config/Settings";
import ListaProdottiCarrello from "./ListaProdottiCarrello";
import { validationSchemaSubmitOrdine } from "../Config/Validation";
import { createOrder } from "../Controllers/Utente";
import AggiungiIndirizzo from "./AggiungiIndirizzo";

const { Option } = Select;
class ModaleCarrello extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      modal_visible: false,
      carrello: [],
      totale: 0.0,
      indirizziVisible: false,
      aggiungi_loading: false,
      act_indirizzi: this.props.auth.user ? this.props.auth.user.indirizzi : [],
      indirizzi: [],
      errorIndirizzo: false,
      submittingOrdine: false,
      indirizzoInserito: false,
    };
  }
  //INDIRIZZI----------------------------------------------------------
  setIndirizzi = (indirizzi) => {
    this.setState({ indirizzi: indirizzi, indirizzoInserito: true });
  };

  //--------------------------------------------------------------------
  _submitOrder = async (values, { setSubmitting }) => {
    setSubmitting(true);
    this.setState({ submittingOrdine: true });
    console.log(values);
    try {
      //sendOrder
      if (this.state.carrello.length === 0) {
        this.setState({ modalVisible: false });
        this.props.callbackClose();
      } else if (this.state.indirizziVisible && values.indirizzo === "") {
        this.setState({ errorIndirizzo: true });
      } else {
        try {
          const uniqueSet = new Set(this.state.carrello);

          let carrelloFinale = [...uniqueSet];
          let dbrecordProdotti = [];
          for (let i = 0; i < carrelloFinale.length; i++) {
            const quantita = this.countOccurrences(
              this.state.carrello,
              carrelloFinale[i]
            );
            const totProdotto =
              this.getProductPrice(carrelloFinale[i]) * quantita;
            let prodottoRecord = {
              id: carrelloFinale[i],
              quantita: quantita,
              totale: totProdotto,
              tipo: "prodotto",
            };
            dbrecordProdotti.push(prodottoRecord);
          }
          const userData = {
            uid: this.props.auth.user.uid,
            nome: this.props.auth.user.nome,
            cognome: this.props.auth.user.cognome,
          };

          const dbrecord = {
            idNegozio: this.props.idNegozio,
            idCliente: this.props.auth.user.uid,
            infoCliente: JSON.stringify(userData),
            prodotti: JSON.stringify(dbrecordProdotti),
            note: values.note,
            creazione: Date.now(),
            ultimaModifica: Date.now(),
            indirizzo: values.indirizzo,
            tipoConsegna: values.consegna.startsWith("Consegna")
              ? "consegna"
              : "ritiro",
            tipoPagamento: values.tipo_pagamento,
            //tipoPagamento: "contante",
          };
          await createOrder(dbrecord);
          notification.destroy();
          notification.success({
            message: "Perfetto",
            description:
              "Il tuo ordine è stato creato correttamente, tienilo d'occhio dalla sezione 'Ordini' del tuo profilo",
          });
          this.setState({ submittingOrdine: false });
          this.setState({ modalVisible: false });
          this.props.callbackClose();
          this.props.svuotaCarrello();
        } catch (error) {
          console.log("error", error);
        }
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: "Errore",
        description: "Errore nell'invio dell'ordine",
      });
    }
  };
  countOccurrences = (arr, val) =>
    arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

  componentDidMount() {
    console.log(this.props);
    this.setState({
      modalVisible: this.props.visible,
      carrello: this.props.carrello,
      indirizzi: this.props.auth.user ? this.props.auth.user.indirizzi : [],
    });
    this.calcolaTotale();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        modalVisible: this.props.visible,
        carrello: this.props.carrello,
      });
      this.calcolaTotale();
    }
  }

  calcolaTotale = () => {
    let totale = 0.0;
    for (let i = 0; i < this.state.carrello.length; i++) {
      totale += this.getProductPrice(this.state.carrello[i]);
    }
    this.setState({ totale: totale });
  };

  findProduct = (id) => {
    for (let i = 0; i < this.props.prodotti.length; i++) {
      if (this.props.prodotti[i].id === id) {
        return this.props.prodotti[i];
      }
    }
  };

  getProductName = (productId) => {
    if (productId) {
      const prod = this.findProduct(productId);
      return prod.nome;
    }
  };

  getProductPrice = (productId) => {
    if (productId) {
      const prod = this.findProduct(productId);
      return prod.prezzo_scontato !== null ? prod.prezzo_scontato : prod.prezzo;
    }
  };

  addToCart = (id) => {
    let carrello = this.state.carrello;
    carrello.push(id);
    this.setState({ carrello: carrello });
    this.calcolaTotale();
  };

  removeElement = (array, elem) => {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  };

  removeFromCart = (id) => {
    let nuovoCarrello = this.state.carrello;
    this.removeElement(nuovoCarrello, id);
    this.setState({ carrello: nuovoCarrello });
    this.calcolaTotale();
    if (this.state.carrello.length === 0) {
      this.setState({ modalVisible: false });
      this.props.callbackClose();
    }
  };

  render() {
    const { nomeNegozio, carrello } = this.props;

    return (
      <>
        <Modal
          footer={null}
          disableEnforceFocus={true}
          onCancel={() => {
            this.setState({ modalVisible: false });
            this.props.callbackClose();
          }}
          transitionName="zoom"
          title={
            <h2
              style={{
                color: Settings.colors.darkGrey,
                fontWeight: "bold",
                fontSize: 22,
              }}
            >
              Il tuo ordine
            </h2>
          }
          visible={this.state.modalVisible}
        >
          <Row gutter={[15, 15]}>
            <Col span={24}>
              <span style={styles.text}>{nomeNegozio}</span>
            </Col>
            <Col span={24}>
              <span style={styles.smallText}>
                {carrello.length.toString() + " "} PRODOTT
                {carrello.length > 1 ? "I" : "O"}
              </span>
            </Col>
            <Col span={24}>
              <ListaProdottiCarrello
                carrello={this.state.carrello}
                prodotti={this.props.prodotti}
                addToCartCallBack={this.addToCart}
                removeFromCartCallBack={this.removeFromCart}
              />
            </Col>
            <Col span={24} style={{ paddingTop: "0px" }}>
              <Formik
                initialValues={{
                  note: "",
                  consegna: null,
                  tipo_pagamento: null,
                  indirizzo: null,
                }}
                validateOnMount={true}
                validateOnChange={true}
                onSubmit={this._submitOrder}
                validationSchema={validationSchemaSubmitOrdine}
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isValid,
                  isSubmitting,
                  dirty,
                }) => (
                  <Form>
                    <Row gutter={[0, 15]}>
                      <Col span={24}>
                        <span style={styles.smallText}>
                          NOTE PER IL NEGOZIO
                        </span>
                      </Col>
                      <Col span={24}>
                        <TextArea
                          value={values.note}
                          onChange={handleChange("note")}
                          autoSize={true}
                          style={{ width: "100%", resize: "none" }}
                          placeholder="Se vuoi, puoi scrivere delle note per il negozio..."
                          autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                      </Col>
                      <Col span={24}>
                        <span style={styles.smallText}>MODALITA' ORDINE</span>
                      </Col>
                      <Col span={24}>
                        <Radio.Group
                          value={values.consegna}
                          onChange={(e) => {
                            this.setState({
                              indirizziVisible:
                                e.target.value.startsWith("Consegna"),
                            });
                            console.log(errors.indirizzo);
                            setFieldValue("consegna", e.target.value);
                          }}
                        >
                          <Space direction="vertical">
                            {this.props.ritiro && (
                              <Radio
                                style={styles.radioStyle}
                                value="Ritira in negozio"
                              >
                                Ritira in negozio
                              </Radio>
                            )}
                            {this.props.consegna && (
                              <Radio
                                style={styles.radioStyle}
                                value="Consegna a domicilio"
                              >
                                Consegna a domicilio
                              </Radio>
                            )}
                          </Space>
                        </Radio.Group>
                        <span style={styles.inputError}>
                          {touched.consegna && errors.consegna}
                        </span>
                      </Col>
                      {this.state.indirizziVisible && (
                        <>
                          <Col span={24}>
                            <span style={styles.smallText}>
                              INDIRIZZO DI CONSEGNA
                            </span>
                          </Col>
                          <Col span={24}>
                            <Select
                              value={values.indirizzo}
                              placeholder="Scegli indirizzo"
                              handleBlur={handleBlur("indirizzo")}
                              defaultValue={
                                this.state.indirizzoInserito
                                  ? this.state.indirizzi[
                                      this.state.indirizzi.length - 1
                                    ]
                                  : null
                              }
                              onSelect={(e) => {
                                if (e !== null) {
                                  setFieldValue("indirizzo", e);
                                }
                              }}
                              style={{ width: "100%" }}
                              dropdownRender={(menu) => (
                                <div>
                                  {menu}
                                  <Row>
                                    <AggiungiIndirizzo
                                      setIndirizzi={this.setIndirizzi}
                                    />
                                  </Row>
                                </div>
                              )}
                            >
                              {this.state.indirizzi.map((item, index) => (
                                <Option
                                  key={index}
                                  value={`${item.indirizzo} ${item.civico} ${item.citta} ${item.cap}`}
                                >
                                  {`${item.indirizzo} ${item.civico} ${item.citta} ${item.cap}`}
                                </Option>
                              ))}
                            </Select>
                          </Col>
                        </>
                      )}
                      <span style={styles.inputError}>
                        {touched.indirizzo && errors.indirizzo}
                      </span>
                      {this.props.carta || this.props.contante ? (
                        <>
                          {" "}
                          <Col span={24}>
                            <span
                              style={{
                                color: Settings.colors.darkGrey,
                              }}
                            >
                              Modalità Pagamento
                            </span>
                          </Col>
                          <Col span={24}>
                            <Radio.Group
                              value={values.tipo_pagamento}
                              onChange={(e) => {
                                setFieldValue("tipo_pagamento", e.target.value);
                              }}
                              style={styles.smallText}
                            >
                              <Space direction="vertical">
                                {this.props.carta &&
                                this.state.totale > 20.0 ? (
                                  <Radio
                                    style={styles.radioStyle}
                                    value="carta"
                                  >
                                    Pagamento online
                                  </Radio>
                                ) : null}
                                {this.props.contante ? (
                                  <Radio
                                    style={styles.radioStyle}
                                    value={values.consegna ? "contante" : ""}
                                  >
                                    {values.consegna
                                      ? values.consegna.startsWith("Consegna")
                                        ? "Alla consegna"
                                        : "Al ritiro"
                                      : "Alla consegna/Al ritiro"}
                                  </Radio>
                                ) : null}
                              </Space>
                            </Radio.Group>
                            <span style={styles.inputError}>
                              {touched.tipo_pagamento && errors.tipo_pagamento}
                            </span>
                          </Col>
                        </>
                      ) : null}

                      <Col span={24} style={{ marginTop: "20px" }}>
                        <Row justify="space-between">
                          <Col>
                            <span style={styles.text}>Prodotti</span>
                          </Col>
                          <Col>
                            <span style={styles.text}>
                              {"€ " + this.state.totale.toFixed(2)}
                            </span>
                          </Col>
                        </Row>
                      </Col>

                      {this.props.consegna && this.state.indirizziVisible ? (
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col>
                              <span style={styles.text}>Costo consegna</span>
                            </Col>
                            <Col>
                              <span style={styles.text}>Da definire*</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: Settings.colors.darkGrey,
                                }}
                              >
                                <strong>
                                  <i>
                                    *Dettagli consegna:{" "}
                                    {this.props.dettagliConsegna}
                                  </i>
                                </strong>
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col>
                            <span style={styles.text}>TOTALE</span>
                          </Col>

                          <Col>
                            <span
                              style={{
                                color: Settings.colors.darkGrey,
                                fontWeight: "bold",
                                fontSize: 22,
                              }}
                            >
                              {"€ " + this.state.totale.toFixed(2)}
                            </span>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={24}>
                        <Button
                          onClick={handleSubmit}
                          disabled={!isValid || isSubmitting}
                          style={{ width: "100%" }}
                          type="primary"
                          size="large"
                          block
                        >
                          {this.state.submittingOrdine
                            ? "Attendi..."
                            : "Conferma ordine"}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

const styles = {
  text: {
    fontSize: "17px",
    color: Settings.colors.darkGrey,
  },
  smallText: {
    fontSize: "14px",
    color: Settings.colors.grey,
  },
  inputError: {
    color: Settings.colors.red,
    fontSize: "14px",
  },
};
function mapStateToProps(state) {
  return {
    id: state.comuni.id,
    slug: state.comuni.slug,
    logo: state.comuni.logo,
    auth: state.auth,
    chats: state.chat,
    ordini: state.ordini,
  };
}
export default connect(mapStateToProps, null)(ModaleCarrello);
