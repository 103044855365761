import React, { Component } from "react";

// import ant design components
import { Row, Col, Button } from "antd";

import { Link } from "react-router-dom";

// Import settings
import Settings from "../Config/Settings";

class Footer extends Component {
  _goToRegisterNegozio = () => {
    if (process.env.REACT_APP_STAGE === "development") {
      return window.open("https://negozio-garzone-test.web.app/scegli-comune");
    } else {
      return window.open("https://negozio.garzone.store/scegli-comune");
    }
  };
  _goToAccediNegozio = () => {
    if (process.env.REACT_APP_STAGE === "development") {
      return window.open("https://negozio-garzone-test.web.app/accedi");
    } else {
      return window.open("https://negozio.garzone.store/accedi");
    }
  };
  render() {
    return (
      <div style={{ margin: 0 }}>
        <div style={styles.footer}>
          <Row gutter={[40, 25]}>
            <Col span={24}>
              <Link to="/">
                <img
                  style={styles.logoGarzone}
                  src={require("../Assets/logo-inline.png")}
                />
              </Link>
            </Col>
          </Row>
          <Row gutter={[40, 25]} justify="center">
            <Col md={6} xs={24} sm={12}>
              <h3 style={styles.columnTitle}>CONTATTI</h3>

              <span style={styles.columnText}>dglen srl</span>
              <span style={styles.columnText}>Via Turati 26, 20121 Milano</span>
              <span style={styles.columnText}>P.IVA 09686140964</span>
              <span style={styles.columnText}>REA MI-2106812</span>
              <span style={styles.columnText}>
                Capitale sociale 10.000,00 €
              </span>
            </Col>
            <Col md={6} xs={24} sm={12}>
              <h3 style={styles.columnTitle}>PER I NEGOZI</h3>

              <a style={styles.columnText} onClick={this._goToRegisterNegozio}>
                Registra il tuo negozio
              </a>
              <a style={styles.columnText} onClick={this._goToAccediNegozio}>
                Accedi al pannello di controllo
              </a>
            </Col>
            <Col md={6} xs={24} sm={12}>
              <h3 style={styles.columnTitle}>PER I COMUNI</h3>

              <a
                onClick={() => {
                  window.location = "/per-i-comuni";
                }}
                style={styles.columnText}
              >
                Richiedi informazioni
              </a>
            </Col>
            <Col md={6} xs={24} sm={12}>
              <h3 style={styles.columnTitle}>ASSISTENZA</h3>
              <Button
                type="primary"
                size="medium"
                onClick={() => (window.location = "mailto:info@garzone.store")}
              >
                Contattaci
              </Button>
            </Col>
          </Row>
        </div>
        <div
          style={{
            width: "100%",
            backgroundColor: Settings.colors.darkerGrey,
            padding: "20px",
            height: "60",
          }}
        >
          <p style={styles.columnText}>
            © Copyright 2020 - dglen srl - Tutti i diritti riservati -{" "}
            <Link
              target="_blank"
              to={require("../Assets/PDF/Privacy policy.pdf")}
            >
              Privacy policy
            </Link>{" "}
            -{" "}
            <Link
              target="_blank"
              to={require("../Assets/PDF/CONDIZIONI GENERALI DI ADESIONE AL MARKETPLACE.pdf")}
            >
              Condizioni generali di vendita
            </Link>{" "}
            -{" "}
            <Link
              target="_blank"
              to={require("../Assets/PDF/Cookie policy.pdf")}
            >
              Cookie Policy
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;

const styles = {
  footer: {
    marginTop: "300px",
    background: Settings.colors.darkGrey,
    padding: "20px",
    color: Settings.colors.white,
  },
  logoGarzone: {
    height: "35px",
  },
  columnTitle: {
    color: Settings.colors.white,
    fontSize: "17px",
    fontWeight: "bold",
  },
  columnText: {
    color: Settings.colors.white,
    fontSize: "17px",
    display: "block",
  },
};
