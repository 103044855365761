import React, { Component } from "react";
import { Statistic } from "antd";

import Settings from "../Config/Settings";

const { Countdown } = Statistic;

class CountdownMail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { onFinish, seconds, textColor } = this.props;
    return (
      <Countdown
        style={{ textAlign: "center" }}
        prefix={
          <span
            style={{
              color: textColor ? textColor : Settings.colors.darkGrey,
              fontWeight: "normal",
            }}
          >
            Richiedi una nuova mail tra
          </span>
        }
        value={Date.now() + 1000 * seconds}
        valueStyle={{
          fontSize: 17,
          fontWeight: "bold",
          color: textColor ? textColor : Settings.colors.darkGrey,
        }}
        format="s"
        onFinish={onFinish}
        suffix={
          <span
            style={{
              color: textColor ? textColor : Settings.colors.darkGrey,
              fontWeight: "normal",
            }}
          >
            secondi
          </span>
        }
      />
    );
  }
}

export default CountdownMail;
