import React from "react";

class TextCollapse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: true,
    };
  }

  render() {
    return (
      <span style={this.props.style}>
        {this.props.children.length > this.props.cut ? (
          <>
            {this.state.collapse === true
              ? this.props.children.substring(0, this.props.cut) + "..."
              : this.props.children}
            <a
              onClick={() => this.setState({ collapse: !this.state.collapse })}
              style={{ marginLeft: "5px" }}
            >
              {this.state.collapse === true ? "Mostra di più" : "Chiudi"}
            </a>
          </>
        ) : (
          <>{this.props.children}</>
        )}
      </span>
    );
  }
}

export default TextCollapse;

const styles = {};
