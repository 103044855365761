import React, { Component } from "react";
//Ant components
import {
  Row,
  Image,
  Col,
  Button,
  Divider,
  Input,
  Modal,
  Checkbox,
  notification,
  Result,
  Select,
  Typography,
} from "antd";
//ant icons
import {
  MailFilled,
  FacebookFilled,
  GoogleCircleFilled,
  CheckOutlined,
} from "@ant-design/icons";

//config import
import Settings from "../Config/Settings";

//Librerie varie
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "firebase";

//Validation schemas
import {
  validationSchemaRegistraUtente,
  validationSchemaLoginUtente,
  validationSchemaRecuperaPassword,
} from "../Config/Validation";
//Formik
import { Formik, Form } from "formik";

//controller functions
import {
  registraUtente,
  registraUtenteFromGoogle,
} from "../Controllers/Utente";
import { getClaims, requestResetPassword } from "../Controllers/Auth";
import { getUtente, demo } from "../Controllers/Utente";
import { successLogin, successLogout } from "../Actions/AuthActions";
import { login, resendEmailVerification, logout } from "../Controllers/Auth";
//custom components
import CountdownMail from "./CountdownMail";
//utility functions
import { _isLocalhost } from "../Utility/utility";

const comuni_json = require("../Assets/comuni.json");
const { Option } = Select;
const { Paragraph } = Typography;
const OneSignal = window.OneSignal || [];

class ModaleLoginUtente extends Component {
  constructor(props) {
    super(props);
    //stato modale
    this.state = {
      buttonDisabled: false,
      mailLoginVisible: false,
      registerVisible: false,
      mainVisible: false,
      registrazioneProviderVisible: true,
      recuperaPasswordVisible: false,
      registrazioneCompletataVisible: false,
      submitButtonText: "Registrati",
      registerLoading: false,
      loginLoading: false,
      resendMailVisible: false,
      sendMailLoading: false,
      notVerified: false,
    };
  }
  //local functions
  //funzione invio mail reset password------------------------------------------------
  _resetPassword = async (values) => {
    try {
      //abilitazione loading
      this.setState({ loadingPassword: true });
      //invio email di recupero password
      await requestResetPassword(values.email.trim());
      notification.destroy();
      notification.success({
        message: "Mail di recupero password inviata",
        description: "Controlla la tua casella email!",
      });
    } catch (error) {
      //gestione errori
      notification.destroy();
      notification.error({
        message: "Mail di recupero password non inviata",
        description: "Contatta l'assistenza",
      });
    }
    //disabilitazione loading
    this.setState({
      countdownPasswordVisible: true,
      loadingPassword: false,
    });
  };
  //----------------------------------------------------------------------------------
  //login con google------------------------------------------------------------------
  _loginWithFirebase = async () => {
    try {
      //provider di login firebase
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        login_hint: "email@esempio.com",
      });
      provider.addScope(
        "https://www.googleapis.com/auth/user.phonenumbers.read	"
      );
      provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
      try {
        //finestra popup di login
        const result = await firebase.auth().signInWithPopup(provider);
        //inizializzazione dati reducer e callback login
        //this.props.successLogin(result.user);
        this.props.callbackLogin();
        //se nuovo utente
        if (result.additionalUserInfo.isNewUser) {
          //record utente
          const data = {
            id_utenza_firebase: result.user.uid,
            nome: result.additionalUserInfo.profile.given_name,
            cognome: result.additionalUserInfo.profile.family_name,
            telefono: result.user.phoneNumber,
            email: result.user.email,
          };
          //registrazione
          await registraUtenteFromGoogle(data);
        }
        window.location.reload();
      } catch (error) {
        console.log("Login error:", error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //----------------------------------------------------------------------------------
  //funzione che restituisce claims utente--------------------------------------------
  _getClaims = async () => {
    try {
      //ottenimento claims
      let { claims } = await getClaims();
      // await demo();
      //se utente e email verificata
      if (claims.utente && claims.email_verified) {
        let { data } = await getUtente(claims.user_id);

        //record utente loggato
        const logged_user = {
          is_logged_in: true,
          email: claims.email,
          email_verified: claims.email_verified,
          uid: claims.user_id,
          citta: data.citta,
        };
        //set dati reducer utente
        //this.props.successLogin(logged_user);
        if (data.abilitato) {
          //utente apposto
          this.setState({
            error_message: "",
            successful_login: true,
            wrong_permissions: false,
          });
        } else {
          //utente non abilitato
          this.setState({
            error_message: "",
            not_abilitato: true,
          });
        }
      } else {
        if (!claims.email_verified) {
          //TODO: inviare email di conferma
          //email non verificata
          this.setState({
            error_message: "",
            notVerified: true,
          });
        } else {
          //errore
          this.setState({
            error_message: "",
            wrong_permissions: true,
          });
          notification.error({
            title: "Errore",
            message: "L'account utilizzato non è un utente",
          });
        }
      }
    } catch (error) {
      console.log("errore", error);
    }
  };
  //----------------------------------------------------------------------------------
  //funzione assegna tag ui su onesignal----------------------------------------------
  setOnesignalUidTag = () => {
    try {
      //dati utente corrente
      const user = firebase.auth().currentUser;
      const self = this;
      //chiamata funzione per spedire tag
      OneSignal.push(function () {
        OneSignal.sendTags(
          {
            uid: user.uid,
            citta: self.props.auth.user.citta,
          },
          function (tagsSent) {
            console.log("Tag spediti", tagsSent);
          }
        );
      });
    } catch (error) {
      //gestione errori
      console.log("Tags non spediti");
    }
  };
  //----------------------------------------------------------------------------------

  //funzione che cancella i tag assegnati su onesignal--------------------------------
  _clearTags = () => {
    //chiamata metodo onesignal per eliminare tags
    OneSignal.push(function () {
      OneSignal.deleteTags(["uid", "citta"], function (tagsDeleted) {
        console.log("tags deleted", tagsDeleted);
      });
    });
  };
  //----------------------------------------------------------------------------------
  //funzione di login-----------------------------------------------------------------
  _doLogin = async (values, { setSubmitting }) => {
    //caricamento loading
    this.setState({ loginLoading: true });
    setSubmitting(true);
    try {
      //effettua login
      await login(values.email.trim(), values.password);
      await this._getClaims();
      if (this.state.wrong_permissions) {
        //Se l'utente non esiste
        setTimeout(async () => {
          notification.destroy();
          notification.error({
            message: "Errore",
            description: "L'account inserito non è un utente",
          });
        }, 1000);
        //pulire i possibili tag mandati su onesignal
        //effettua il logout
        await logout();
        successLogout();
      } else if (this.state.notVerified) {
        //se l'utente non è verificato
        notification.destroy();
        notification.error({
          message: "Errore",
          description:
            "L'account non è stato verificato, controlla la tua casella di posta elettronica per la mail di verifica.",
        });
        await logout();
        successLogout();
      } else if (this.state.successful_login) {
        //se il login ha avuto successo
        //impostare lo stato globale come loggato
        this.props.callbackLogin();
        this._closeMailLoginModal();
        //set dei tag di onesignal
        //this.setOnesignalUidTag();
      }
    } catch (error) {
      //gestione errori
      notification.destroy();
      notification.error({
        message: "Errore",
        description: "Credenziali errate, riprova",
      });
      this.setState({ error_message: "Credenziali errate! Riprova." });
    } finally {
      //disattivare loading
      setSubmitting(false);
      this.setState({ loading: false, loginLoading: false });
    }
  };
  //-----------------------------------------------------------------------------------
  //funzione di chiusura modale--------------------------------------------------------
  _closeMailLoginModal = (e) => {
    this.setState({ mailLoginVisible: false });
    this.props.callbackClose();
  };
  //----------------------------------------------------------------------------------
  //invio dati registrazione utente---------------------------------------------------
  _submitForm = async (values, { setSubmitting }) => {
    //imposta loading
    this.setState({
      registerLoading: true,
      submitButtonText: "Attendi...",
    });
    //dati passati dal form
    const {
      nome,
      cognome,
      cellulare,
      email,
      password,
      privacy,
      citta,
      marketing,
      profilazione,
    } = values;
    //creazione oggetto record da inviare
    const utenteRecord = {
      email: email,
      password: password,
      nome: nome,
      cognome: cognome,
      telefono: `+${cellulare}`,
      privacy_condizioni: privacy,
      provider: "Email",
      registerLoading: false,
      citta: citta,
      marketing: marketing,
      profilazione: profilazione,
    };

    try {
      //registrazione dell'utente
      await registraUtente(utenteRecord);
      //login dell'utente appena registrato
      await login(email, password);
      //invio email di verifica
      await resendEmailVerification();
      //eliminazione eventuali tag
      //effettua logout
      await logout();
      this.setState({
        registerLoading: false,
        registerVisible: false,
        registrazioneCompletataVisible: true,
      });
    } catch (error) {
      setSubmitting(false);
      //gestione degli errori
      let description =
        "Errore in fase di registrazione, contattare l'assistenza";
      if (error.toString().indexOf("phone") !== -1) {
        description =
          "Esiste già un account associato a questo numero di telefono";
      } else if (error.toString().indexOf("email") !== -1) {
        description =
          "Esiste già un account associato a questo indirizzo email";
      } else if (error.toString().match("TOO_SHORT")) {
        description = "Il numero di telefono è troppo corto";
      } else if (error.toString().match("Missing recipients")) {
        description = "L'indirizzo email inserito è inseistente";
      }

      notification.destroy();
      notification.error({
        message: "Errore",
        description: description,
      });

      this.setState({
        registerLoading: false,
      });
    }
  };
  //----------------------------------------------------------------------------------
  //set stato visible al modal di login-----------------------------------------------
  componentDidMount() {
    this.setState({
      mailLoginVisible: this.props.visible,
      buttonDisabled: this.props.buttonDisabled,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        mailLoginVisible: this.props.visible,
        buttonDisabled: this.props.buttonDisabled,
      });
    }
  }
  //----------------------------------------------------------------------------------
  render() {
    const { resendMailVisible } = this.state;

    const initialValues = {
      nome: "",
      cognome: "",
      cellulare: "+39",
      email: "",
      password: "",
      confermaPassword: "",
      privacy: false,
      marketing: false,
      profilazione: false,
    };
    const initialLogin = { email: "", password: "" };

    return (
      <>
        {/* MODALE METODO DI ACCESSO */}
        <Modal
          maskClosable={false}
          destroyOnClose
          footer={null}
          onCancel={() => {
            this.setState({ mainVisible: false });
            this.props.callbackClose();
          }}
          transitionName="zoom"
          title={
            <Image
              style={styles.modalLogo}
              width="158px"
              src={require("../Assets/logo-cyan.png")}
            ></Image>
          }
          visible={this.state.mainVisible}
        >
          {this.state.registrazioneProviderVisible && (
            <>
              <Row gutter={[0, 40]}>
                <Button
                  disabled={this.state.buttonDisabled}
                  type="secondary"
                  style={{ width: "100%", marginTop: "5%" }}
                  onClick={this._loginWithFirebase}
                >
                  <Row justify="center" gutter={[10, 10]}>
                    <Col span={2}>
                      <GoogleCircleFilled style={{ fontSize: 24 }} />
                    </Col>
                    <Col span={21} pull={1}>
                      <span style={styles.providerBtn}>
                        Continua con Google
                      </span>
                    </Col>
                  </Row>
                </Button>
              </Row>
              <Row gutter={[0, 10]}>
                <Button
                  disabled={true}
                  type="secondary"
                  style={{ width: "100%", paddingBottom: "10px" }}
                >
                  <Row justify="center" gutter={[10, 10]}>
                    <Col span={2}>
                      <FacebookFilled style={{ fontSize: 24 }} />
                    </Col>
                    <Col span={21} pull={1}>
                      <span style={styles.providerBtn}>
                        Continua con Facebook
                      </span>
                    </Col>
                  </Row>
                </Button>
              </Row>
              <Divider style={styles.modalLoginDivider}>Oppure</Divider>
            </>
          )}

          {/* //---------------------- */}
          <Row gutter={[0, 10]}>
            <Button
              disabled={this.state.buttonDisabled}
              type="secondary"
              style={{ width: "100%", paddingBottom: "10px" }}
              onClick={() => {
                this.setState({ mailLoginVisible: true, mainVisible: false });
              }}
            >
              <Row justify="center" gutter={[10, 10]}>
                <Col span={2}>
                  <MailFilled style={{ fontSize: 24 }} />
                </Col>
                <Col span={21} pull={1}>
                  <span style={styles.providerBtn}>Continua con Email</span>
                </Col>
              </Row>
            </Button>
          </Row>
        </Modal>
        {/* MODALE ACCESSO EMAIL */}
        <Modal
          transitionName="zoom"
          destroyOnClose
          onCancel={this._closeMailLoginModal}
          footer={null}
          title={
            <Image
              style={styles.modalLogo}
              width="158px"
              onClick={null}
              src={require("../Assets/logo-cyan.png")}
            />
          }
          visible={this.state.mailLoginVisible}
        >
          <Row justify="center" align="middle" gutter={[15, 15]}>
            <Col span={24}>
              <div
                style={{
                  background: Settings.colors.darkGrey,
                  borderRadius: 4,
                  padding: 15,
                }}
              >
                <h3 style={{ color: Settings.colors.white, fontWeight: 700 }}>
                  Sei un negozio?
                </h3>
                <p style={{ color: Settings.colors.white }}>
                  Accedi al tuo pannello di controllo o registra la tua
                  attività.
                </p>
                <a href={Settings.base_url.negozio} target="_blank">
                  <Button type="secondary">Clicca qui</Button>
                </a>
              </div>
            </Col>
            <Col span={24}>
              <h2 style={styles.titleModal}>Sei un cliente? Accedi!</h2>
            </Col>
          </Row>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaLoginUtente}
            validateOnMount={false}
            validateOnChange={true}
            onSubmit={this._doLogin}
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isValid,
              isSubmitting,
              dirty,
            }) => (
              <Form>
                <Row gutter={[0, 15]}>
                  <Col span={24}>
                    <span style={styles.inputLabel}>Email</span>
                    <Input
                      placeholder="Inserisci email"
                      value={values.email}
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      style={{ marginTop: "10px" }}
                    />
                    <span style={styles.inputError}>
                      {touched.email && errors.email}
                    </span>
                  </Col>
                </Row>
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <span style={styles.inputLabel}>Password</span>
                    <Input.Password
                      placeholder="Inserisci password"
                      value={values.password}
                      onChange={handleChange("password")}
                      onBlur={handleBlur("password")}
                      style={{ marginTop: "10px" }}
                    />
                    <span style={styles.inputError}>
                      {touched.password && errors.password}
                    </span>
                  </Col>
                </Row>
                <Row justify="space-around" gutter={[0, 0]}>
                  <Col span={24}>
                    <Button
                      block
                      type="primary"
                      onClick={handleSubmit}
                      loading={this.state.loginLoading}
                      disabled={!(isValid && dirty) || isSubmitting}
                      style={{ marginBottom: 10 }}
                    >
                      {isSubmitting ? "Attendi..." : "Accedi"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>

          <Row justify="space-around" gutter={[0, 20]}>
            <Col>
              <a
                onClick={() => {
                  this.setState({
                    mailLoginVisible: false,
                    recuperaPasswordVisible: true,
                  });
                }}
                style={{ fontSize: "14px" }}
              >
                Password dimenticata?
              </a>
            </Col>
          </Row>
          <Row justify="space-around" style={{ marginBottom: 10 }}>
            <Col>
              <span style={{ color: Settings.colors.grey, fontSize: "14px" }}>
                Non hai ancora un account?
              </span>
            </Col>
          </Row>
          <Row justify="center" align="middle" gutter={[0, 20]}>
            <Col span={24}>
              <Button
                block
                type="secondary"
                onClick={() => {
                  this.setState({
                    mailLoginVisible: false,
                    registerVisible: true,
                  });
                }}
              >
                Registrati
              </Button>
            </Col>
          </Row>
          <Row justify="space-around">
            <span style={{ color: Settings.colors.grey, fontSize: "14px" }}>
              Vuoi accedere con Google o Facebook?{" "}
              <a
                style={{ fontSize: "14px" }}
                onClick={() => {
                  this.setState({ mailLoginVisible: false, visibleMain: true });
                }}
              >
                {" "}
                Clicca qui
              </a>
            </span>
          </Row>
        </Modal>
        {/* MODALE PER REGISTRAZIONE */}
        <Modal
          transitionName="zoom"
          destroyOnClose
          visible={this.state.registerVisible}
          footer={null}
          onCancel={() => {
            this.setState({ registerVisible: false });
            this.props.callbackClose();
          }}
          title={
            <Image
              style={styles.modalLogo}
              width="158px"
              src={require("../Assets/logo-cyan.png")}
            ></Image>
          }
        >
          <Row justify="center" align="middle" gutter={[15, 15]}>
            <Col span={24}>
              <h2 style={styles.titleModal}>Registrati, è gratis!</h2>
            </Col>
          </Row>
          <Formik
            initialErrors={initialValues}
            validateOnMount={false}
            validateOnChange={true}
            initialValues={initialValues}
            validationSchema={validationSchemaRegistraUtente}
            onSubmit={this._submitForm}
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
              isValid,
              isSubmitting,
              dirty,
            }) => (
              <Form>
                <Row gutter={[0, 15]}>
                  <Col span={24}>
                    <span style={styles.inputLabel}>Nome</span>
                    <Input
                      placeholder="Inserisci nome"
                      value={values.nome}
                      onChange={handleChange("nome")}
                      onBlur={handleBlur("nome")}
                      style={{ marginTop: "10px" }}
                    />
                    <span style={styles.inputError}>
                      {touched.nome && errors.nome}
                    </span>
                  </Col>
                </Row>
                <Row gutter={[0, 15]}>
                  <Col span={24}>
                    <span style={styles.inputLabel}>Cognome</span>
                    <Input
                      placeholder="Inserisci cognome"
                      value={values.cognome}
                      onChange={handleChange("cognome")}
                      onBlur={handleBlur("cognome")}
                      style={{ marginTop: "10px" }}
                    />
                    <span style={styles.inputError}>
                      {touched.cognome && errors.cognome}
                    </span>
                  </Col>
                </Row>
                <Row gutter={[0, 15]}>
                  <Col span={24}>
                    <div style={{ marginBottom: "10px" }}>
                      <span style={styles.inputLabel}>Cellulare</span>
                    </div>

                    <PhoneInput
                      value={values.cellulare}
                      onChange={handleChange("cellulare")}
                      onBlur={handleBlur("cellulare")}
                      placeholder="Inserisci telefono"
                      country="it"
                      countryCodeEditable={false}
                      inputClass="input-text"
                      inputStyle={{
                        width: "100%",
                        marginTop: "40px",
                      }}
                    />
                    <span style={styles.inputError}>
                      {touched.cellulare && errors.cellulare}
                    </span>
                  </Col>
                  <Col span={24}>
                    <span style={styles.inputLabel}>Città di residenza</span>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={(value) => {
                        setFieldValue("citta", value);
                        let comune = comuni_json.find((i) => i.nome == value);
                        setFieldValue(
                          "cap",
                          comune && Array.isArray(comune.cap)
                            ? comune.cap[0]
                            : comune.cap
                        );
                        this.setState({
                          selected_citta_caps:
                            comune && Array.isArray(comune.cap)
                              ? comune.cap
                              : [comune.cap],
                        });
                        setFieldValue("provincia", comune.provincia.nome);
                      }}
                      size="default"
                      placeholder="Scegli la città"
                      style={{ width: "100%" }}
                      onBlur={handleBlur("citta")}
                      value={values.citta}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {comuni_json.map((c) => (
                        <Option key={c.nome}>{c.nome}</Option>
                      ))}
                    </Select>
                    <span style={styles.inputError}>
                      {touched.citta && errors.citta}
                    </span>
                  </Col>
                </Row>
                <Row gutter={[0, 15]}>
                  <Col span={24}>
                    <span style={styles.inputLabel}>Email</span>
                    <Input
                      value={values.email}
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      placeholder="Inserisci email"
                      style={{ marginTop: "10px" }}
                    />
                    <span style={styles.inputError}>
                      {touched.email && errors.email}
                    </span>
                  </Col>
                </Row>
                <Row gutter={[0, 15]}>
                  <Col span={24}>
                    <span style={styles.inputLabel}>Password</span>
                    <Input.Password
                      value={values.password}
                      onChange={handleChange("password")}
                      onBlur={handleBlur("password")}
                      placeholder="Inserisci password"
                      style={{ marginTop: "10px" }}
                    />
                    <span style={styles.inputError}>
                      {touched.password && errors.password}
                    </span>
                  </Col>
                </Row>
                <Row gutter={[0, 40]}>
                  <Col span={24}>
                    <span style={styles.inputLabel}>Ripeti password</span>
                    <Input.Password
                      value={values.confermaPassword}
                      onChange={handleChange("confermaPassword")}
                      onBlur={handleBlur("confermaPassword")}
                      placeholder="Reinserisci la password"
                      style={{ marginTop: "10px" }}
                    />
                    <span style={styles.inputError}>
                      {touched.confermaPassword && errors.confermaPassword}
                    </span>
                  </Col>
                </Row>
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <Checkbox
                      style={{ fontSize: "17px" }}
                      onChange={handleChange("privacy")}
                    >
                      Si invita l’utente a visionare la{" "}
                      <Link
                        target="_blank"
                        to={require("../Assets/PDF/Privacy policy.pdf")}
                      >
                        Privacy Policy
                      </Link>{" "}
                      di questo sito.
                    </Checkbox>
                    <span style={styles.inputError}>{errors.privacy}</span>
                  </Col>
                </Row>
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <Checkbox
                      style={{ fontSize: "17px" }}
                      onChange={handleChange("marketing")}
                    >
                      Letta la{" "}
                      <Link
                        target="_blank"
                        to={require("../Assets/PDF/Privacy policy.pdf")}
                      >
                        Privacy Policy
                      </Link>
                      , presto il mio consenso per l’invio a mezzo email, da
                      parte di questo sito, di comunicazioni informative e
                      promozionali, inclusa la newsletter, riferite a prodotti
                      e/o servizi propri e/o di terzi e per lo svolgimento di
                      ricerche di mercato.
                    </Checkbox>
                    <span style={styles.inputError}>{errors.marketing}</span>
                  </Col>
                </Row>
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <Checkbox
                      style={{ fontSize: "17px" }}
                      onChange={handleChange("profilazione")}
                    >
                      Letta la{" "}
                      <Link
                        target="_blank"
                        to={require("../Assets/PDF/Privacy policy.pdf")}
                      >
                        Privacy Policy
                      </Link>
                      , presto il mio consenso per l’invio a mezzo e-mail, da
                      parte di questo sito, di comunicazioni informative e
                      promozionali, inclusa la newsletter, riferite a prodotti
                      e/o servizi di mio specifico interesse.
                    </Checkbox>
                    <span style={styles.inputError}>{errors.profilazione}</span>
                  </Col>
                </Row>
                <Row gutter={[0, 40]}>
                  <Col span={24}>
                    <Button
                      block
                      loading={this.state.registerLoading}
                      type="primary"
                      disabled={!(isValid && dirty) || isSubmitting}
                      onClick={handleSubmit}
                    >
                      {this.state.registerLoading ? "Attendi..." : "Registrati"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>

          <Row justify="space-around" gutter={[0, 0]}>
            <span style={{ color: Settings.colors.grey, fontSize: "14px" }}>
              Hai già un account?
            </span>
          </Row>
          <Row justify="center" align="middle" gutter={[0, 40]}>
            <Col span={24}>
              <Button
                block
                type="secondary"
                onClick={() => {
                  this.setState({
                    registerVisible: false,
                    mailLoginVisible: true,
                  });
                }}
              >
                Accedi
              </Button>
            </Col>
          </Row>
          <Row justify="space-around">
            <span style={{ color: Settings.colors.grey, fontSize: "14px" }}>
              Vuoi accedere con Google o Facebook?{" "}
              <a
                style={{ fontSize: "14px" }}
                onClick={() => {
                  this.setState({ registerVisible: false, visibleMain: true });
                }}
              >
                {" "}
                Clicca qui
              </a>
            </span>
          </Row>
        </Modal>
        {/* MODALE RECUPERO PASSWORD */}
        <Modal
          destroyOnClose
          visible={this.state.recuperaPasswordVisible}
          footer={null}
          onCancel={() => {
            this.setState({ recuperaPasswordVisible: false });
            this.props.callbackClose();
          }}
          title={
            <Image
              style={styles.modalLogo}
              width="158px"
              src={require("../Assets/logo-cyan.png")}
            ></Image>
          }
        >
          <Formik
            validateOnMount={false}
            validateOnChange={true}
            initialValues={{ email: "" }}
            validationSchema={validationSchemaRecuperaPassword}
            onSubmit={this._resetPassword}
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
              isValid,
              isSubmitting,
              dirty,
            }) => (
              <Form>
                <Row gutter={[0, 20]}>
                  <Col md={24} sm={24}>
                    <span style={styles.inputLabel}>Email</span>
                    <Input
                      value={values.email}
                      onBlur={handleBlur("email")}
                      onChange={handleChange("email")}
                      style={{ marginTop: "10px" }}
                      placeholder="Inserisci email"
                    />
                    <span style={styles.inputError}>
                      {touched.email && errors.email}
                    </span>
                  </Col>
                </Row>
                <Row justify="space-around" gutter={[0, 10]}>
                  <Col md={24} sm={24}>
                    {this.state.countdownPasswordVisible ? (
                      <CountdownMail
                        seconds={60}
                        onFinish={() =>
                          this.setState({
                            countdownPasswordVisible: false,
                          })
                        }
                      />
                    ) : (
                      <Button
                        block
                        type="primary"
                        loading={this.state.loadingPassword}
                        disabled={
                          !(isValid && dirty) || this.state.loadingPassword
                        }
                        onClick={handleSubmit}
                      >
                        {" "}
                        Invia il link
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>

          <Row justify="space-around" gutter={[0, 15]}>
            <Col>
              <a
                style={{ fontSize: "14px" }}
                onClick={() => {
                  this.setState({
                    recuperaPasswordVisible: false,
                    mailLoginVisible: true,
                  });
                }}
              >
                Torna indietro
              </a>
            </Col>
          </Row>
        </Modal>
        {/* MODALE REGISTRAZIONE COMPLETATA */}
        <Modal
          visible={this.state.registrazioneCompletataVisible}
          destroyOnClose
          footer={null}
          onCancel={() => {
            this.setState({ registrazioneCompletataVisible: false });
            this.props.callbackClose();
          }}
          title={
            <Image
              style={styles.modalLogo}
              width="158px"
              src={require("../Assets/logo-cyan.png")}
            />
          }
        >
          <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
            <Col>
              <Result
                style={{ padding: "0" }}
                status="success"
                icon={<CheckOutlined style={styles.doneIcon} />}
                title={<span style={styles.resultTitle}>Ci siamo quasi!</span>}
                subTitle={
                  <span style={styles.resultSubtitle}>
                    Ti abbiamo inviato una email per confermare il tuo account.
                    Controlla anche la casella “Spam”. Clicca sul link che trovi
                    nella email per attivare il tuo profilo.
                  </span>
                }
              />
            </Col>
          </Row>
          <Row justify="center" align="middle" style={{ marginBottom: 100 }}>
            <Col span={24}>
              {resendMailVisible ? (
                <Button
                  block
                  type="primary"
                  key="console"
                  loading={this.state.sendMailLoading}
                  disabled={this.state.sendMailLoading}
                  onClick={async () => {
                    this.setState({ sendMailLoading: true });
                    try {
                      await resendEmailVerification();
                      notification.destroy();
                      notification.success({
                        message: "Congratulazioni!",
                        description:
                          "Controlla nella tua casella mail e conferma il tuo indirizzo!",
                      });
                    } catch (error) {
                      console.log(error);
                      notification.destroy();
                      notification.error({
                        message: "Errore!",
                        description:
                          "Si è verificato un errore nell'invio della mail di conferma",
                      });
                    } finally {
                      this.setState({
                        sendMailLoading: false,
                        resendMailVisible: false,
                      });
                    }
                  }}
                >
                  Inviami un'altra email di verifica
                </Button>
              ) : (
                <CountdownMail
                  seconds={60}
                  onFinish={() =>
                    this.setState({
                      resendMailVisible: true,
                    })
                  }
                />
              )}
            </Col>
          </Row>
          <Row justify="center" align="bottom">
            <span style={{ color: Settings.colors.grey, marginBottom: 8 }}>
              Hai già confermato l'account?
            </span>
            <Col span={24}>
              <Button
                block
                type="secondary"
                key="console"
                onClick={() =>
                  this.setState({
                    registrazioneCompletataVisible: false,
                    mailLoginVisible: true,
                  })
                }
              >
                Accedi
              </Button>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

const styles = {
  inputError: {
    color: Settings.colors.red,
    fontSize: "14px",
  },
  providerBtn: {
    color: Settings.colors.darkGrey,
  },
  modalLoginDivider: {
    fontSize: 14,
    color: Settings.colors.grey,
  },
  titleModal: {
    fontSize: "24px",
    fontWeight: "700",
    textAlign: "center",
    marginBottom: 0,
    color: Settings.colors.darkGrey,
  },
  inputLabel: {
    paddingBottom: "10px",
    fontWeight: 600,
    color: Settings.colors.darkGrey,
  },
  inputLabelPhone: {
    paddingBottom: "10px",
    marginBottom: "10px",
  },
  modalLogo: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  loginLogo: {
    left: "1px",
    float: "left",
    display: "inline",
  },
  doneIcon: {
    width: "80px",
    height: "80px",
    borderRadius: "40px",
    backgroundColor: Settings.colors.cyan,
    color: Settings.colors.white,
    fontSize: "30px !important",
  },
  resultTitle: {
    color: Settings.colors.darkGrey,
    fontSize: 24,
    fontWeight: "bold",
  },
  resultSubtitle: {
    color: Settings.colors.grey,
  },
};

function mapStateToProps(state) {
  return { auth: state.auth };
}
function mapDispatchToProps(dispatch) {
  return {
    successLogin: (user) => dispatch(successLogin(user)),
    successLogout: () => dispatch(successLogout()),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModaleLoginUtente)
);
