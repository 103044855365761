import { Functions } from "../Config/Fire";

export function getNegoziComune(
  id,
  keyword = null,
  limit = null,
  offset = null
) {
  const negozi = Functions.httpsCallable("utente-getNegoziComune");
  let data = { id: id };
  if (keyword) data.keyword = keyword;
  if (limit) data.limit = limit;
  if (offset) data.offset = offset;
  return negozi(data);
}

export function getNegoziComuneRand(id, limit = null, offset = null) {
  const negozi = Functions.httpsCallable("utente-getNegoziComuneRand");
  console.log(limit);
  let data = { id: id };
  if (limit) data.limit = limit;
  if (offset) data.offset = offset;
  return negozi(data);
}
export function getStripeDetails(id) {
  const getStripeDetails = Functions.httpsCallable("negozio-getStripeDetails");
  return getStripeDetails({ id: id });
}
export function getNegozio(id, slug) {
  const negozio = Functions.httpsCallable("utente-getNegozio");
  return negozio({ slug: slug, id: id });
}

export function getProdottiNegozio(
  id,
  limit = null,
  offset = null,
  categoria = null
) {
  const prodotti = Functions.httpsCallable("utente-getProdottiNegozio");
  let data = { id: id };
  if (limit) data.limit = limit;
  if (offset) data.offset = offset;
  if (categoria) data.categoria = categoria;
  return prodotti(data);
}

export function getServiziNegozio(
  id,
  limit = null,
  offset = null,
  categoria = null
) {
  const servizi = Functions.httpsCallable("utente-getServiziNegozio");
  let data = { id: id };
  if (limit) data.limit = limit;
  if (offset) data.offset = offset;
  if (categoria) data.categoria = categoria;
  return servizi(data);
}

export function getNegozioFirebaseInfo(uid) {
  const negozio = Functions.httpsCallable("utente-getNegozioFirebaseInfo");
  return negozio(uid);
}

export async function getNegozioId(data) {
  const getNegozioId = Functions.httpsCallable("utente-getNegozioId");
  return await getNegozioId(data);
}

export async function getNegozioUid(telefono_referente) {
  const getNegozioUid = Functions.httpsCallable("utente-getNegozioUid");
  return await getNegozioUid(telefono_referente);
}

export async function getAppuntamentiNegozio(id_negozio) {
  const getAppuntamentiNegozio = Functions.httpsCallable(
    "utente-getAppuntamentiNegozio"
  );
  return await getAppuntamentiNegozio(id_negozio);
}
