import React from "react";

// Third-party components
import { Spin, Row, Col } from "antd";

import CustomResult from "../Components/CustomResult";
import Settings from "../Config/Settings";
// Libraries
import { applyActionCode, checkCode, userType } from "../Controllers/Auth";

class VerificaMail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "loading",
      code: "",
      email: "",
    };
  }

  _goToPanel = async () => {
    if (this.state.tipo === "utente") {
      return this.props.history.push("/");
    } else if (this.state.tipo === "negozio") {
      if (process.env.REACT_APP_STAGE === "development") {
        return window.open(
          "https://negozio-garzone-test.web.app/accedi",
          "_self"
        );
      } else {
        return window.open("https://negozio.garzone.store/accedi", "_self");
      }
    } else if (this.state.tipo === "comune") {
      if (process.env.REACT_APP_STAGE == "development") {
        return window.open(
          "https://comune-garzone-test.web.app/accedi",
          "_self"
        );
      } else {
        return window.open("https://comune.garzone.store/accedi", "_self");
      }
    }
  };
  _loader = async () => {
    let {
      match: { params },
    } = this.props;
    if (!params.mode) {
      this.props.history.push("/");
    } else {
      let dividedParams = params.mode.split("&");
      let code = "";
      dividedParams.forEach((item) => {
        if (item.substring(0, 7) == "oobCode") {
          code = item.replace("oobCode=", "");
        }
      });
      try {
        let mail = await checkCode(code);
        if (mail) {
          let checkResult = await applyActionCode(code);
          let user = await userType(mail);
          let tipo = user.data.tipologia;
          this.setState({ status: "valid", code, tipo });
        } else {
          this.setState({ status: "invalid" });
        }
      } catch (error) {
        if (error.code == "auth/expired-action-code") {
          this.setState({ status: "expired" });
        } else if (error.code == "auth/invalid-action-code") {
          this.setState({ status: "invalid" });
        } else if (error.code == "auth/user-disabled") {
          this.setState({ status: "banned" });
        } else if (error.code == "auth/user-not-found") {
          this.setState({ status: "not-found" });
        }
      }
    }
  };
  componentDidMount = () => {
    this._loader();
  };

  render() {
    this._loader();
    return (
      <div style={styles.loginBackground}>
        <Row justify="center" align="middle">
          <Col>
            <img
              src={require("../Assets/logo-white.png")}
              style={{ height: 100, marginTop: 35, marginBottom: 39 }}
              alt="logo"
            />
          </Col>
        </Row>
        <Row justify="center" align="middle" gutter={[15, 15]}>
          <Col lg={12} md={18} sm={24}>
            {this.state.status === "loading" ? (
              <div style={{ textAlign: "center" }}>
                <Spin
                  size="large"
                  style={{ marginTop: 100, alignSelf: "center" }}
                />
              </div>
            ) : this.state.status === "valid" ? (
              <CustomResult
                icon="success"
                title="Account verificato"
                subtitle="Adesso puoi accedere a Garzone."
                button={{ title: "Accedi", route: () => this._goToPanel() }}
              />
            ) : this.state.status === "expired" ? (
              <CustomResult
                icon="error"
                title="Link scaduto"
                subtitle={
                  <span>
                    Il link che hai aperto è scaduto e non è più utilizzabile.
                    Per ottenere un nuovo link effettua nuovamente l'accesso..
                  </span>
                }
                button={{
                  title: "Accedi",
                  route: () => this.props.history.push("/"),
                }}
              />
            ) : this.state.status === "invalid" ? (
              <CustomResult
                icon="error"
                title="Link non valido"
                subtitle={
                  <span>
                    Il link che hai aperto non è valido e non può essere usato.
                    Per ottenere un nuovo link effettua nuovamente l'accesso.
                  </span>
                }
                button={{
                  title: "Accedi",
                  route: () => this.props.history.push("/"),
                }}
              />
            ) : this.state.status === "banned" ? (
              <CustomResult
                icon="info"
                title="Accesso negato"
                subtitle={
                  <span>
                    Il tuo utente è stato disattivato, pertanto non puoi
                    accedere a Garzone.
                  </span>
                }
              />
            ) : this.state.status === "not-found" ? (
              <CustomResult
                icon="error"
                title="Non trovato"
                subtitle={<span>L'utente non è stato trovato</span>}
                button={{
                  title: "Torna alla home",
                  route: () => this.props.history.push("/"),
                }}
              />
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}

const styles = {
  loginBackground: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: `url(${require("../Assets/background.png")})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "15px",
  },
  logoStyle: {
    height: 100,
    marginTop: 35,
    marginBottom: 35,
  },
  title: {
    color: Settings.colors.white,
    textAlign: "center",
  },
  forgotPassword: {
    textAlign: "center",
    color: "var(--white)",
  },
  alert: {
    marginBottom: "15px",
    alignSelf: "center",
  },
};

export default VerificaMail;
