import React from "react";
import { Helmet } from "react-helmet";

// import redux
import { connect } from "react-redux";

import { Link } from "react-router-dom";

// ant design components
import { Button, Row, Col, Carousel } from "antd";

// ant design icons

// import settings
import Settings from "../Config/Settings";

// import custom components
import Wrapper from "../Components/Wrapper";
import Panel from "../Components/Panel";
import CardNegozio from "../Components/CardNegozio";
import CardNegozioSearchInput from "../Components/CardNegozioSearchInput";
import CardPromozione from "../Components/CardPromozione";
import SearchInput from "../Components/SearchInput";
import LoadingBlock from "../Components/LoadingBlock";

// import controllers function
import { getNegoziComuneRand } from "../Controllers/Negozi";
import { getPromozioniComune } from "../Controllers/Promozioni";
import CardCategoriaSearchInput from "../Components/CardCategoriaSearchInput";

class Comune extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading_negozi: true,
      loading_promozioni: true,
      negozi: [],
      promozioni: [],
    };
  }

  componentDidMount = () => {
    if (this.state.negozi.length === 0) this._getNegozi();
    if (this.state.promozioni.length === 0) this._getPromozioni();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.slug !== this.props.slug) {
      this._getNegozi();
      this._getPromozioni();
    }
  }

  _getNegozi = async () => {
    this.setState({ loading_negozi: true });
    console.log(this.props)
    // slug from URL
    const { slug } = this.props.match.params;
    if (slug === this.props.slug) {
      try {
        const negozi = await getNegoziComuneRand(this.props.id, 10, 0);
        this.setState({ negozi: negozi.data, loading_negozi: false });
      } catch (e) {
        this.setState({ loading_negozi: false });
        console.log(e);
      }
    }
  };

  _getPromozioni = async () => {
    this.setState({ loading_promozioni: true });
    // slug from URL
    const { slug } = this.props.match.params;
    if (slug == this.props.slug) {
      try {
        const promozioni = await getPromozioniComune(this.props.slug);
        this.setState({
          promozioni: promozioni.data,
          loading_promozioni: false,
        });
      } catch (e) {
        this.setState({ loading_promozioni: false });
        console.log(e);
      }
    }
  };

  render() {
    // const { slug } = this.props;
    return (
      <Wrapper>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Garzone Store</title>
        </Helmet>
        {/* Sezione ricerca negozi */}
        <Row style={styles.section} align="top">
          <Col span={24}>
            <Panel
              title="Trova quello di cui hai bisogno"
              background={Settings.colors.lightCyan}
              borderRadius="none"
              titleColor={Settings.colors.darkGrey}
              fontSizeTitle="38px"
              rightImage={require("../Assets/Tavola disegno 3.png")}
            >
              <div style={{ padding: "0 15px" }}>
                <Row>
                  <Col span={24}>
                    <SearchInput
                      disabled={this.state.loading_negozi}
                      placeholder={
                        this.state.loading_negozi
                          ? "Attendi caricamento..."
                          : "Cerca un negozio, un prodotto o una categoria merceologica..."
                      }
                      numberOfResults={3}
                      fieldsToFilter={["categoria", "nome_negozio", "tags"]}
                      defaultItemsName="SUGGERIMENTI"
                      defaultItemsNameStyle={{ color: Settings.colors.grey }}
                      defaultItems={
                        this.state.negozi &&
                        [
                          ...new Set(
                            this.state.negozi.map(
                              (value, index) => value.categoria
                            )
                          ),
                        ].sort()
                      }
                      renderDefaultItem={({ item }) => (
                        <a
                          target="_blank"
                          href={
                            Settings.base_url.utente +
                            "/comuni/" +
                            this.props.slug +
                            "/negozi?ricerca=" +
                            item
                          }
                        >
                          <Row gutter={[0, 20]}>
                            <Col span={24}>
                              <CardCategoriaSearchInput nome={item} />
                            </Col>
                          </Row>
                        </a>
                      )}
                      items={this.state.negozi}
                      resultsUrl={
                        Settings.base_url.utente +
                        "/comuni/" +
                        this.props.slug +
                        "/negozi?ricerca="
                      }
                      renderItem={({ item }) => (
                        <a
                          target="_blank"
                          href={
                            Settings.base_url.utente +
                            "/comuni/" +
                            this.props.slug +
                            "/negozi/" +
                            item.slug
                          }
                        >
                          <Row gutter={[0, 20]}>
                            <Col span={24}>
                              <CardNegozioSearchInput
                                nome={item.nome_negozio}
                                categoria={item.categoria}
                                immagine={item.logo}
                              />
                            </Col>
                          </Row>
                        </a>
                      )}
                    />
                  </Col>
                </Row>
              </div>
            </Panel>
          </Col>
        </Row>

        {/* Sezione Carosello Negozi */}

        {this.state.loading_negozi ? (
          <>
            {/* <Row justify="space-between">
              <Col md={4}>
                <Skeleton
                  // style={{ width: "100px" }}
                  active
                  avatar={false}
                  title={false}
                  input={false}
                  button={false}
                  paragraph={{ rows: 1, width: "100%" }}
                />
              </Col>
              <Col md={2} offset={18}>
                <Skeleton
                  // style={{ width: "100px" }}
                  active
                  avatar={false}
                  title={false}
                  input={false}
                  button={false}
                  paragraph={{ rows: 1, width: "100%" }}
                />
              </Col>
            </Row>
            <Row justify="space-between" gutter={20}>
              <SkeletonNegozio />
              <SkeletonNegozio />
              <SkeletonNegozio />
              <SkeletonNegozio />
            </Row> */}
            <LoadingBlock height="300px" />
          </>
        ) : (
          <Row style={styles.section}>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col>
                  <h2 style={styles.title}>Guarda i negozi</h2>
                </Col>
                <Col>
                  <Link to={"/comuni/" + this.props.slug + "/negozi"}>
                    <Button size={"medium"} type="secondary">
                      Vedi tutti
                    </Button>
                  </Link>
                </Col>
              </Row>
              <br />
              <Row gutter={[20, 40]}>
                <Col span={24}>
                  {this.state.negozi.length > 0 ? (
                    <Carousel
                      style={styles.section}
                      autoplay
                      slidesToShow={4}
                      infinite={false}
                      slidesToScroll={1}
                      swipeToSlide
                      dots={false}
                      responsive={[
                        {
                          breakpoint: 576,
                          settings: {
                            slidesToShow: 1,
                          },
                        },
                        {
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 1,
                          },
                        },
                        {
                          breakpoint: 992,
                          settings: {
                            slidesToShow: 2,
                          },
                        },
                        {
                          breakpoint: 1200,
                          settings: {
                            slidesToShow: 3,
                          },
                        },
                      ]}
                    >
                      {this.state.negozi.map((item, index) => {
                        if (item.logo)
                          return (
                            <div key={index}>
                              <div style={{ marginRight: "20px" }}>
                                <CardNegozio
                                  key={index}
                                  nome={item.nome_negozio}
                                  immagine={item.logo}
                                  categoria={item.categoria}
                                  indirizzo={`${item.indirizzo} ${item.civico}, ${item.cap} ${item.citta}`}
                                  sql_id={item.id}
                                  target={
                                    Settings.base_url.utente +
                                    "/comuni/" +
                                    this.props.slug +
                                    "/negozi/" +
                                    item.slug
                                  }
                                  containerStyles={{ zIndex: 1 }}
                                  chatAbilitata={item.chat}
                                />
                              </div>
                            </div>
                          );
                        else return null;
                      })}
                    </Carousel>
                  ) : (
                    <p>Non ci sono negozi al momento.</p>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {/* Sezione Carosello Promozioni */}

        {this.state.loading_promozioni ? (
          <>
            {/* <br />
            <Row justify="space-between">
              <Col md={4}>
                <Skeleton
                  // style={{ width: "100px" }}
                  active
                  avatar={false}
                  title={false}
                  input={false}
                  button={false}
                  paragraph={{ rows: 1, width: "100%" }}
                />
              </Col>
              <Col md={2} offset={18}>
                <Skeleton
                  // style={{ width: "100px" }}
                  active
                  avatar={false}
                  title={false}
                  input={false}
                  button={false}
                  paragraph={{ rows: 1, width: "100%" }}
                />
              </Col>
            </Row>
            <Row justify="space-between" gutter={20}>
              <SkeletonNegozio />
              <SkeletonNegozio />
              <SkeletonNegozio />
              <SkeletonNegozio />
            </Row> */}
            <LoadingBlock height="300px" />
          </>
        ) : (
          <Row style={styles.section}>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col>
                  <h2 style={styles.title}>Le ultime promozioni</h2>
                </Col>
                <Col>
                  <Link to={"/comuni/" + this.props.slug + "/promozioni"}>
                    <Button size={"medium"} type="secondary">
                      Vedi tutte
                    </Button>
                  </Link>
                </Col>
              </Row>
              <br />
              <Row gutter={[20, 40]}>
                <Col span={24}>
                  {this.state.promozioni.length > 0 ? (
                    <Carousel
                      style={styles.section}
                      autoplay
                      slidesToShow={4}
                      infinite={false}
                      slidesToScroll={1}
                      swipeToSlide
                      dots={false}
                      responsive={[
                        {
                          breakpoint: 576,
                          settings: {
                            slidesToShow: 1,
                          },
                        },
                        {
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 1,
                          },
                        },
                        {
                          breakpoint: 992,
                          settings: {
                            slidesToShow: 2,
                          },
                        },
                        {
                          breakpoint: 1200,
                          settings: {
                            slidesToShow: 3,
                          },
                        },
                      ]}
                    >
                      {this.state.promozioni.map((item, index) => {
                        return (
                          <div key={index}>
                            <div style={{ marginRight: "20px" }}>
                              <a
                                target="_blank"
                                href={
                                  Settings.base_url.utente +
                                  "/comuni/" +
                                  this.props.slug +
                                  "/promozioni/" +
                                  item.id
                                }
                              >
                                <CardPromozione
                                  key={index}
                                  nome_negozio={item.nome_negozio}
                                  immagine={item.immagine}
                                  titolo={item.titolo}
                                  data_scadenza={item.data_scadenza}
                                />
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  ) : (
                    <p>Non ci sono promozioni al momento.</p>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Wrapper>
    );
  }
}

const styles = {
  section: {
    marginBottom: "50px",
    // marginTop: "-10px",
    paddingTop: "0px",
  },
  title: {
    fontSize: "28px",
    fontWeight: "700",
    marginBottom: 0,
    color: Settings.colors.darkGrey,
  },
};

function mapStateToProps(state) {
  return {
    id: state.comuni.id,
    slug: state.comuni.slug,
    nome: state.comuni.nome,
    logo: state.comuni.logo,
  };
}

export default connect(mapStateToProps)(Comune);
