import React from "react";

// import redux
import { connect } from "react-redux";

// ant design components
import {
  Row,
  Col,
  Tabs,
  Image,
  Divider,
  Button,
  Typography,
  notification,
  Popover,
  Select,
} from "antd";
// ant design icons
import { withRouter } from "react-router-dom";

// import settings
import Settings from "../Config/Settings";
import placeholder from "../Assets/Background/placeholder.jpg";
// import custom components
import Wrapper from "../Components/Wrapper";
import Panel from "../Components/Panel";
import CardNegozio from "../Components/CardNegozio";
import CardNegozioSearchInput from "../Components/CardNegozioSearchInput";
import SearchInput from "../Components/SearchInput";
import LoadingBlock from "../Components/LoadingBlock";

// import utility functions
import { _findGetParameter } from "../Utility/utility";

// import controllers functions
import { getNegoziComune } from "../Controllers/Negozi";
import CardCategoriaSearchInput from "../Components/CardCategoriaSearchInput";
import {
  CompassFilled,
  CompassOutlined,
  MailOutlined,
  MessageOutlined,
  PhoneOutlined,
  SendOutlined,
  ShopFilled,
  ShopOutlined,
} from "@ant-design/icons";

import GoogleMapReact from "google-map-react";
import Modal from "antd/lib/modal/Modal";
import Chip from "../Components/Chip";
import { Formik } from "formik";
import Form from "antd/lib/form/Form";
import { Input } from "@material-ui/core";
import { getUtente, postFirstMessage } from "../Controllers/Utente";

class Negozi extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading_negozi: true,
      negozi: [],
      filtered_negozi: [],
      filtered_negozi_select: [],
      defaultSearchValue: "",
      negozio_details_visible: false,
      selected_negozio: null,
      selected_categoria: null,
    };
  }

  componentDidMount = () => {
    this._getNegozi();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.slug !== this.props.slug) {
      this._getNegozi();
    }
  }

  _openNegozioDetails = (key, childProps) => {
    this.setState(
      { negozio_details_visible: true, selected_negozio: childProps.negozio },
      () => console.log(this.state.selected_negozio)
    );
  };
  _closeNegozioDetails = () => {
    this.setState({ negozio_details_visible: false, selected_negozio: null });
  };

  _filterNegoziByCategoria = () => { };

  _getNegozi = async () => {
    this.setState({ loading_negozi: true });
    // slug from URL
    const { slug } = this.props.match.params;
    if (slug === this.props.slug) {
      try {
        const res = await getNegoziComune(this.props.id);
        let negozi = res.data;
        this.setState({ negozi: negozi });
        // se ho una ricerca da url filtro la lista dei negozi
        if (_findGetParameter("ricerca")) {
          const fieldsToFilter = ["categoria", "nome_negozio", "tags"];
          negozi = negozi.filter((item) => {
            return fieldsToFilter.some((field) =>
              JSON.stringify(item[field])
                .toLowerCase()
                .includes(_findGetParameter("ricerca").toLowerCase())
            );
          });
          this.setState({
            defaultSearchValue: _findGetParameter("ricerca").toLowerCase(),
          });
        }
        // aggiorno i negozi filtrati nello stato
        this.setState({
          filtered_negozi: negozi,
          filtered_negozi_select: negozi,
          loading_negozi: false,
        });
      } catch (e) {
        this.setState({ loading_negozi: false });
        console.log(e);
      }
    }
  };

  render() {
    return (
      <Wrapper>
        {/* Sezione ricerca negozi */}
        <Row style={styles.section}>
          <Col span={24}>
            <Panel
              title="Negozi"
              background={Settings.colors.darkGrey}
              borderRadius="none"
              titleColor={Settings.colors.white}
              fontSizeTitle="38px"
              rightImage={require("../Assets/illustrazione-spesa.png")}
            >
              <div style={{ padding: "0 15px" }}>
                <Row>
                  <Col span={24}>
                    <SearchInput
                      disabled={this.state.loading_negozi}
                      defaultValue={this.state.defaultSearchValue}
                      placeholder={
                        this.state.loading_negozi
                          ? "Attendi caricamento..."
                          : "Cerca un negozio, un prodotto o una categoria merceologica..."
                      }
                      numberOfResults={3}
                      fieldsToFilter={["categoria", "nome_negozio", "tags"]}
                      resultsUrl={
                        Settings.base_url.utente +
                        "/comuni/" +
                        this.props.slug +
                        "/negozi?ricerca="
                      }
                      defaultItemsName="SUGGERIMENTI"
                      defaultItemsNameStyle={{ color: Settings.colors.grey }}
                      defaultItems={
                        this.state.negozi &&
                        [
                          ...new Set(
                            this.state.negozi.map(
                              (value, index) => value.categoria
                            )
                          ),
                        ].sort()
                      }
                      renderDefaultItem={({ item }) => (
                        <a
                          target="_blank"
                          href={
                            Settings.base_url.utente +
                            "/comuni/" +
                            this.props.slug +
                            "/negozi?ricerca=" +
                            item
                          }
                        >
                          <Row gutter={[0, 20]}>
                            <Col span={24}>
                              <CardCategoriaSearchInput nome={item} />
                            </Col>
                          </Row>
                        </a>
                      )}
                      items={this.state.negozi}
                      renderItem={({ item }) => (
                        <a
                          target="_blank"
                          href={
                            Settings.base_url.utente +
                            "/comuni/" +
                            this.props.slug +
                            "/negozi/" +
                            item.slug
                          }
                        >
                          <Row gutter={[0, 20]}>
                            <Col span={24}>
                              <CardNegozioSearchInput
                                nome={item.nome_negozio}
                                categoria={item.categoria}
                                immagine={item.logo}
                              />
                            </Col>
                          </Row>
                        </a>
                      )}
                    />
                  </Col>
                </Row>
              </div>
            </Panel>
          </Col>
        </Row>

        {/* Risultati di ricerca */}
        <Tabs
          defaultActiveKey="1"
          onChange={() => this.setState({ selected_categoria: null })}
        >
          <Tabs.TabPane
            tab={
              <span style={{ textAlign: "center", marginRight: "5px" }}>
                <ShopOutlined style={{ margin: "0 10px 5px 10px" }} />
                Lista Negozi
              </span>
            }
            key="1"
          >
            <Row>
              <Col span={24}>
                {_findGetParameter("ricerca") && (
                  <Row gutter={[0, 30]}>
                    <Col span={24}>
                      <h3
                        style={{
                          color: Settings.colors.darkGrey,
                          marginBottom: 0,
                        }}
                      >
                        Risultati di ricerca per{" "}
                        <b>"{_findGetParameter("ricerca").toLowerCase()}"</b>
                      </h3>
                      <a
                        href={
                          Settings.base_url.utente +
                          "/comuni/" +
                          this.props.slug +
                          "/negozi"
                        }
                      >
                        Cancella ricerca
                      </a>
                    </Col>
                  </Row>
                )}

                <Row gutter={[20, 40]}>
                  {this.state.loading_negozi ? (
                    <Col span={24}>
                      <LoadingBlock height="300px" />
                    </Col>
                  ) : this.state.filtered_negozi.length > 0 ? (
                    this.state.filtered_negozi.map((item, index) => (
                      <Col
                        key={index}
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xl={{ span: 6 }}
                      >
                        <CardNegozio
                          key={index}
                          nome={item.nome_negozio}
                          immagine={item.logo}
                          categoria={item.categoria}
                          indirizzo={`${item.indirizzo} ${item.civico}, ${item.cap} ${item.citta}`}
                          chatAbilitata={item.chat}
                          sql_id={item.id}
                          target={
                            Settings.base_url.utente +
                            "/comuni/" +
                            this.props.slug +
                            "/negozi/" +
                            item.slug
                          }
                        />
                      </Col>
                    ))
                  ) : (
                    <Col span={24}>
                      <p>Nessun risultato.</p>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <span style={{ textAlign: "center", marginRight: "5px" }}>
                <CompassOutlined style={{ margin: "0 10px 5px 10px" }} />
                Mappa
              </span>
            }
            key="2"
          >
            {this.state.loading_negozi ? (
              <Col span={24}>
                <LoadingBlock height="300px" />
              </Col>
            ) : (
              <>
                {_findGetParameter("ricerca") && (
                  <Row gutter={[0, 30]}>
                    <Col span={24}>
                      <h3
                        style={{
                          color: Settings.colors.darkGrey,
                          marginBottom: 0,
                        }}
                      >
                        Risultati di ricerca per{" "}
                        <b>"{_findGetParameter("ricerca").toLowerCase()}"</b>
                      </h3>
                      <a
                        href={
                          Settings.base_url.utente +
                          "/comuni/" +
                          this.props.slug +
                          "/negozi"
                        }
                      >
                        Cancella ricerca
                      </a>
                    </Col>
                  </Row>
                )}
                <Row style={{ marginBottom: 15 }}>
                  <Col span={24}>
                    <Select
                      placeholder="Filtra per categoria"
                      allowClear
                      defaultValue={null}
                      value={this.state.selected_categoria}
                      style={{ width: 300 }}
                      onChange={(value) =>
                        this.setState({
                          selected_categoria: value,
                          filtered_negozi_select: value
                            ? this.state.filtered_negozi.filter(
                              (negozio, index) => negozio.categoria === value
                            )
                            : this.state.filtered_negozi,
                        })
                      }
                    >
                      {this.state.filtered_negozi &&
                        [
                          ...new Set(
                            this.state.filtered_negozi.map(
                              (negozio, index) => negozio.categoria
                            )
                          ),
                        ]
                          .sort()
                          .map((categoria, index) => (
                            <Select.Option key={index} value={categoria}>
                              {categoria}
                            </Select.Option>
                          ))}
                    </Select>
                  </Col>
                </Row>

                <div style={{ height: "120vh", width: "100%" }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: Settings.get_google_api_key() }}
                    defaultCenter={this.props.coords}
                    defaultZoom={14}
                    yesIWantToUseGoogleMapApiInternals
                    onChildClick={this._openNegozioDetails}
                  >
                    {this.state.filtered_negozi_select &&
                      this.state.filtered_negozi_select.map((item, index) => (
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "15px",
                            backgroundColor: Settings.colors.cyan,
                            textAlign: "center",
                            display: "table",
                          }}
                          key={item.id}
                          lat={+item.latitudine}
                          lng={+item.longitudine}
                          negozio={item}
                        >
                          <Popover content={item.nome_negozio}>
                            <ShopFilled
                              style={{
                                fontSize: "15px",
                                display: "table-cell",
                                verticalAlign: "middle",
                                color: Settings.colors.white,
                                cursor: "pointer",
                              }}
                            />
                          </Popover>
                        </div>
                      ))}
                  </GoogleMapReact>
                </div>
              </>
            )}
            {this.state.selected_negozio && (
              <Modal
                title="Dettagli negozio"
                visible={this.state.negozio_details_visible}
                onCancel={this._closeNegozioDetails}
                footer={
                  <>
                    <Row justify="start" align="middle">
                      <Button
                        onClick={() => {
                          this.props.history.push(
                            "/comuni/" +
                            this.props.slug +
                            "/negozi/" +
                            this.state.selected_negozio.slug
                          );
                        }}
                      >
                        Vai al negozio
                      </Button>
                    </Row>
                  </>
                }
              >
                <Row gutter={[15, 15]}>
                  <Col xs={24} md={8}>
                    <img
                      style={{ borderRadius: "4px", width: "100%" }}
                      src={this.state.selected_negozio.logo ?? placeholder}
                    ></img>
                  </Col>
                  <Col xs={24} md={16}>
                    <Row style={{ marginBottom: 5 }}>
                      <Chip
                        title={this.state.selected_negozio.categoria}
                      ></Chip>
                    </Row>
                    <Row style={{ marginBottom: 5 }}>
                      <span
                        style={{
                          fontWeight: "bold",
                          color: Settings.colors.darkGrey,
                          fontSize: 17,
                        }}
                      >
                        {this.state.selected_negozio.nome_negozio}
                      </span>
                    </Row>

                    <Row style={{ marginBottom: 5 }}>
                      <Typography.Paragraph
                        ellipsis={50}
                        style={{
                          color: Settings.colors.darkGrey,
                          fontSize: 14,
                        }}
                      >
                        {this.state.selected_negozio.descrizione}
                      </Typography.Paragraph>
                    </Row>
                    <Row style={{ marginBottom: 15 }}>
                      <span
                        style={{
                          color: Settings.colors.darkGrey,
                          fontSize: 14,
                        }}
                      >
                        <a
                          target="_blank"
                          href={`https://maps.google.com/?q=${this.state.selected_negozio.indirizzo} ${this.state.selected_negozio.civico}, ${this.state.selected_negozio.cap} ${this.state.selected_negozio.citta}`}
                        >{`${this.state.selected_negozio.indirizzo} ${this.state.selected_negozio.civico}, ${this.state.selected_negozio.citta} ${this.state.selected_negozio.cap}`}</a>
                      </span>
                    </Row>
                    {this.state.selected_negozio.email ||
                      this.state.selected_negozio.telefono ? (
                      <>
                        <Row style={{ marginBottom: 5 }}>
                          <span
                            style={{
                              fontWeight: "bold",
                              color: Settings.colors.darkGrey,
                              fontSize: 17,
                            }}
                          >
                            Contatti
                          </span>
                        </Row>
                        <Row style={{ marginBottom: 15 }}>
                          <Col span={24}>
                            <Typography.Paragraph
                              style={{
                                color: Settings.colors.darkGrey,
                                fontSize: 14,
                              }}
                            >
                              {
                                // TELEFONO
                                this.state.selected_negozio &&
                                this.state.selected_negozio
                                  .telefono !== "" &&
                                this.state.selected_negozio
                                  .telefono !== "+39" && (
                                  <div style={styles.block}>
                                    <Row align="top">
                                      <Col xs={{ span: 3 }} sm={{ span: 2 }}>
                                        <PhoneOutlined style={styles.icon} />
                                      </Col>
                                      <Col
                                        xs={{ span: 21 }}
                                        sm={{ span: 22 }}
                                      >
                                        <span style={styles.info}>
                                          {
                                            this.state.selected_negozio
                                              .telefono
                                          }
                                        </span>
                                      </Col>
                                    </Row>
                                  </div>
                                )
                              }
                              {
                                // EMAIL
                                this.state.selected_negozio &&
                                this.state.selected_negozio.email !== "" && (
                                  <div style={styles.block}>
                                    <Row align="top">
                                      <Col xs={{ span: 3 }} sm={{ span: 2 }}>
                                        <MailOutlined style={styles.icon} />
                                      </Col>
                                      <Col span={20}>
                                        <span style={styles.info}>
                                          {this.state.selected_negozio.email}
                                        </span>
                                      </Col>
                                    </Row>
                                  </div>
                                )
                              }
                            </Typography.Paragraph>
                          </Col>
                        </Row>
                      </>
                    ) : null}

                    {this.state.selected_negozio.orari_apertura && (
                      <>
                        <Row style={{ marginBottom: 5 }}>
                          <span
                            style={{
                              fontWeight: "bold",
                              color: Settings.colors.darkGrey,
                              fontSize: 17,
                            }}
                          >
                            Orari
                          </span>
                        </Row>
                        <Row style={{ marginBottom: 5 }}>
                          <Col span={24}>
                            <span
                              style={{
                                whiteSpace: "pre-wrap",
                                color: Settings.colors.darkGrey,
                                fontSize: 14,
                              }}
                            >
                              {this.state.selected_negozio.orari_apertura}
                            </span>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
              </Modal>
            )}
          </Tabs.TabPane>
        </Tabs>
      </Wrapper>
    );
  }
}

const styles = {
  section: {
    marginBottom: "50px",
  },
  info: {
    color: Settings.colors.darkGrey,
  },
  title: {
    fontSize: "28px",
    fontWeight: "600",
    marginBottom: 0,
    color: Settings.colors.darkGrey,
  },
};

function mapStateToProps(state) {
  return {
    id: state.comuni.id,
    slug: state.comuni.slug,
    nome: state.comuni.nome,
    logo: state.comuni.logo,
    coords: state.comuni.coords,
    auth: state.auth,
    chats: state.chat,
  };
}

export default withRouter(connect(mapStateToProps)(Negozi));
