import React, { Component } from "react";

import { Col, Divider, Image, Input, List, Row, Typography } from "antd";
import placeholder from "../Assets/Background/placeholder.jpg";
import Settings from "../Config/Settings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { _timeDifference } from "../Utility/utility";

class ChatList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chats: [],
      filtered_chats: [],
    };
  }

  componentDidMount() {
    this.setState({ chats: this.props.chats.chats, filtered_chats: this.props.chats.chats })
  }

  componentDidUpdate(prevProps) {
    if (prevProps != this.props) {
      this.setState({ chats: this.props.chats.chats, filtered_chats: this.props.chats.chats })
    }
  }

  _filterChats = (keyword) => {
    const filtered_chats = this.state.chats.filter(chat => chat.chat_title.toLowerCase().includes(keyword.toLowerCase()))
    this.setState({ filtered_chats: filtered_chats })
  }

  render() {
    const { filtered_chats } = this.state
    return (
      <>
        <Col span={24}>
          <Typography.Title level={3} style={styles.title}>
            Chat
          </Typography.Title>
          <Input
            onPressEnter={null}
            placeholder="Cerca tra le chat..."
            allowClear
            size="large"
            onChange={(e) => this._filterChats(e.target.value)}
          />
          <Divider />
          <List
            locale={{ emptyText: "Nessuna Chat" }}
            itemLayout="horizontal"
            dataSource={filtered_chats}
            renderItem={({
              chat_uid,
              chat_title,
              last_message,
              last_update,
              user_sql_id, //Utenza a cui sto scrivendo
              to_read,
            }) => (
              <List.Item
                key={chat_uid}
                style={{
                  cursor: "pointer",
                  paddingLeft: "15px",
                  borderRadius: "4px",
                  borderBottom: 'none',
                  backgroundColor:
                    this.props.match.params.chat_uid == chat_uid
                      ? Settings.colors.lightCyan
                      : null,
                }}
                onClick={() => this.props.history.push(`/chats/${chat_uid}`)}
                extra={
                  to_read ? (
                    <div
                      style={{
                        width: "14px",
                        height: "14px",
                        borderRadius: "14px",
                        margin: "0 14px 14px 14px",
                        backgroundColor: Settings.colors.cyan,
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "14px",
                        height: "14px",
                        margin: "0 14px 14px 14px",
                        backgroundColor: "transparent",
                      }}
                    />
                  )
                }
              >
                <List.Item.Meta
                  avatar={
                    <Image
                      width={66}
                      height={50}
                      style={{ borderRadius: '4px', overflow: 'hidden' }}
                      src={`https://storage.googleapis.com/piattaforma-garzone${process.env.REACT_APP_STAGE === "development" ? '-test' : ''}.appspot.com/negozi/${user_sql_id}/corporate/logo.png`}
                      preview={placeholder}
                      fallback={placeholder}
                    />
                  }
                  title={
                    <Row
                      justify="space-between"
                      gutter={[15, 15]}
                      style={{ margin: 0 }}
                    >
                      <span
                        style={{
                          color: Settings.colors.darkGrey,
                          fontWeight: to_read ? 700 : 500,
                          fontSize: 17,
                        }}
                      >
                        {chat_title.length < 26
                          ? chat_title
                          : `${chat_title.substr(0, 23)}...`}
                      </span>
                    </Row>
                  }
                  description={
                    <Row
                      justify="space-between"
                      gutter={[15, 15]}
                      style={{ margin: 0 }}
                    >
                      <Col style={{ padding: 0 }}>
                        <span
                          style={{
                            color: to_read
                              ? Settings.colors.cyan
                              : Settings.colors.grey,
                            fontWeight: to_read ? 700 : 500,
                            fontSize: 14,
                          }}
                        >
                          {last_message.length < 15
                            ? last_message
                            : `${last_message.substr(0, 12)}...`}
                        </span>
                      </Col>
                      <Col style={{ padding: 0 }}>
                        <span
                          style={{
                            color: Settings.colors.grey,
                            fontSize: 14,
                          }}
                        >
                          {last_update
                            ? _timeDifference(
                              Math.floor(Date.now()),
                              last_update
                            )
                            : null}
                        </span>
                      </Col>
                    </Row>
                  }
                />
              </List.Item>
            )}
          ></List>
        </Col>
      </>
    );
  }
}

const styles = {
  title: {
    color: Settings.colors.darkGrey,
    fontWeight: 700,
  },
};

function mapStateToProps(state) {
  return {
    id: state.comuni.id,
    slug: state.comuni.slug,
    nome: state.comuni.nome,
    logo: state.comuni.logo,
    auth: state.auth,
    chats: state.chat,
  };
}

export default withRouter(connect(mapStateToProps, null)(ChatList));
