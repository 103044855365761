import React from "react";

// import redux
import { connect } from "react-redux";

// ant design components
import { Row, Col } from "antd";

// import settings
import Settings from "../Config/Settings";

// import custom components
import Wrapper from "../Components/Wrapper";
import Panel from "../Components/Panel";
import CardPromozione from "../Components/CardPromozione";

// import controllers functions
import { getPromozioniComune } from "../Controllers/Promozioni";
import LoadingBlock from "../Components/LoadingBlock";

class Promozioni extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading_promozioni: true,
      promozioni: [],
    };
  }

  componentDidMount = () => {
    this._getPromozioni();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.slug !== this.props.slug) {
      this._getPromozioni();
    }
  }

  _getPromozioni = async () => {
    this.setState({ loading_promozioni: true });
    // slug from URL
    const { slug } = this.props.match.params;
    if (slug === this.props.slug) {
      try {
        const res = await getPromozioniComune(this.props.slug);
        let promozioni = res.data;
        this.setState({ promozioni: promozioni, loading_promozioni: false });
      } catch (e) {
        this.setState({ loading_promozioni: false });
        console.log(e);
      }
    }
  };

  render() {
    return (
      <Wrapper>
        {/* Sezione ricerca negozi */}
        <Row style={styles.section}>
          <Col span={24}>
            <Panel
              title="Promozioni"
              background={Settings.colors.cyan}
              borderRadius="none"
              fontSizeTitle="48px"
              titleColor={Settings.colors.white}
            >
              <div style={{ padding: "0 15px", marginTop: "-10px" }}>
                <Row>
                  <Col span={24} style={{ color: Settings.colors.white }}>
                    Sfoglia tutte le promozioni del{" "}
                    {this.props.nome && this.props.nome}
                  </Col>
                </Row>
              </div>
            </Panel>
          </Col>
        </Row>

        {/* Lista Promozioni */}

        {this.state.loading_promozioni ? (
          // <Row gutter={[40, 10]} justify="space-between">
          //   <Col span={6}>
          //     <SkeletonPromozioni />
          //   </Col>
          //   <Col span={6}>
          //     <SkeletonPromozioni />
          //   </Col>
          //   <Col span={6}>
          //     <SkeletonPromozioni />
          //   </Col>
          //   <Col span={6}>
          //     <SkeletonPromozioni />
          //   </Col>

          //   <Col span={6}>
          //     <SkeletonPromozioni />
          //   </Col>
          //   <Col span={6}>
          //     <SkeletonPromozioni />
          //   </Col>
          //   <Col span={6}>
          //     <SkeletonPromozioni />
          //   </Col>
          //   <Col span={6}>
          //     <SkeletonPromozioni />
          //   </Col>
          // </Row>
          <LoadingBlock height="300px" />
        ) : this.state.promozioni.length > 0 ? (
          <Row gutter={[20, 40]}>
            {this.state.promozioni.map((item, index) => (
              <Col key={index} xs={24} md={6} lg={6} xl={6}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    Settings.base_url.utente +
                    "/comuni/" +
                    this.props.slug +
                    "/promozioni/" +
                    item.id
                  }
                >
                  <CardPromozione
                    key={index}
                    nome_negozio={item.nome_negozio}
                    immagine={item.immagine}
                    titolo={item.titolo}
                    data_scadenza={item.data_scadenza}
                  />
                </a>
              </Col>
            ))}
          </Row>
        ) : (
          <Col span={24}>
            <p>Nessuna promozione presente.</p>
          </Col>
        )}
      </Wrapper>
    );
  }
}

const styles = {
  section: {
    marginBottom: "50px",
  },
  title: {
    fontSize: "28px",
    fontWeight: "600",
    marginBottom: 0,
    color: Settings.colors.darkGrey,
  },
};

function mapStateToProps(state) {
  return {
    id: state.comuni.id,
    slug: state.comuni.slug,
    nome: state.comuni.nome,
    logo: state.comuni.logo,
  };
}

export default connect(mapStateToProps)(Promozioni);
