import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { successLogin } from "../Actions/AuthActions";
import Header from "../Components/Header";
import SideMenu from "../Components/SideMenu";
import Settings from "../Config/Settings";
import { getAppuntamenti } from "../Controllers/Appuntamenti";
import {
  Button,
  Col,
  ConfigProvider,
  Empty,
  List,
  notification,
  Row,
  Typography,
} from "antd";
import {
  getNegozio,
  getNegozioFirebaseInfo,
  getNegozioId,
} from "../Controllers/Negozi";
import LoadingBlock from "../Components/LoadingBlock";
import { Link } from "react-router-dom";
import { generaIdNegozioAppuntamenti } from "../Controllers/Utente";

class Agenda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible_menu: false,

      appuntamenti: [],
    };
  }

  _loader = async () => {
    try {
      this.setState({ loading: true });
      const { data } = await getAppuntamenti();
      console.log(data);
      let appuntamenti = [];
      let prevId = 0;
      let currentNegozio = null;
      for (let i = 0; i < data.length; i++) {
        //const info = await getNegozioFirebaseInfo(data[i].uid_negozio_firebase);

        if (prevId !== data[i].idNegozio) {
          currentNegozio = await getNegozio(data[i].id_negozio);
          prevId = currentNegozio.id;
        }
        const appuntamento = {
          titolo: data[i].titolo,
          inizio: data[i].inizio,
          fine: data[i].fine,
          descrizione: data[i].descrizione,
          idNegozio: currentNegozio.data.id,
          nomeNegozio: currentNegozio.data.nome_negozio,
          slugNegozio: currentNegozio.data.slug,
        };
        appuntamenti.push(appuntamento);
      }
      this.setState({ appuntamenti: appuntamenti });
    } catch (error) {
      console.log(error);
      notification.destroy();
      notification.error({
        message: "Errore!",
        description: "Contatta l'assistenza e descrivi il problema.",
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  _closeMenu = () => {
    this.setState({ visible_menu: false });
  };
  _openMenu = () => {
    this.setState({ visible_menu: true });
  };

  _getNegozio = async (uid) => {
    const { data } = await getNegozioFirebaseInfo(uid);
    const negozio = await getNegozioId(data.phoneNumber);
    return negozio.data;
  };
  getNegozio = (uid) => {
    return this._getNegozio(uid);
  };

  componentDidMount() {
    if (this.state.appuntamenti.length === 0) this._loader();
  }

  render() {
    const { visible_menu, loading, appuntamenti } = this.state;
    const { auth, slug } = this.props;
    return (
      <>
        <Helmet>
          <title>
            Garzone Store - {`${auth.user.nome} ${auth.user.cognome}`}
          </title>
        </Helmet>
        <Header account onMenuClick={this._openMenu} />
        <SideMenu
          slug={slug}
          visible_menu={visible_menu}
          _closeMenu={this._closeMenu}
        />
        <div style={styles.wrapper}>
          <Row justify="start" align="top" gutter={[15, 15]}>
            <Col span={24}>
              <Typography.Title level={3} style={styles.title}>
                Appuntamenti
              </Typography.Title>
            </Col>
          </Row>
          {loading ? (
            <>
              <LoadingBlock height="300px" />
            </>
          ) : (
            <Row justify="start" align="top" gutter={[15, 15]}>
              <Col span={24}>
                <ConfigProvider
                  renderEmpty={() => (
                    <Empty
                      description={
                        <span>
                          Nessun appuntamento, richiedine uno sulla pagina di un
                          negozio
                        </span>
                      }
                    />
                  )}
                >
                  <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={appuntamenti}
                    renderItem={(item) => {
                      return (
                        <List.Item
                          key={item.titolo}
                          extra={
                            <Link
                              to={`/comuni/${this.props.slug}/negozi/${item.slugNegozio}`}
                            >
                              <img
                                width={272}
                                alt="logo"
                                src={`https://storage.googleapis.com/piattaforma-garzone${
                                  process.env.REACT_APP_STAGE === "development"
                                    ? "-test"
                                    : ""
                                }.appspot.com/negozi/${
                                  item.idNegozio
                                }/corporate/logo.png`}
                              />
                            </Link>
                          }
                        >
                          <List.Item.Meta
                            title={
                              <>
                                <Row>
                                  <Col span={24}>
                                    <span
                                      style={{
                                        color: Settings.colors.darkGrey,
                                        fontWeight: 700,
                                      }}
                                    >
                                      {item.titolo}
                                    </span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={24}>
                                    <span
                                      style={{
                                        color: Settings.colors.darkGrey,
                                      }}
                                    >
                                      Presso{" "}
                                      <Link
                                        to={`/comuni/${this.props.slug}/negozi/${item.slugNegozio}`}
                                      >
                                        {item.nomeNegozio}
                                      </Link>
                                    </span>
                                  </Col>
                                </Row>
                              </>
                            }
                            description={`Inizio Appuntamento: ${item.inizio}`}
                          />
                          {item.descrizione}
                        </List.Item>
                      );
                    }}
                  />
                </ConfigProvider>
              </Col>
            </Row>
          )}
        </div>
      </>
    );
  }
}

const styles = {
  wrapper: {
    padding: "25px 15px",
  },
  menu_item: {
    borderBottom: "1px solid " + Settings.colors.lightGrey,
    padding: "10px 20px",
    color: Settings.colors.darkGrey,
  },
  menu_item_title: {
    fontSize: "18px",
    display: "block",
  },
  menu_item_subtitle: {
    fontSize: "14px",
    color: Settings.colors.red,
    display: "block",
  },
  title: {
    color: Settings.colors.darkGrey,
  },
};

function mapStateToProps(state) {
  return {
    id: state.comuni.id,
    slug: state.comuni.slug,
    nome: state.comuni.nome,
    logo: state.comuni.logo,
    auth: state.auth,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    successLogin: (user) => dispatch(successLogin(user)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Agenda);
