import React from "react";

// Custom Components
import CustomResult from "../Components/CustomResult";

// Libraries
import { applyActionCode, checkCode, userType } from "../Controllers/Auth";

class ModificaMail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "",
      code: "",
      email: "",
      tipo: "",
    };
  }

  _goToPanel = async () => {
    if (this.state.tipo == "cliente") {
      return window.open("https://garzone.store/accedi", "_self");
    } else if (this.state.tipo == "negozio") {
      return this.props.history.push("/accedi");
    } else if (this.state.tipo == "comune") {
      return window.open("https://comune.garzone.store/accedi", "_self");
    }
  };

  componentDidMount = async () => {
    let {
      match: { params },
    } = this.props;
    if (!params.mode) {
      this.props.history.push("/");
    } else {
      let dividedParams = params.mode.split("&");
      let code = "";
      dividedParams.forEach((item) => {
        if (item.substring(0, 7) == "oobCode") {
          code = item.replace("oobCode=", "");
        }
      });
      try {
        let mail = await checkCode(code);
        let checkResult = await applyActionCode(code);
        let user = await userType(mail);
        let tipo = user.data.tipologia;
        this.setState({ status: "valid", code, tipo });
      } catch (error) {
        console.log("Error:", error);
        if (error.code == "auth/expired-action-code") {
          this.setState({ status: "expired" });
        } else if (error.code == "auth/invalid-action-code") {
          this.setState({ status: "invalid" });
        } else if (error.code == "auth/user-disabled") {
          this.setState({ status: "banned" });
        } else if (error.code == "auth/user-not-found") {
          this.setState({ status: "not-found" });
        }
      }
    }
  };

  render() {
    return (
      <div className="login-background">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "15px 0",
          }}
        >
          <img
            src={require("../Assets/logo-white.png")}
            style={{ height: 100, marginTop: 35, marginBottom: 39 }}
            alt="logo"
          />
        </div>
        {this.state.status == "valid" ? (
          <CustomResult
            icon="success"
            title="Email ripristinata"
            subtitle="L'indirizzo E-Mail è stato ripristinato con quello precedente. Per sicurezza, ti suggeriamo di cambiare anche la password."
            button={{ title: "Accedi", route: () => this._goToPanel() }}
          />
        ) : this.state.status == "expired" ? (
          <CustomResult
            icon="error"
            title="Link scaduto"
            subtitle={
              <span>
                Il link che hai aperto è scaduto e non è più utilizzabile.
              </span>
            }
            // button={{ title: 'Accedi', route: () => this._goToPanel() }}
          />
        ) : this.state.status == "invalid" ? (
          <CustomResult
            icon="error"
            title="Link non valido"
            subtitle={
              <span>
                Il link che hai aperto non è valido e non può essere usato.
              </span>
            }
            // button={{ title: 'Accedi', route: () => this._goToPanel() }}
          />
        ) : this.state.status == "banned" ? (
          <CustomResult
            icon="info"
            title="Accesso negato"
            subtitle={
              <span>
                Il tuo utente è stato disattivato, pertanto non puoi accedere a
                Garzone.
              </span>
            }
          />
        ) : this.state.status == "not-found" ? (
          <CustomResult
            icon="error"
            title="Non trovato"
            subtitle={<span>L'utente non è stato trovato</span>}
            button={{
              title: "Torna alla home",
              route: () => this.props.history.push("/"),
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default ModificaMail;
