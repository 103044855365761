import React from "react";
import { Helmet } from "react-helmet";
// Ant design component
import { Select, Row, Col, Button, Carousel, Image, Skeleton } from "antd";

import { Link } from "react-router-dom";

// import custom components
import Panel from "../Components/Panel";
import Footer from "../Components/Footer";
import LoadingBlock from "../Components/LoadingBlock";

// import Settings
import Settings from "../Config/Settings";
import Header from "../Components/Header";

// Controllers functions
import { getComuni } from "../Controllers/Comuni";

const bg = require("../Assets/Background/garzone-homepage-bg.jpg");
const appleLogo = require("../Assets/AppStoreLogo.png");
const androidLogo = require("../Assets/PlayStoreLogo.png");

const OneSignal = window.OneSignal || [];

async function getSubscriptionState() {
  var isPushEnabled = await OneSignal.isPushNotificationsEnabled();
  var isOptedOut = await OneSignal.isOptedOut();
  return {
    isPushEnabled: isPushEnabled,
    isOptedOut: isOptedOut,
  };
}
class Homepage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading_negozi: true,
      notification_modal_visible: true,
      comuni: [],
      partecipants: []
    };
  }
  skeletonNumber = [1, 2, 3, 4];

  componentDidMount() {
    this._getComuni();
    this._initOnesignal();
  }

  _initOnesignal = () => {
    const self = this;
    var enabled = false;
    OneSignal.push(function () {
      getSubscriptionState().then(function (state) {
        if (state.isPushEnabled) {
          enabled = true;
          self.setState({ enabled: enabled });
        }
      });
    });
    return enabled;
  };

  _getComuni = async () => {
    // ottengo tutti i comuni abilitati in garzone e li salvo nello stato
    try {
      const comuni = await getComuni();
      let partecipants = [];
      comuni.data.map((item, index) =>
        JSON.parse(item.comuni_partecipanti).map(
          (partecipant, indexPart) => partecipants.push({ slug: item.slug, partecipant: partecipant })
        )
      )
      partecipants = partecipants.sort((a, b) => (a.partecipant.toLowerCase() > b.partecipant.toLowerCase()) ? 1 : -1)
      this.setState({ comuni: comuni.data, loading_negozi: false, partecipants: partecipants });
    } catch (e) {
      this.setState({ loading_negozi: false });
      alert(e);
    }
  };
  _getSkeleton = () => {
    let skeletons = [];

    for (let i = 0; i < 4; i++) {
      skeletons.push(
        <div key={i} style={{ marginRight: "10px" }}>
          <Skeleton active paragraph={{ rows: 5, width: "50px" }} />
        </div>
      );
    }
    return skeletons;
  };

  _onChangeComune = (slug) => {
    this.props.history.push("/comuni/" + slug);
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Garzone Store</title>
        </Helmet>
        {/* Main block */}
        <Header homepage />
        <div style={{ ...styles.main_block, backgroundImage: `url( ${bg} )` }}>
          {/* Mask */}
          <div style={styles.mask}></div>
          {/* Navbar */}
          {/* Modal notification */}

          {/* Content */}
          <div style={{ padding: "70px 20px" }}>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <h1 style={styles.title}>
                  Benvenuto nel marketplace della tua città!
                </h1>
                <h3 style={styles.subtitle}>
                  In Garzone troverai l’offerta di tantissimi negozi di vicinato
                  con cui poter interagire direttamente tramite una chat per
                  ricevere comodamente a casa tua tutto quello di cui hai
                  bisogno.
                </h3>
                <h2 style={styles.cta_text}>Cerca il tuo marketplace</h2>
                <Select
                  disabled={this.state.loading_negozi}
                  showSearch
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Scegli città/comune"
                  optionFilterProp="children"
                  onChange={this._onChangeComune}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.partecipants.map(({ slug, partecipant }) => (
                    <Select.Option
                      key={partecipant}
                      value={slug}
                    >
                      {partecipant}
                    </Select.Option>
                  ))}
                </Select>
                <h3 style={styles.app_text}>Garzone è anche APP!</h3>

                <a
                  href="https://apps.apple.com/it/app/garzone/id1540104414"
                  style={{ marginRight: "20px" }}
                >
                  <Image width="180px" src={appleLogo} />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.garzoneapputente"
                  style={{ marginTop: "20px" }}
                >
                  <Image width="180px" src={androidLogo}></Image>
                </a>
              </Col>
            </Row>
          </div>
        </div>

        {/* Comuni attivi carousel */}
        <div style={{ padding: "30px 20px" }}>
          <h3
            style={{
              color: Settings.colors.darkGrey,
              fontWeight: "600",
              marginBottom: "20px",
            }}
          >
            Comuni che hanno adottato Garzone
          </h3>
          <Row>
            <Col span={24}>
              {this.state.loading_negozi ? (
                // <Row gutter={15}>
                //   <Col span={6}>
                //     <Skeleton loading={true} active></Skeleton>
                //   </Col>
                //   <Col span={6}>
                //     <Skeleton loading={true} active></Skeleton>
                //   </Col>
                //   <Col span={6}>
                //     <Skeleton loading={true} active></Skeleton>
                //   </Col>
                //   <Col span={6}>
                //     <Skeleton loading={true} active></Skeleton>
                //   </Col>
                // </Row>
                <LoadingBlock height="300px" />
              ) : (
                <Carousel
                  style={styles.section}
                  autoplay
                  infinite={false}
                  slidesToShow={4}
                  slidesToScroll={1}
                  swipeToSlide
                  dots={false}
                  responsive={[
                    {
                      breakpoint: 576,
                      settings: {
                        slidesToShow: 1,
                      },
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 2,
                      },
                    },
                    {
                      breakpoint: 992,
                      settings: {
                        slidesToShow: 3,
                      },
                    },
                  ]}
                >
                  {this.state.comuni.length > 0 &&
                    this.state.comuni.map((item, index) => (
                      <div key={index}>
                        <Link to={"/comuni/" + item.slug}>
                          <img
                            src={item.logo}
                            style={{ height: "76px", margin: "0 auto" }}
                          />
                          <h4 style={{ textAlign: "center", margin: 0 }}>
                            {item.nome}
                          </h4>
                        </Link>
                      </div>
                    ))}
                </Carousel>
              )}
            </Col>
          </Row>
        </div>

        <div style={{ padding: "15px" }}>
          <Row justify="space-between" gutter={[15, 15]}>
            {/* Sei un negozio block */}
            <Col md={12} xs={24} style={{ padding: "30px" }}>
              <Panel
                title="Sei un negozio?"
                background={Settings.colors.white}
                style={{
                  borderLeft: `1px solid ${Settings.colors.lightGrey}`,
                  borderRight: `1px solid ${Settings.colors.lightGrey}`,
                }}
              >
                <div style={{ padding: "0 15px" }}>
                  <Row>
                    <Col span={24}>
                      <p style={{ fontSize: "20px" }}>
                        Apri il tuo negozio virtuale ed entra in contatto con
                        tutti i cittadini della tua città.
                        <br />
                        L’iscrizione è GRATUITA!
                      </p>
                    </Col>
                  </Row>
                  <Row gutter={[15, 15]}>
                    <Col>
                      <span style={styles.panels_grey}>
                        Non sei ancora iscritto?
                      </span>
                      <br />
                      <Button
                        size="large"
                        type="primary"
                        href={Settings.base_url.negozio}
                        target="_blank"
                      >
                        Registrati
                      </Button>
                    </Col>
                    <Col>
                      <span style={styles.panels_grey}>Sei già iscritto?</span>
                      <br />
                      <Button
                        size="large"
                        type="secondary"
                        href={Settings.base_url.negozio + "/accedi"}
                        target="_blank"
                      >
                        Accedi al tuo pannello
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Panel>
            </Col>

            {/* Sei un comune block */}
            <Col md={12} xs={24} style={{ padding: "30px" }}>
              <Panel
                title="Sei un comune?"
                background={Settings.colors.white}
                style={{
                  borderLeft: `1px solid ${Settings.colors.lightGrey}`,
                  borderRight: `1px solid ${Settings.colors.lightGrey}`,
                }}
              >
                <div style={{ padding: "0 15px" }}>
                  <Row>
                    <Col span={24}>
                      <p style={{ fontSize: "20px" }}>
                        Realizza il marketplace della tua città.
                        <br />
                        Fai ripartire il tuo business del commercio locale
                        sfruttando le potenzialità del mondo digitale.
                      </p>
                    </Col>
                  </Row>
                  <Row gutter={[15, 15]}>
                    <Col>
                      <span style={styles.panels_grey}>
                        Vuoi saperne di più?
                      </span>
                      <br />
                      <Link to="/per-i-comuni">
                        <Button size="large" type="primary">
                          Richiedi informazioni
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Panel>
            </Col>

            <Col span={24} style={{ marginTop: "65px" }}>
              <Panel
                title=""
                background={Settings.colors.cyan}
                borderRadius="none"
                titleColor={Settings.colors.white}
                fontSizeTitle="36px"
              >
                <div style={{ padding: "0 15px" }}>
                  <span
                    style={{ color: Settings.colors.white, fontSize: "36px" }}
                  >
                    <strong>
                      Il miglior progetto per il rilancio del commercio locale
                    </strong>
                  </span>
                </div>
              </Panel>
            </Col>
            <Col md={16} sm={24}>
              <span style={{ fontSize: "17px" }}>
                Garzone ha ottenuto un importante riconoscimento nazionale al
                <a href="https://www.puntoimpresadigitale.camcom.it/paginainterna/premio-top-of-the-pid">
                  {" "}
                  <strong>
                    Premio Top of the Pid - Restart promosso dalle Camere di
                    Commercio italiane.
                  </strong>
                </a>
              </span>
              <br />
              <br />
              <span style={{ fontSize: "17px" }}>
                L’iniziativa premia i{" "}
                <strong> migliori progetti innovativi</strong> che possono
                agevolare il rilancio dell’economia per uscire dalla profonda
                crisi provocata della diffusione del Covid-19.
              </span>
              <br />
              <br />
              <span style={{ fontSize: "17px" }}>
                <strong>
                  Garzone ha ricevuto per questo motivo una menzione speciale
                  come
                  <span style={{ color: Settings.colors.cyan }}>
                    {" "}
                    miglior modello di business 4.0
                  </span>{" "}
                  per il rilancio del commercio locale.
                </strong>
              </span>
              <br />
            </Col>
            <Col md={8} sm={24}>
              <Row justify="center">
                <img
                  style={{ width: "200px" }}
                  src={require("../Assets/Top_of_the_pid.png")}
                />
                <br />
                <span style={{ fontSize: "24px", textAlign: "center" }}>
                  <strong>
                    Premio Nazionale delle Camere di Commercio Nazionali
                  </strong>
                </span>
              </Row>
            </Col>
            {/* <Col span={24} style={{ marginTop: "20px" }}>
              <Panel
                title=""
                background={Settings.colors.cyan}
                borderRadius="none"
                titleColor={Settings.colors.white}
                fontSizeTitle="36px"
              >
                <Row justify="center">
                  <span
                    style={{ color: Settings.colors.white, fontSize: "36px" }}
                  >
                    <strong>Parlano di noi</strong>
                  </span>
                </Row>
              </Panel>
            </Col> */}
          </Row>
        </div>
        <Footer />
      </>
    );
  }
}

const styles = {
  panels_grey: {
    fontSize: "14px",
    color: Settings.colors.grey,
  },
  main_block: {
    position: "relative",
    width: "100%",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  mask: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(81,99,117,0.7)",
  },
  navbar: {
    height: "48px",
    borderBottom: "1px solid #647A90",
    padding: "6px 20px",
  },
  logoGarzone: {
    height: "35px",
  },
  title: {
    fontSize: "48px",
    color: Settings.colors.white,
    fontWeight: "bold",
    lineHeight: "60px",
  },
  subtitle: {
    color: Settings.colors.white,
    fontWeight: "600",
  },
  cta_text: {
    marginTop: "25px",
    color: Settings.colors.white,
    fontWeight: "bold",
    fontSize: "28px",
  },
  app_text: {
    marginTop: "60px",
    fontWeight: "bold",
    fontSize: "28px",
    color: Settings.colors.white,
  },
};

export default Homepage;
