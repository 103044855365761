import React from "react";

// import ant design components
import { Row, Col, Statistic } from "antd";

// import custom components
import Chip from "../Components/Chip";
import TextCollapse from "../Components/TextCollapse";

// import settings
import Settings from "../Config/Settings";

//import placeholder
import placeholder from "../Assets/Background/placeholder.jpg";
class CardServizio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div style={{ borderBottom: "1px solid #eee", paddingTop: "20px" }}>
        <Row gutter={[20, 20]}>
          {this.props.immagine ? (
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 9 }}
              xl={{ span: 7 }}
              xxl={{ span: 6 }}
            >
              <img
                src={this.props.immagine}
                style={{ width: "100%", borderRadius: "4px" }}
              />
            </Col>
          ) : (
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 9 }}
              xl={{ span: 7 }}
              xxl={{ span: 6 }}
            >
              <img
                src={placeholder}
                style={{ width: "100%", borderRadius: "4px" }}
              />
            </Col>
          )}
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 15 }}
            xl={{ span: 17 }}
            xxl={{ span: 18 }}
          >
            <Chip title={this.props.categoria || "Nessuna categoria"} />

            <h2 style={styles.title}>{this.props.nome}</h2>
            {this.props.descrizione !== "" && (
              <TextCollapse cut={80} style={styles.description}>
                {this.props.descrizione}
              </TextCollapse>
            )}
            <Row align="middle" gutter={20}>
              <Col>
                {this.props.prezzo != 0 && (
                  <Statistic
                    valueStyle={styles.prezzo}
                    suffix={
                      <span style={{ color: Settings.colors.darkGrey }}>€</span>
                    }
                    value={
                      this.props.prezzo_scontato != null
                        ? this.props.prezzo_scontato
                        : this.props.prezzo
                    }
                    precision={2}
                    decimalSeparator=","
                  />
                )}
              </Col>
              {this.props.prezzo_scontato != null && (
                <Col>
                  <Statistic
                    valueStyle={styles.prezzo_barrato}
                    suffix={
                      <span style={{ color: Settings.colors.grey }}>€</span>
                    }
                    value={this.props.prezzo != 0 ? this.props.prezzo : ""}
                    precision={2}
                    decimalSeparator=","
                  />
                  <div style={styles.line}></div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CardServizio;

const styles = {
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: 0,
    marginTop: "5px",
    color: Settings.colors.darkGrey,
  },
  description: {
    display: "block",
    fontSize: "16px",
    color: Settings.colors.darkGrey,
    marginTop: "5px",
    marginBottom: 0,
  },
  prezzo: {
    fontSize: "28px",
    fontWeight: "600",
    color: Settings.colors.darkGrey,
    marginTop: "5px",
  },
  prezzo_barrato: {
    fontSize: "18px",
    color: Settings.colors.grey,
    marginTop: "12px",
    textDecoration: "line-through",
    position: "relative",
  },
  line: {
    position: "absolute",
    bottom: "14px",
    left: 0,
    background: Settings.colors.grey,
    height: "1px",
    width: "100%",
  },
};
