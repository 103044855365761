import React from "react";

import moment from "moment";
import "moment/locale/it";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Ant design components
import { Row, Col, Tabs, Select, Button, Divider, Alert } from "antd";

// ant design icons
import {
  InstagramOutlined,
  WhatsAppOutlined,
  PushpinOutlined,
  PhoneOutlined,
  MailOutlined,
  LinkOutlined,
  FacebookOutlined,
  ShoppingCartOutlined,
  FormOutlined,
} from "@ant-design/icons";

// Custom components
import Wrapper from "../Components/Wrapper";
import CardProdotto from "../Components/CardProdotto";
import CardServizio from "../Components/CardServizio";
import CardPromozione from "../Components/CardPromozione";
import LoadingBlock from "../Components/LoadingBlock";

// import controllers functions
import {
  getAppuntamentiNegozio,
  getNegozio,
  getNegozioUid,
  getProdottiNegozio,
  getServiziNegozio,
} from "../Controllers/Negozi";
import { getPromozioniNegozio } from "../Controllers/Promozioni";

// import settings
import Settings from "../Config/Settings";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import ModaleLoginUtente from "../Components/ModaleLoginUtente";
import ModaleCarrello from "../Components/ModaleCarrello";
import ModalRichiestaPreventivo from "../Components/ModalRichiestaPreventivo";
import ModalRichiestaAppuntamento from "../Components/ModalRichiestaAppuntamento";
import ModalChatConNegozio from "../Components/ModalChatConNegozio";
import { _eventStyleGetter } from "../Utility/utility";
import Loading from "../Components/Loading";

const { TabPane } = Tabs;
//TODO: testo descrivi il tuo appuntamento...
//
class Negozio extends React.Component {
  _localizer = momentLocalizer(moment);

  constructor(props) {
    super(props);
    this.state = {
      modaleCarrelloVisible: false,
      loading_negozio: true,
      loading_prodotti: true,
      loading_servizi: true,
      loading_promozioni: true,
      contattaNegozioVisible: false,
      sendMessageLoading: false,
      condividiVisible: false,
      loginVisible: false,
      buttonDisabled: false,
      ordiniAbilitati: true,
      loading_appuntamenti: true,
      uid_negozio: "",
      negozio: {
        // ESEMPIO DATI NEGOZIO
        // nome_negozio: 'nome del negozio',
        // categoria: 'Categoria',
        // logo: 'https://images2-corrieredelveneto.corriereobjects.it/methode_image/2020/05/16/Treviso%20Belluno/Foto/15034976.jpg?v=202005161513',
        // indirizzo: 'Via Turati',
        // civico: '26',
        // cap: '20121',
        // citta: 'Milano',
        // provincia: 'Milano',
        // telefono: '021234567',
        // email: 'test@test.com',
        // sito_web: 'www.ilmiosito.it',
        // facebook: 'https://facebook.com',
        // instagram: 'https://instagram.com',
        // whatsapp: 'https://whatsapp.com',
        // bancomat: 1,
        // carta: 1,
        // contante: 1,
        // altri_metodi: '',
        // orari_apertura: 'test',
        // descrizione: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
        // categorie_prodotti: [],
        // categorie_servizi: []
      },
      prodotti: [],
      servizi: [],
      promozioni: [],
      appuntamenti: [],
      filtered_prodotti: [],
      filtered_servizi: [],
      chatAbilitata: false,
      carrello: [],
      totale: 0.0,

      richiediPreventivoVisible: false,

      indirizziVisible: false,
      aggiungi_loading: false,
      act_indirizzi: this.props.auth.user ? this.props.auth.user.indirizzi : [],
      indirizzi: [],
      errorIndirizzo: false,
      indirizzoInserito: false,
    };
  }

  _closeCarrelloModal = () => {
    this.setState({ modaleCarrelloVisible: false });
    this.calcolaTotale();
  };
  _closeLoginModal = (e) => {
    this.setState({ loginVisible: false });
  };
  _changeLoginState = () => {
    this.setState({ logged: true });
  };
  _addProduct = (id, prezzo) => {
    let carrello = this.state.carrello;
    let totale = this.state.totale;
    carrello.push(id);
    this.setState({
      carrello: carrello,
      totale: totale + prezzo,
    });
  };

  componentDidMount() {
    this._getInfoNegozio();
    this.setState({
      indirizzi: this.props.auth.user ? this.props.auth.user.indirizzi : [],
    });
    console.log(this.state);
    console.log(this.state.negozio);
  }

  calcolaTotale = () => {
    let totale = 0.0;
    for (let i = 0; i < this.state.carrello.length; i++) {
      totale += this.getProductPrice(this.state.carrello[i]);
    }
    this.setState({ totale: totale });
  };
  findProduct = (id) => {
    for (let i = 0; i < this.state.prodotti.length; i++) {
      if (this.state.prodotti[i].id === id) {
        return this.state.prodotti[i];
      }
    }
  };
  getProductName = (productId) => {
    if (productId) {
      const prod = this.findProduct(productId);
      return prod.nome;
    }
  };
  getProductPrice = (productId) => {
    if (productId) {
      const prod = this.findProduct(productId);
      return prod.prezzo_scontato !== 0 && prod.prezzo_scontato !== null
        ? prod.prezzo_scontato
        : prod.prezzo;
    }
  };

  _getInfoNegozio = async () => {
    this.setState({ loading_negozio: true, loading_appuntamenti: true });
    // get "id" negozio from url
    //if (this.state.negozio === {})
    const { slugNegozio } = this.props.match.params;
    try {
      let negozio = this.state.negozio;
      let negozioDownloaded = false;
      if (Object.entries(this.state.negozio).length === 0) {
        negozio = await getNegozio(slugNegozio, slugNegozio);
        negozioDownloaded = true;
      }
      let appuntamenti;
      let appuntamentiDownloaded = false;
      if (this.state.appuntamenti.length === 0) {
        appuntamenti = await getAppuntamentiNegozio(
          negozioDownloaded ? negozio.data.id : negozio.id
        );
        appuntamentiDownloaded = true;
      }

      this.setState({
        chatAbilitata: negozioDownloaded ? negozio.data.chat : negozio.chat,
        ordiniAbilitati: negozioDownloaded
          ? negozio.data.modulo_ordini
          : negozio.modulo_ordini,
        prenotazioniAbilitate: negozioDownloaded
          ? negozio.data.modulo_prenotazioni
          : negozio.modulo_prenotazioni,
      })
      this.setState({
        negozio: negozioDownloaded ? negozio.data : negozio,
        loading_negozio: false,
        loading_appuntamenti: false,
        appuntamenti: appuntamentiDownloaded
          ? appuntamenti.data.map(({ inizio, fine }) => ({
            start: moment(inizio, "DD/MM/YYYY HH:mm").toDate(),
            end: moment(fine, "DD/MM/YYYY HH:mm").toDate(),
            title: "",
          }))
          : appuntamenti.map(({ inizio, fine }) => ({
            start: moment(inizio, "DD/MM/YYYY HH:mm").toDate(),
            end: moment(fine, "DD/MM/YYYY HH:mm").toDate(),
            title: "",
          })),
      });
      this._getProdottiNegozio();
      this._getServiziNegozio();
      this._getPromozioniNegozio();

    } catch (e) {
      this.setState({ loading_negozio: false });
      console.log(e);
    }
  };

  _getProdottiNegozio = async () => {
    this.setState({ loading_prodotti: true });
    try {
      if (this.state.prodotti.length === 0) {
        const prodotti = await getProdottiNegozio(this.state.negozio.id);
        console.log(prodotti)
        this.setState({
          prodotti: prodotti.data,
          filtered_prodotti: prodotti.data,
          loading_prodotti: false,
        });
      } else {
        this.setState({ loading_prodotti: false });
      }
    } catch (e) {
      this.setState({ loading_prodotti: false });
      console.log(e);
    }
  };

  _getServiziNegozio = async () => {
    console.log("Servizi");
    this.setState({ loading_servizi: true });
    // get "id" negozio from url
    try {
      if (this.state.servizi.length === 0) {
        const servizi = await getServiziNegozio(this.state.negozio.id);
        this.setState({
          servizi: servizi.data,
          filtered_servizi: servizi.data,
          loading_servizi: false,
        });
      } else {
        this.setState({ loading_servizi: false });
      }
    } catch (e) {
      this.setState({ loading_servizi: false });
      console.log(e);
    }
  };

  _getPromozioniNegozio = async () => {
    this.setState({ loading_promozioni: true });
    // get "id" negozio from url
    try {
      if (this.state.promozioni.length === 0) {
        const promozioni = await getPromozioniNegozio(this.state.negozio.id);
        this.setState({
          promozioni: promozioni.data,
          loading_promozioni: false,
        });
      } else {
        this.setState({ loading_promozioni: false });
      }
    } catch (e) {
      this.setState({ loading_promozioni: false });
      console.log(e);
    }
  };

  _filterProdotti = (categoria) => {
    if (categoria === "") {
      this.setState({ filtered_prodotti: this.state.prodotti });
    } else {
      const results = this.state.prodotti.filter(
        (item) => item.categoria === categoria
      );
      this.setState({ filtered_prodotti: results });
    }
  };

  _filterServizi = (categoria) => {
    if (categoria === "") {
      this.setState({ filtered_servizi: this.state.servizi });
    } else {
      const results = this.state.servizi.filter(
        (item) => item.categoria === categoria
      );
      this.setState({ filtered_servizi: results });
    }
  };
  svuotaCarrello = () => {
    this.setState({ carrello: [], totale: 0.0 });
  };
  openModalLogin = () => {
    this.setState({ loginVisible: true });
  };
  render() {
    const {
      loading_negozio,
      loading_prodotti,
      loading_servizi,
      loading_appuntamenti,
      negozio,
      prodotti,
      servizi,
      promozioni,
      filtered_prodotti,
      filtered_servizi,
    } = this.state;
    const { slug } = this.props.match.params;
    return (
      <>
        <Wrapper>
          {loading_negozio ? (
            <Loading loading opaque />
          ) : (
            <>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Garzone Store - {negozio.nome_negozio}</title>
                <meta
                  name="description"
                  content={"Pagina negozio " + negozio.nome_negozio}
                ></meta>
              </Helmet>
              <ModaleCarrello
                carta={this.state.negozio.pagamenti_online}
                contante={this.state.negozio.pagamenti_offline}
                dettagliConsegna={this.state.negozio.dettagli_consegna}
                consegna={this.state.negozio.consegna}
                ritiro={this.state.negozio.ritiro}
                idNegozio={this.state.negozio.id}
                visible={this.state.modaleCarrelloVisible}
                nomeNegozio={negozio.nome_negozio}
                prodotti={this.state.prodotti}
                carrello={this.state.carrello}
                callbackClose={this._closeCarrelloModal}
                svuotaCarrello={this.svuotaCarrello}
              />
              <Row gutter={[0, 30]} style={styles.headerNegozio}>
                {this.state.carrello.length > 0 && (
                  <Button
                    type="primary"
                    style={{
                      position: "fixed",
                      zIndex: 100,
                      left: "50%",
                      marginLeft: "-125px",
                      bottom: "70px",
                      width: "250px",
                      height: "55px",
                      boxShadow: "0 2px 20px rgba(0,0,0,0.2)",
                    }}
                    onClick={() => {
                      this.setState({ modaleCarrelloVisible: true });
                    }}
                  >
                    <Row align="middle" justify="center" gutter={15}>
                      <Col>
                        <ShoppingCartOutlined style={{ fontSize: "24px" }} />
                      </Col>
                      <Col>
                        <span style={{ fontWeight: "normal" }}>
                          <span style={{ fontWeight: "bold" }}>
                            {this.state.carrello.length.toString() + " "}
                          </span>{" "}
                          articol
                          {this.state.carrello.length > 1 ? "i" : "o"}
                          <b>{` (${this.state.totale.toFixed(2)} €)`}</b>
                        </span>
                      </Col>
                    </Row>
                  </Button>
                )}

                <Col span={24}>
                  {
                    // CATEGORIA
                    negozio && negozio.categoria !== "" && (
                      <span style={styles.spanCategoria}>
                        {negozio.categoria.toUpperCase()}
                      </span>
                    )
                  }
                  {
                    // NOME DEL NEGOZIO
                    negozio && negozio.nome_negozio !== "" && (
                      <h1
                        style={{
                          fontWeight: "bold",
                          color: Settings.colors.white,
                          fontSize: "48px",
                        }}
                      >
                        {negozio.nome_negozio}
                      </h1>
                    )
                  }
                  <Row align="middle" gutter={[0, 15]}>
                    {/* CONDIVIDI NEGOZIO */}
                    {this.state.chatAbilitata === 1 && (
                      <>
                        <Col>
                          <ModalChatConNegozio
                            negozio={this.state.negozio}
                            callBackLogin={this.openModalLogin}
                          />
                        </Col>
                      </>
                    )}
                    {this.state.ordiniAbilitati ? (
                      <Col>
                        <ModalRichiestaPreventivo
                          carta={this.state.negozio.pagamenti_online}
                          contante={this.state.negozio.pagamenti_offline}
                          negozio={negozio}
                          indirizzi={this.state.indirizzi}
                          callBackLogin={() => {
                            this.setState({ loginVisible: true });
                          }}
                        />
                      </Col>
                    ) : null}
                    {this.state.prenotazioniAbilitate ? (
                      <Col>
                        <ModalRichiestaAppuntamento
                          negozio={this.state.negozio}
                          callBackLogin={this.openModalLogin}
                        />
                      </Col>
                    ) : null}
                  </Row>
                  {this.state.condividiVisible && (
                    <Button type="secondary">Condividi</Button>
                  )}
                </Col>
              </Row>
              <br />
              <br />
              <Row gutter={[20, 30]}>
                <Col xs={{ span: 24 }} md={{ span: 10 }} lg={{ span: 8 }}>
                  {
                    // IMMAGINE
                    negozio && negozio.logo && negozio.logo !== "" ? (
                      <div style={styles.block}>
                        <img
                          src={negozio.logo}
                          style={{ width: "100%", borderRadius: "4px" }}
                        />
                      </div>
                    ) : (
                      <div style={styles.block}>
                        <img
                          src={require("../Assets/Background/placeholder.jpg")}
                          style={{ width: "100%", borderRadius: "4px" }}
                        />
                      </div>
                    )
                  }
                  {
                    // INDIRIZZO
                    negozio &&
                    negozio.indirizzo !== "" &&
                    negozio.civico !== "" &&
                    negozio.cap !== "" &&
                    negozio.citta !== "" &&
                    negozio.provincia !== "" && (
                      <>
                        <h3 style={styles.section_info}>Contatti</h3>
                        <div style={styles.block}>
                          <Row align="top">
                            <Col xs={{ span: 3 }} sm={{ span: 2 }}>
                              <PushpinOutlined style={styles.icon} />
                            </Col>
                            <Col xs={{ span: 21 }} sm={{ span: 22 }}>
                              <span style={styles.info}>
                                <a
                                  target="_blank"
                                  href={`https://maps.google.com/?q=${negozio.indirizzo} ${negozio.civico}, ${negozio.cap} ${negozio.citta}`}
                                >
                                  {`${negozio.indirizzo} ${negozio.civico}, ${negozio.cap} ${negozio.citta}`}
                                </a>
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )
                  }
                  {
                    // TELEFONO
                    negozio &&
                    negozio.telefono !== "" &&
                    negozio.telefono !== "+39" && (
                      <div style={styles.block}>
                        <Row align="top">
                          <Col xs={{ span: 3 }} sm={{ span: 2 }}>
                            <PhoneOutlined style={styles.icon} />
                          </Col>
                          <Col xs={{ span: 21 }} sm={{ span: 22 }}>
                            <span style={styles.info}>
                              {negozio.telefono}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    )
                  }
                  {
                    // EMAIL
                    negozio && negozio.email !== "" && (
                      <div style={styles.block}>
                        <Row align="top">
                          <Col xs={{ span: 3 }} sm={{ span: 2 }}>
                            <MailOutlined style={styles.icon} />
                          </Col>
                          <Col xs={{ span: 21 }} sm={{ span: 22 }}>
                            <span style={styles.info}>{negozio.email}</span>
                          </Col>
                        </Row>
                      </div>
                    )
                  }
                  {
                    // SITO WEB
                    negozio && negozio.sito_web !== "" && (
                      <div style={styles.block}>
                        <Row align="top">
                          <Col xs={{ span: 3 }} sm={{ span: 2 }}>
                            <LinkOutlined style={styles.icon} />
                          </Col>
                          <Col xs={{ span: 21 }} sm={{ span: 22 }}>
                            <span style={styles.info}>
                              <a href={negozio.sito_web} target="_blank">
                                Sito web
                              </a>
                            </span>
                          </Col>
                        </Row>
                      </div>
                    )
                  }
                  {
                    // FACEBOOK
                    negozio && negozio.facebook !== "" && (
                      <div style={styles.block}>
                        <Row align="top">
                          <Col xs={{ span: 3 }} sm={{ span: 2 }}>
                            <FacebookOutlined style={styles.icon} />
                          </Col>
                          <Col xs={{ span: 21 }} sm={{ span: 22 }}>
                            <span style={styles.info}>
                              <a href={negozio.facebook} target="_blank">
                                Facebook
                              </a>
                            </span>
                          </Col>
                        </Row>
                      </div>
                    )
                  }
                  {
                    // INSTAGRAM
                    negozio && negozio.instagram !== "" && (
                      <div style={styles.block}>
                        <Row align="top">
                          <Col xs={{ span: 3 }} sm={{ span: 2 }}>
                            <InstagramOutlined style={styles.icon} />
                          </Col>
                          <Col xs={{ span: 21 }} sm={{ span: 22 }}>
                            <span style={styles.info}>
                              <a href={negozio.instagram} target="_blank">
                                Instagram
                              </a>
                            </span>
                          </Col>
                        </Row>
                      </div>
                    )
                  }
                  {
                    // WHATSAPP
                    negozio.whatsapp && negozio.whatsapp.length > 4 && (
                      <div style={styles.block}>
                        <Row align="top">
                          <Col xs={{ span: 3 }} sm={{ span: 2 }}>
                            <WhatsAppOutlined style={styles.icon} />
                          </Col>
                          <Col xs={{ span: 21 }} sm={{ span: 22 }}>
                            <span style={styles.info}>
                              <a
                                href={"https://wa.me/" + negozio.whatsapp}
                                target="_blank"
                              >
                                Whatsapp
                              </a>
                            </span>
                          </Col>
                        </Row>
                      </div>
                    )
                  }
                  <>
                    {/* <h3 style={styles.section_info}>Condividi</h3>
                    <div style={styles.block}>
                      <FacebookShareButton
                        url="https://garzone.store/comuni/comune-di-busto-garolfo/negozi/309"
                        quote={negozio.nome_negozio + " - Garzone"}
                      >
                        <Button
                          style={styles.socialButton}
                          type="primary"
                          icon={<FacebookFilled style={{ fontSize: "34pt" }} />}
                        />
                      </FacebookShareButton>
                      <TwitterShareButton
                        url="https://garzone.store/comuni/comune-di-busto-garolfo/negozi/309"
                        title={negozio.nome_negozio + " - Garzone"}
                      >
                        <Button
                          style={styles.socialButton}
                          type="primary"
                          icon={
                            <TwitterSquareFilled style={{ fontSize: "34pt" }} />
                          }
                        />
                      </TwitterShareButton>
                    </div> */}
                  </>
                  {
                    // METODI DI PAGAMENTO
                    negozio &&
                    (negozio.bancomat !== 0 ||
                      negozio.carta !== 0 ||
                      negozio.contante !== 0 ||
                      negozio.altri_metodi !== "") && (
                      <>
                        <h3 style={styles.section_info}>
                          Metodi di pagamento
                        </h3>
                        <div style={styles.block}>
                          <span style={styles.info}>
                            {negozio.bancomat !== 0 && <li>Bancomat</li>}
                            {negozio.carta !== 0 && <li>Carta</li>}
                            {negozio.contante !== 0 && <li>Contante</li>}
                            {negozio.altri_metodi !== "" && (
                              <li>Altri metodi: {negozio.altri_metodi}</li>
                            )}
                          </span>
                        </div>
                      </>
                    )
                  }
                  {
                    // ORARI DI APERTURA
                    negozio && negozio.orari_apertura !== "" && (
                      <>
                        <h3 style={styles.section_info}>Orari di apertura</h3>
                        <div style={styles.block}>{negozio.orari_apertura}</div>
                      </>
                    )
                  }
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 14 }} lg={{ span: 16 }}>
                  {
                    // DESCRIZIONE
                    negozio && negozio.descrizione !== "" && (
                      <div style={styles.block}>
                        <span style={{ ...styles.info, fontSize: "18px" }}>
                          {negozio.descrizione}
                        </span>
                      </div>
                    )
                  }

                  {
                    // TABS PRODOTTI E SERVIZI
                    (!loading_prodotti ||
                      !loading_servizi ||
                      !loading_appuntamenti) && (
                      <Tabs
                        size="large"
                        defaultActiveKey={() => {
                          if (promozioni.length > 0) {
                            return "promozioni";
                          } else {
                            if (prodotti.length > 0) {
                              return "prodotti";
                            } else if (servizi.length > 0) {
                              return "servizi";
                            } else {
                              return "";
                            }
                          }
                        }}
                      >
                        {
                          // TAB PROMOZIONI
                          promozioni.length > 0 && (
                            <TabPane
                              tab="Promozioni"
                              key="promozioni"
                              style={{ padding: "25px 0" }}
                            >
                              <div style={{ padding: "0px 0px" }}>
                                <Row gutter={[20, 40]}>
                                  {promozioni.map((item, index) => (
                                    <Col
                                      xl={{ span: 8 }}
                                      md={{ span: 12 }}
                                      sm={{ span: 24 }}
                                    >
                                      <a
                                        target="_blank"
                                        href={
                                          Settings.base_url.utente +
                                          "/comuni/" +
                                          slug +
                                          "/promozioni/" +
                                          item.id
                                        }
                                      >
                                        <CardPromozione
                                          key={index}
                                          nome_negozio={item.nome_negozio}
                                          immagine={item.immagine}
                                          titolo={item.titolo}
                                          data_scadenza={item.data_scadenza}
                                        />
                                      </a>
                                    </Col>
                                  ))}
                                </Row>
                              </div>
                            </TabPane>
                          )
                        }
                        {
                          // TAB PRODOTTI
                          prodotti.length > 0 && (
                            <TabPane
                              tab="Catalogo prodotti"
                              key="prodotti"
                              style={{ padding: "25px 0" }}
                            >
                              <Row>
                                <Col span={24}>
                                  <Alert
                                    message="Informazione"
                                    description={
                                      <>
                                        <Row gutter={[15, 15]}>
                                          <Col span={24}>
                                            <span>
                                              Vuoi fare un richiesta specifica?
                                              Puoi richiedere al negozio un
                                              preventivo personalizzato.
                                            </span>
                                          </Col>
                                          {this.state.ordiniAbilitati ? (
                                            <Col>
                                              <ModalRichiestaPreventivo
                                                primary
                                                negozio={negozio}
                                                indirizzi={this.state.indirizzi}
                                                callBackLogin={() => {
                                                  this.setState({
                                                    loginVisible: true,
                                                  });
                                                }}
                                              />
                                            </Col>
                                          ) : null}
                                        </Row>
                                      </>
                                    }
                                    type="info"
                                    showIcon
                                  />
                                </Col>
                              </Row>
                              <Divider />
                              {negozio &&
                                negozio.categorie_prodotti &&
                                negozio.categorie_prodotti.length > 0 && (
                                  <>
                                    <label style={styles.label}>
                                      Scegli categoria
                                    </label>
                                    <br />
                                    <Select
                                      size="large"
                                      defaultValue=""
                                      style={{ width: 200 }}
                                      onChange={(value) =>
                                        this._filterProdotti(value)
                                      }
                                    >
                                      <Select.Option value="">
                                        Tutte
                                      </Select.Option>
                                      {negozio.categorie_prodotti.map(
                                        (cat, index) => (
                                          <Select.Option
                                            key={index}
                                            value={cat}
                                          >
                                            {cat}
                                          </Select.Option>
                                        )
                                      )}
                                    </Select>
                                  </>
                                )}
                              <div style={{ padding: "40px 0px" }}>
                                {filtered_prodotti.length > 0 ? (
                                  filtered_prodotti.map((item, index) => (
                                    <CardProdotto
                                      key={index}
                                      id={item.id}
                                      immagine={item.immagine}
                                      nome={item.nome}
                                      categoria={item.categoria}
                                      descrizione={item.descrizione}
                                      prezzo={item.prezzo}
                                      prezzo_scontato={item.prezzo_scontato}
                                      callbackAddProdotto={this._addProduct}
                                      ordinabile={item.ordinabile}
                                      ordiniAbilitati={
                                        this.state.ordiniAbilitati
                                      }
                                    />
                                  ))
                                ) : (
                                  <div>
                                    <p>
                                      Nessun prodotto trovato per la categoria
                                      scelta.
                                    </p>
                                  </div>
                                )}
                              </div>
                            </TabPane>
                          )
                        }
                        {
                          // TAB SERVIZI
                          servizi.length > 0 && (
                            <TabPane
                              tab="Catalogo servizi"
                              key="servizi"
                              style={{ padding: "25px 0" }}
                            >
                              <Row>
                                <Col span={24}>
                                  <Alert
                                    message="Informazione"
                                    description={
                                      <>
                                        <Row gutter={[15, 15]}>
                                          <Col span={24}>
                                            <span>
                                              Vuoi fare una richiesta specifica?
                                              Puoi richiedere al negozio un
                                              preventivo personalizzato.
                                            </span>
                                          </Col>
                                          {this.state.ordiniAbilitati ? (
                                            <Col>
                                              <ModalRichiestaPreventivo
                                                primary
                                                negozio={negozio}
                                                indirizzi={this.state.indirizzi}
                                                callBackLogin={() => {
                                                  this.setState({
                                                    loginVisible: true,
                                                  });
                                                }}
                                              />
                                            </Col>
                                          ) : null}
                                        </Row>
                                      </>
                                    }
                                    type="info"
                                    showIcon
                                  />
                                </Col>
                              </Row>
                              <Divider />
                              {negozio &&
                                negozio.categorie_servizi &&
                                negozio.categorie_servizi.length > 0 && (
                                  <>
                                    <label style={styles.label}>
                                      Scegli categoria
                                    </label>
                                    <br />
                                    <Select
                                      size="large"
                                      defaultValue=""
                                      style={{ width: 200 }}
                                      onChange={(value) =>
                                        this._filterServizi(value)
                                      }
                                    >
                                      <Select.Option value="">
                                        Tutte
                                      </Select.Option>
                                      {negozio.categorie_servizi.map(
                                        (cat, index) => (
                                          <Select.Option
                                            key={index}
                                            value={cat}
                                          >
                                            {cat}
                                          </Select.Option>
                                        )
                                      )}
                                    </Select>
                                  </>
                                )}
                              <div style={{ padding: "40px 0px" }}>
                                {filtered_servizi.length > 0 ? (
                                  filtered_servizi.map((item, index) => (
                                    <CardServizio
                                      key={index}
                                      nome={item.nome}
                                      immagine={item.immagine}
                                      categoria={item.categoria}
                                      descrizione={item.descrizione}
                                      prezzo={item.prezzo}
                                      prezzo_scontato={item.prezzo_scontato}
                                    />
                                  ))
                                ) : (
                                  <div>
                                    <p>
                                      Nessun servizio trovato per la categoria
                                      scelta.
                                    </p>
                                  </div>
                                )}
                              </div>
                            </TabPane>
                          )
                        }
                        {this.state.prenotazioniAbilitate && (
                          <TabPane
                            tab="Disponibilità Appuntamenti"
                            key="appuntamenti"
                            style={{ padding: "25px 0" }}
                          >
                            <Row>
                              <Col span={24}>
                                <Alert
                                  message="Informazione"
                                  description={
                                    <>
                                      <Row gutter={[15, 15]}>
                                        <Col span={24}>
                                          <span>
                                            Il calendario mostra la
                                            disponibilità indicativa
                                            dell’attività commerciale.
                                          </span>
                                        </Col>
                                        {this.state.prenotazioniAbilitate ? (
                                          <Col>
                                            <ModalRichiestaAppuntamento
                                              negozio={this.state.negozio}
                                              callBackLogin={
                                                this.openModalLogin
                                              }
                                              primary
                                            />
                                          </Col>
                                        ) : null}
                                      </Row>
                                    </>
                                  }
                                  type="info"
                                  showIcon
                                />
                              </Col>
                            </Row>
                            <Divider />
                            <Calendar
                              localizer={this._localizer}
                              defaultView="week"
                              views={["week", "day"]}
                              id="bigcalendar"
                              events={this.state.appuntamenti}
                              startAccessor="start"
                              endAccessor="end"
                              eventPropGetter={_eventStyleGetter}
                              style={{ height: 700 }}
                              messages={{
                                next: "Avanti",
                                previous: "Indietro",
                                today: "Oggi",
                                month: "Mese",
                                week: "Settimana",
                                day: "Giorno",
                                allDay: "Tutto il giorno",
                                agenda: "Agenda",
                                date: "Data",
                                time: "Orario",
                                event: "Evento",
                                showMore: (target) => (
                                  <span className="ml-2" role="presentation">
                                    {" "}
                                    +{target} altri...
                                  </span>
                                ),
                              }}
                            />
                          </TabPane>
                        )}
                      </Tabs>
                    )
                  }
                </Col>
              </Row>
            </>
          )}
          <ModaleLoginUtente
            visible={this.state.loginVisible}
            buttonDisabled={this.state.buttonDisabled}
            callbackClose={this._closeLoginModal}
            callbackLogin={this._changeLoginState}
          />
        </Wrapper>
      </>
    );
  }
}

const styles = {
  block: {
    marginBottom: "5px",
    whiteSpace: "pre-wrap",
  },
  section_info: {
    color: Settings.colors.darkGrey,
    fontWeight: "bold",
    marginTop: "50px",
    marginBottom: "25px",
  },
  icon: {
    fontSize: "18px",
    color: Settings.colors.grey,
  },
  info: {
    color: Settings.colors.darkGrey,
  },
  descrizione_block: {
    color: Settings.colors.darkGrey,
    fontSize: "18px",
    marginBottom: "50px",
  },
  label: {
    color: Settings.colors.grey,
  },
  socialButton: {
    width: "50px",
    height: "50px",
    textAlign: "center",
    marginRight: "10px",
  },
  headerNegozio: {
    backgroundColor: Settings.colors.cyan,
    marginTop: "0px",
    marginLeft: "-15px",
    marginRight: "-15px",
    paddingLeft: "20px",
  },
  spanCategoria: {
    color: Settings.colors.white,
    fontSize: "14px",
  },
  inputError: {
    color: Settings.colors.red,
    fontSize: "14px",
  },
  radioStyle: {
    display: "block",
  },
};

function mapStateToProps(state) {
  return {
    id: state.comuni.id,
    slug: state.comuni.slug,
    nome: state.comuni.nome,
    logo: state.comuni.logo,
    auth: state.auth,
    chats: state.chat,
  };
}

export default connect(mapStateToProps, null)(Negozio);
