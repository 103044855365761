import { Auth, Functions } from "../Config/Fire";

export function login(email, password) {
  return Auth().signInWithEmailAndPassword(email, password);
}

export function getClaims() {
  return Auth().currentUser.getIdTokenResult();
}

export function userType(email) {
  // let type = 'negozio'
  const type = Functions.httpsCallable("auth-getTipoUtente");
  return type(email);
}

export function checkAuthState(callback) {
  return Auth().onAuthStateChanged(callback);
}

export function resendEmailVerification() {
  return Auth().currentUser.sendEmailVerification();
}

export function requestResetPassword(email) {
  return Auth().sendPasswordResetEmail(email);
}

export async function applyActionCode(code) {
  let result = null;
  await Auth()
    .applyActionCode(code)
    .then(() => {
      result = "success";
    })
    .catch((error) => {
      result = error;
    });
  return result;
}

export async function checkCode(code) {
  let result = null;
  await Auth()
    .verifyPasswordResetCode(code)
    .then((data) => {
      result = data;
    })
    .catch((error) => {
      result = error;
    });
  return result;
}

export async function resetPassword(code, password) {
  let result = null;
  await Auth()
    .confirmPasswordReset(code, password)
    .then(() => {
      result = "success";
    })
    .catch((error) => {
      result = error;
    });
  return result;
}

export function updateUserEmail(email) {
  return Auth().currentUser.updateEmail(email);
}

export function updateUserPassword(password) {
  return Auth().currentUser.updatePassword(password);
}

export function updateUserData(data) {
  //Chiamo cloud function per registrare utenza e negozio
  const utente = Functions.httpsCallable("updateUserData");
  return data;
}

export function logout() {
  return Auth().signOut();
}
