import React, { Component } from "react";

// import ant design components
import { Row, Col, Tag } from "antd";

// import custom components
import Chip from "./Chip";

// import settings
import Settings from "../Config/Settings";
import { date } from "yup";
import moment from "moment";

class CardPromozione extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data_scadenza: props.data_scadenza,
    };
  }
  _getColor = () => {
    var dataCorrente = moment().format("DD/MM/YYYY");
    var dataScadenza = moment(this.state.data_scadenza, "DD/MM/YYYY").format(
      "DD/MM/YYYY"
    );
    if (dataCorrente < dataScadenza) {
      return Settings.colors.darkGrey;
    } else {
      return Settings.colors.red;
    }
  };
  render() {
    const {
      titolo,
      data_scadenza,
      nome_negozio,
      immagine,
      containerStyles,
    } = this.props;

    return (
      <div style={{ ...styles.container, containerStyles }}>
        <Row style={styles.section}>
          <Col span={24}>
            {immagine && immagine !== "" ? (
              <img style={styles.image} src={immagine} />
            ) : (
              <img
                style={styles.image}
                src={require("../Assets/Background/placeholder.jpg")}
              />
            )}
          </Col>
        </Row>
        <Row style={styles.section}>
          <Col span={24}>
            <Tag color={this._getColor()} style={{ fontSize: "14px" }}>
              Valida fino al {data_scadenza.split(" ")[0]}
            </Tag>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h2 style={styles.title}>{titolo}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <span style={styles.description}>{nome_negozio}</span>
          </Col>
        </Row>
      </div>
    );
  }
}

const styles = {
  container: {
    overflow: "hidden",
  },
  section: {
    marginBottom: "15px",
  },
  image: {
    width: "100%",
    borderRadius: "4px",
  },
  title: {
    fontSize: "22px",
    fontWeight: "bold",
    marginBottom: 0,
    color: Settings.colors.darkGrey,
  },
  description: {
    fontSize: "17px",
    color: Settings.colors.darkGrey,
    textTransform: "uppercase",
  },
};

export default CardPromozione;
