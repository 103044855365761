import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  Button,
  Col,
  Divider,
  Image,
  notification,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Input,
} from "antd";
import {
  FormOutlined,
  PlusOutlined,
  QuestionCircleFilled,
  SendOutlined,
} from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { Form, Formik } from "formik";
import TextArea from "antd/lib/input/TextArea";
import {
  getUtente,
  postFirstMessage,
  postIndirizzi,
  postMessage,
} from "../Controllers/Utente";
import Settings from "../Config/Settings";
import { validationSchemaAggiungiIndirizzo } from "../Config/Validation";

const comuni_json = require("../Assets/comuni.json");

const ModalRichiestaPreventivo = ({
  negozio,
  callBackLogin,
  indirizzi,
  primary,
  carta,
  contante,
}) => {
  //States
  const [modalVisible, setModalVisible] = React.useState(false);
  const [first, setFirst] = React.useState(false);
  const [chatUid, setChatUid] = React.useState(null);
  const [sendMessageLoading, setsendMessageLoading] = useState(false);
  const [openModalIndirizzi, setopenModalIndirizzi] = useState(false);
  const [aggiungiLoading, setaggiungiLoading] = useState();
  const [selected_citta_caps, setselected_citta_caps] = useState();
  //-----------------------------

  const auth = useSelector((state) => state.auth);
  const chats = useSelector((state) => state.chat.chats);
  const [actIndirizzi, setactIndirizzi] = useState(
    auth.user ? auth.user.indirizzi : []
  );
  useEffect(() => {}, []);
  /************
   * FUNZIONI *
   ************/
  const openModal = () => {
    setopenModalIndirizzi(true);
  };
  const closeModal = () => {
    setopenModalIndirizzi(false);
  };
  const _aggiungiIndirizzo = async (values, { setSubmitting }) => {
    const { indirizzo, cap, citta, civico } = values;
    setaggiungiLoading(true);
    setSubmitting(true);
    try {
      await postIndirizzi({
        id: auth.user.uid,
        indirizzi: [
          ...actIndirizzi,
          {
            indirizzo: indirizzo.trim(),
            cap: cap,
            citta: citta,
            civico: civico.trim(),
          },
        ],
      });
      setactIndirizzi([
        ...actIndirizzi,
        {
          indirizzo: indirizzo.trim(),
          cap: cap,
          citta: citta,
          civico: civico.trim(),
        },
      ]);
      notification.destroy();
      notification.success({
        message: "Indirizzo aggiunto",
        description: `Hai aggiunto con successo l'indirizzo ${indirizzo.trim()} ${civico.trim()}, ${citta} ${cap}`,
      });
      setopenModalIndirizzi(false);
      setactIndirizzi([
        ...actIndirizzi,
        {
          indirizzo: indirizzo.trim(),
          cap: cap,
          citta: citta,
          civico: civico.trim(),
        },
      ]);
    } catch (error) {
      notification.destroy();
      notification.error({
        message: "Errore!",
        description:
          "Errore nell'aggiunta dell'indirizzo, riprova o contatta l'assistenza.",
      });
    } finally {
      setaggiungiLoading(false);
    }
  };
  const _sendPreventivo = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setsendMessageLoading(true);
    let uid_chat;
    try {
      const { richiesta, consegna, tipo_pagamento, indirizzo } = values;
      //id negozio verso
      //uid mio
      //messaggio

      //-> Chiamata function POST message che restituisce l'uid firebase della chat
      const user = await getUtente(auth.user.uid);
      const messaggio = richiesta;
      if (!first) {
        const res = await postMessage({
          chat_uid: chatUid,
          id_negozio: negozio.id,
          uid_from: auth.user.uid,
          messaggio: messaggio,
          type: "preventivo",
          extra: {
            richiesta: richiesta,
            consegna:
              consegna === "Consegna a domicilio" ? "consegna" : "ritiro",
            indirizzo: indirizzo,
            tipo_pagamento: tipo_pagamento,
            //tipo_pagamento: "contante",
          },
        });
        uid_chat = res.data.firebase_db_chat_id;
      } else {
        const res = await postFirstMessage({
          id_negozio: negozio.id,
          id_utente: user.data.id,
          uid_from: auth.user.uid,
          messaggio: messaggio,
          type: "preventivo",
          extra: {
            richiesta: richiesta,
            consegna:
              consegna === "Consegna a domicilio" ? "consegna" : "ritiro",
            indirizzo: indirizzo,
            tipo_pagamento: tipo_pagamento,
            //tipo_pagamento: "contante",
          },
        });
        uid_chat = res.data;
      }
      notification.destroy();
      notification.success({
        message: "Congratulazioni!",
        description:
          "Hai inviato correttamente la richiesta di preventivo al negozio. Quando il negozio avrà creato correttamente il preventivo riceverai una notifica.",
      });
      setsendMessageLoading(false);
      setModalVisible(false);
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      setsendMessageLoading(false);

      notification.destroy();
      notification.error({
        message: "Errore!",
        description: "Contatta l'assistenza e descrivi il problema.",
      });
    }
  };
  /************************************
   * ------------FUNZIONI------------ *
   ************************************/
  /******************************************
   * ---------------STILE------------------ *
   ******************************************/
  const styles = {
    block: {
      marginBottom: "5px",
      whiteSpace: "pre-wrap",
    },
    section_info: {
      color: Settings.colors.darkGrey,
      fontWeight: "bold",
      marginTop: "50px",
      marginBottom: "25px",
    },
    icon: {
      fontSize: "18px",
      color: Settings.colors.grey,
    },
    info: {
      color: Settings.colors.darkGrey,
    },
    descrizione_block: {
      color: Settings.colors.darkGrey,
      fontSize: "18px",
      marginBottom: "50px",
    },
    label: {
      color: Settings.colors.grey,
    },
    socialButton: {
      width: "50px",
      height: "50px",
      textAlign: "center",
      marginRight: "10px",
    },
    headerNegozio: {
      backgroundColor: Settings.colors.cyan,
      marginTop: "0px",
      marginLeft: "-15px",
      marginRight: "-15px",
      paddingLeft: "20px",
    },
    spanCategoria: {
      color: Settings.colors.white,
      fontSize: "14px",
    },
    inputError: {
      color: Settings.colors.red,
      fontSize: "14px",
    },
    radioStyle: {
      display: "block",
    },
  };
  /*************************************
   * ---------------STILI------------- *
   *************************************/
  return (
    <>
      <Button
        type={primary ? "primary" : "secondary"}
        style={{
          marginRight: "10px",
        }}
        onClick={() => {
          if (auth.user != null) {
            const chatExists = chats.find((c) => c.user_sql_id == negozio.id);
            if (chatExists) {
              setFirst(false);
              setChatUid(chatExists.chat_uid);
            } else {
              setFirst(true);
            }
            setModalVisible(true);
          } else {
            callBackLogin();
          }
        }}
        icon={<FormOutlined />}
      >
        Richiedi preventivo
      </Button>
      <Modal
        destroyOnClose
        footer={null}
        onCancel={() => {
          setModalVisible(false);
        }}
        transitionName="zoom"
        title={
          <Image
            style={styles.modalLogo}
            width="158px"
            onClick={null}
            src={require("../Assets/logo-cyan.png")}
          />
        }
        visible={modalVisible}
      >
        <Row justify="space-between" align="middle" gutter={[15, 15]}>
          <Col span={24}>
            <Formik
              validationSchema={Yup.object().shape({
                richiesta: Yup.string()
                  .nullable()
                  .required("Descrivi la tua richiesta di preventivo"),
                consegna: Yup.string()
                  .oneOf(["Consegna a domicilio", "Ritira in negozio"])
                  .required("Seleziona la modalità di consegna"),

                indirizzo: Yup.string()
                  .nullable()
                  .when("consegna", {
                    is: "Consegna a domicilio",
                    then: Yup.string()
                      .nullable()
                      .required("Inserisci l'indirizzo di consegna"),
                  }),
                // tipo_pagamento: Yup.string()
                //   .when("consegna", {
                //     is: "Consegna a domicilio",
                //     then: Yup.string()
                //       .nullable()
                //       .required("Seleziona il metodo di pagamento")
                //       .oneOf(["carta", "consegna"]),
                //   })
                //   .when("consegna", {
                //     is: "Ritira in negozio",
                //     then: Yup.string()
                //       .nullable()
                //       .required("Seleziona il metodo di pagamento")
                //       .oneOf(["carta", "ritiro"]),
                //   }),
              })}
              initialValues={{
                richiesta: "",
                consegna: null,
                tipo_pagamento: null,
                indirizzo: null,
              }}
              validateOnMount={true}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={_sendPreventivo}
            >
              {({
                touched,
                errors,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isValid,
                isSubmitting,
                dirty,
                validateForm,
              }) => (
                <Form>
                  <Row justify="center" align="middle" gutter={[15, 15]}>
                    <Col span={24}>
                      <Space direction="horizontal">
                        <span style={{ color: Settings.colors.darkGrey }}>
                          Richiesta
                        </span>
                        <Popover content="Descrivi, aggiungendo informazioni come i prodotti/servizi desiderati, la tua richiesta di preventivo">
                          <QuestionCircleFilled
                            style={{ color: Settings.colors.darkGrey }}
                          />
                        </Popover>
                      </Space>

                      <TextArea
                        rows={6}
                        placeholder="Descrivi la richiesta di preventivo indicando quello di cui hai bisogno..."
                        value={values.richiesta}
                        onChange={handleChange("richiesta")}
                        onBlur={handleBlur("richiesta")}
                        style={{ marginTop: "10px" }}
                      />
                      <span style={styles.inputError}>
                        {touched.richiesta && errors.richiesta}
                      </span>
                    </Col>
                  </Row>
                  <Divider />
                  <Row style={{ marginBottom: 15 }}>
                    <Col span={24}>
                      <Space direction="horizontal">
                        <span style={{ color: Settings.colors.darkGrey }}>
                          Modalità Ordine
                        </span>
                        <Popover content="Seleziona la modalità di consegna che desideri tra quelle che il negozio accetta">
                          <QuestionCircleFilled
                            style={{ color: Settings.colors.darkGrey }}
                          />
                        </Popover>
                      </Space>
                    </Col>
                    <Col span={24}>
                      <Radio.Group
                        value={values.consegna}
                        onBlur={handleBlur("consegna")}
                        onChange={(e) => {
                          handleChange(e);
                          if (
                            e.target.value &&
                            e.target.value.startsWith("Consegna")
                          ) {
                            setFieldValue(
                              "consegna",
                              "Consegna a domicilio",
                              true
                            );
                          } else {
                            setFieldValue(
                              "consegna",
                              "Ritira in negozio",
                              true
                            );
                          }
                          setFieldValue("tipo_pagamento", null, true);
                          setFieldValue("indirizzo", null, true);
                          validateForm();
                        }}
                      >
                        {negozio.ritiro && (
                          <Radio
                            style={styles.radioStyle}
                            value="Ritira in negozio"
                          >
                            Ritira in negozio
                          </Radio>
                        )}
                        {negozio.consegna && (
                          <Radio
                            style={styles.radioStyle}
                            value="Consegna a domicilio"
                          >
                            Consegna a domicilio
                          </Radio>
                        )}
                      </Radio.Group>
                      <span style={styles.inputError}>
                        {touched.consegna && errors.consegna}
                      </span>
                    </Col>
                  </Row>
                  {values.consegna === "Consegna a domicilio" && (
                    <>
                      <Row style={{ marginBottom: 15 }}>
                        <Col span={24}>
                          <span
                            style={{
                              color: Settings.colors.darkGrey,
                            }}
                          >
                            Indirizzo di consegna
                          </span>
                        </Col>
                        <Col span={24}>
                          <Select
                            value={values.indirizzo}
                            placeholder="Scegli indirizzo"
                            handleBlur={handleBlur("indirizzo")}
                            onSelect={(e) => {
                              if (e !== null) {
                                setFieldValue("indirizzo", e);
                              }
                            }}
                            style={{ width: "100%" }}
                            dropdownRender={(menu) => (
                              <div>
                                {menu}
                                <Row>
                                  <Col span={24}>
                                    <Button
                                      icon={<PlusOutlined />}
                                      type="secondary"
                                      onClick={openModal}
                                      block
                                    >
                                      Aggiungi indirizzo
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          >
                            {actIndirizzi.map((item, index) => (
                              <Select.Option
                                key={index}
                                value={
                                  item.indirizzo.toString() +
                                  " " +
                                  item.civico.toString() +
                                  " " +
                                  item.citta.toString() +
                                  " " +
                                  item.cap.toString()
                                }
                              >
                                {item.indirizzo.toString() +
                                  " " +
                                  item.civico.toString() +
                                  " " +
                                  item.citta.toString() +
                                  " " +
                                  item.cap.toString()}
                              </Select.Option>
                            ))}
                          </Select>
                        </Col>
                        <span style={styles.inputError}>
                          {touched.indirizzo && errors.indirizzo}
                        </span>
                      </Row>
                    </>
                  )}
                  {contante || carta ? (
                    <Row>
                      <Col span={24}>
                        <Space direction="horizontal">
                          <span style={{ color: Settings.colors.darkGrey }}>
                            Modalità Pagamento
                          </span>
                          <Popover content="Seleziona la modalità di pagamento che desideri tra quelle che il negozio accetta, in caso di pagamento con carta/bancomat il pagamento sarà online">
                            <QuestionCircleFilled
                              style={{ color: Settings.colors.darkGrey }}
                            />
                          </Popover>
                        </Space>
                      </Col>
                      <Col span={24}>
                        <Radio.Group
                          value={values.tipo_pagamento}
                          onChange={(e) => {
                            setFieldValue("tipo_pagamento", e.target.value);
                          }}
                          style={styles.smallText}
                        >
                          {carta ? (
                            <Radio style={styles.radioStyle} value="carta">
                              Carta di Credito/Bancomat
                            </Radio>
                          ) : null}
                          {contante ? (
                            <Radio
                              style={styles.radioStyle}
                              value={
                                values.consegna
                                  ? values.consegna.startsWith("Consegna")
                                    ? "consegna"
                                    : "ritiro"
                                  : ""
                              }
                            >
                              {values.consegna
                                ? values.consegna.startsWith("Consegna")
                                  ? "Alla consegna"
                                  : "Al ritiro"
                                : "Alla consegna/Al ritiro"}
                            </Radio>
                          ) : null}
                        </Radio.Group>
                        <span style={styles.inputError}>
                          {touched.consegna && errors.consegna}
                        </span>
                      </Col>
                    </Row>
                  ) : null}

                  <Divider />
                  <Row justify="space-around">
                    <Col span={24}>
                      <Button
                        block
                        size="large"
                        icon={<SendOutlined />}
                        type="primary"
                        onClick={handleSubmit}
                        loading={sendMessageLoading}
                        disabled={!isValid || isSubmitting || !values.consegna}
                      >
                        {isSubmitting ? "Attendi..." : "Invia"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal>
      <Modal
        style={{ zIndex: 1000 }}
        destroyOnClose
        footer={null}
        onCancel={closeModal}
        transitionName="zoom"
        title="Aggiungi indirizzo"
        visible={openModalIndirizzi}
      >
        <Formik
          initialValues={{
            citta: null,
            cap: null,
          }}
          validationSchema={validationSchemaAggiungiIndirizzo}
          validateOnMount={true}
          validateOnChange={true}
          onSubmit={_aggiungiIndirizzo}
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isValid,
            isSubmitting,
            dirty,
          }) => (
            <Form>
              <Row gutter={[15, 15]}>
                <Col span={16}>
                  <span style={styles.inputLabel}>Indirizzo</span>
                  <Input
                    placeholder="Inserisci indirizzo"
                    value={values.indirizzo}
                    onChange={handleChange("indirizzo")}
                    onBlur={handleBlur("indirizzo")}
                  />
                  <span style={styles.inputError}>
                    {touched.indirizzo && errors.indirizzo}
                  </span>
                </Col>
                <Col span={8}>
                  <span style={styles.inputLabel}>Civico</span>
                  <Input
                    placeholder="Inserisci civico"
                    value={values.civico}
                    onChange={handleChange("civico")}
                    onBlur={handleBlur("civico")}
                  />
                  <span style={styles.inputError}>
                    {touched.civico && errors.civico}
                  </span>
                </Col>
              </Row>
              <Row gutter={[15, 15]}>
                <Col span={16}>
                  <span style={styles.inputLabel}>Città</span>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={(value) => {
                      setFieldValue("citta", value);
                      let comune = comuni_json.find((i) => i.nome == value);
                      setFieldValue(
                        "cap",
                        comune && Array.isArray(comune.cap)
                          ? comune.cap[0]
                          : comune.cap
                      );
                      setselected_citta_caps(
                        comune && Array.isArray(comune.cap)
                          ? comune.cap
                          : [comune.cap]
                      );
                      setFieldValue("provincia", comune.provincia.nome);
                    }}
                    size="default"
                    placeholder="Scegli la città"
                    style={{ width: "100%" }}
                    onBlur={handleBlur("citta")}
                    value={values.citta}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {comuni_json.map((c) => (
                      <Select.Option key={c.nome}>{c.nome}</Select.Option>
                    ))}
                  </Select>
                  <span style={styles.inputError}>
                    {touched.citta && errors.citta}
                  </span>
                </Col>
                <Col span={8}>
                  <span style={styles.inputLabel}>CAP</span>
                  <Select
                    onChange={(value) => setFieldValue("cap", value)}
                    size="default"
                    placeholder="Seleziona il CAP"
                    style={{ width: "100%" }}
                    onBlur={handleBlur("cap")}
                    value={values.cap}
                  >
                    {values.citta
                      ? selected_citta_caps.map((c) => (
                          <Select.Option key={c}>{c}</Select.Option>
                        ))
                      : null}
                  </Select>
                  <span style={styles.inputError}>
                    {touched.cap && errors.cap}
                  </span>
                </Col>
              </Row>

              <Divider />
              <Row justify="center" align="middle">
                <Col span={24}>
                  <Button
                    icon={<PlusOutlined />}
                    loading={aggiungiLoading}
                    disabled={!(isValid && dirty) || isSubmitting}
                    block={true}
                    size="large"
                    onClick={() => {
                      if (!isValid)
                        notification.error({
                          message: "Errore",
                          description: "Ricontrolla i dati inseriti",
                        });
                      handleSubmit();
                    }}
                    type="primary"
                  >
                    {aggiungiLoading ? "Attendi..." : "Aggiungi"}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ModalRichiestaPreventivo;
