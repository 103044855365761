import { Col, notification, Row } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import React, { Component } from "react";
import Loading from "../Components/Loading";
import Wrapper from "../Components/Wrapper";
import { getComunicazione } from "../Controllers/Comunicazioni";
class Comunicazione extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, comunicazione: null };
  }

  componentDidMount() {
    this.getComunicazione();
  }
  getComunicazione = async () => {
    this.setState({ loading: true });
    const { id } = this.props.match.params;
    try {
      const { data } = await getComunicazione(id);
      this.setState({ comunicazione: data, loading: false });
    } catch (error) {
      notification.destroy();
      notification.error({
        title: "Errore",
        message: "C'è stato un errore, contatta l'assistenza.",
      });
    }
  };
  render() {
    return (
      <>
        <Wrapper>
          <Loading loading={this.state.loading} />
          {this.state.comunicazione ? (
            <Row style={{ padding: "10px" }} gutter={[0, 20]}>
              <Col span={24}>
                <span style={{ fontSize: "26px" }}>
                  <strong>
                    Comunicazione del {this.state.comunicazione.creazione}
                  </strong>
                </span>
              </Col>
              <Col span={24}>
                <span style={{ fontSize: "26px" }}>
                  <strong>{this.state.comunicazione.titolo}</strong>
                </span>
              </Col>
              <Col span={24}>
                <Paragraph
                  style={{
                    marginBottom: "5px",
                    whiteSpace: "pre-wrap",
                    fontSize: "20px",
                  }}
                >
                  {this.state.comunicazione.descrizione}
                </Paragraph>
              </Col>
            </Row>
          ) : null}
        </Wrapper>
      </>
    );
  }
}

export default Comunicazione;
