// This is the default state of the app i.e. when the app starts for the first time
const initialState = {
  id: null,
  nome: null,
  slug: null,
  logo: null,
  coords: null
}

// This is a reducer which listens to actions and modifies the state
const ComuniReducer = (state = initialState, action) => {
  // A switch is used since if more actions are added in the future, it will be easy
  // to be able to handle this in the reducer since we just add another 'case'.
  switch (action.type) {
    case 'SET_ACTUAL_COMUNE':
      return {
        ...state,
        id: action.payload.id,
        nome: action.payload.nome,
        slug: action.payload.slug,
        logo: action.payload.logo,
        coords: action.payload.coords
      }
    default:
      return state
  }
}

// export reducer
export default ComuniReducer