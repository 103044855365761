import { Card, Col, List, Row, Typography } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../Components/Loading";
import Panel from "../Components/Panel";
import Wrapper from "../Components/Wrapper";
import Settings from "../Config/Settings";
import { getComunicazioniComune } from "../Controllers/Comunicazioni";
import { withRouter } from "react-router-dom";

class Comunicazioni extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      comunicazioni: [],
    };
  }

  _getComunicazioniComune = async () => {
    this.setState({ loading: true });
    const { slug } = this.props.match.params;
    if (slug === this.props.slug) {
      try {
        const { data } = await getComunicazioniComune(this.props.id);
        this.setState({ comunicazioni: data });
      } catch (e) {
        console.log(e);
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  _loader = async () => {
    await this._getComunicazioniComune();
  };

  componentDidMount() {
    this._loader();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.slug !== this.props.slug) {
      this._loader();
    }
  }

  render() {
    const { loading, comunicazioni } = this.state;
    return (
      <Wrapper>
        <Loading loading={loading} opaque />
        <Row style={styles.section}>
          <Col span={24}>
            <Panel
              title="Comunicazioni dal Comune"
              background={Settings.colors.cyan}
              borderRadius="none"
              fontSizeTitle="48px"
              titleColor={Settings.colors.white}
            >
              <div style={{ padding: "0 15px", marginTop: "-10px" }}>
                <Row>
                  <Col span={24} style={{ color: Settings.colors.white }}>
                    Sfoglia tutte le comunicazioni della città{" "}
                    {this.props.nome && this.props.nome}
                  </Col>
                </Row>
              </div>
            </Panel>
          </Col>
        </Row>
        <Row style={styles.section}>
          <Col span={24}>
            <List
              grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 4,
                xxl: 4,
              }}
              dataSource={comunicazioni}
              renderItem={(item) => (
                <List.Item>
                  <Card
                    title={
                      <span
                        style={{
                          color: Settings.colors.grey,
                          fontSize: 16,
                        }}
                      >
                        {item.creazione}
                      </span>
                    }
                  >
                    <Card.Meta
                      title={
                        <span
                          style={{
                            color: Settings.colors.darkGrey,
                            fontWeight: "bold",
                          }}
                        >
                          {item.titolo}
                        </span>
                      }
                      description={
                        <>
                          <Row>
                            <Col span={24}>
                              <Typography.Paragraph
                                ellipsis={
                                  item.descrizione.length > 10
                                    ? {
                                      rows: 2,
                                    }
                                    : false
                                }
                                style={{ marginBottom: "35px" }}
                              >
                                {item.descrizione}
                              </Typography.Paragraph>
                            </Col>

                            {/* <span
                            style={{
                              color: Settings.colors.darkGrey,
                              fontWeight: "bold",
                            }}
                          >
                            <i> - {item.nome}</i>
                          </span> */}
                            <Col span={24}>
                              <span
                                style={{
                                  color: Settings.colors.cyan,
                                  fontSize: "17px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.props.history.push(
                                    "/comuni/" +
                                    this.props.slug +
                                    `/comunicazioni/${item.id}`
                                  );
                                }}
                              >
                                Mostra di più
                              </span>
                            </Col>
                          </Row>
                        </>
                      }
                    />
                  </Card>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

const styles = {
  section: {
    marginBottom: "50px",
  },
  title: {
    fontSize: "28px",
    fontWeight: "600",
    marginBottom: 0,
    color: Settings.colors.darkGrey,
  },
};

function mapStateToProps(state) {
  return {
    id: state.comuni.id,
    slug: state.comuni.slug,
    nome: state.comuni.nome,
    logo: state.comuni.logo,
  };
}

export default withRouter(connect(mapStateToProps)(Comunicazioni));
