import { Component } from "react";
import React from "react";
import { Button, Col, Divider, Row } from "antd";
import {
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Settings from "../Config/Settings";

class ListaProdottiCarrello extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carrelloFinale: [],
    };
  }
  processCarrello = (carrello) => {
    const uniqueSet = new Set(carrello);
    let carrelloFinale = [...uniqueSet];
    this.setState({ carrelloFinale: carrelloFinale });
  };
  findProduct = (id) => {
    for (let i = 0; i < this.props.prodotti.length; i++) {
      if (this.props.prodotti[i].id === id) {
        return this.props.prodotti[i];
      }
    }
  };
  getProductName = (productId) => {
    if (productId) {
      const prod = this.findProduct(productId);
      return prod.nome;
    }
  };
  getProductPrice = (productId) => {
    if (productId) {
      const prod = this.findProduct(productId);
      return prod.prezzo_scontato !== null ? prod.prezzo_scontato : prod.prezzo;
    }
  };
  removeElement = (array, elem) => {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  };
  countOccurrences = (arr, val) =>
    arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

  componentDidMount() {
    let carrello = this.props.carrello;
    this.processCarrello(carrello);
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      let carrello = this.props.carrello;
      this.processCarrello(carrello);
    }
  }
  render() {
    return (
      <div>
        {this.state.carrelloFinale.map((item, index) => (
          <Row gutter={[15, 10]} key={index}>
            <Col span={24}>
              <Row gutter={[15, 10]} align="middle">
                <Col>
                  <Button
                    style={{ fontSize: "17px" }}
                    type="secondary"
                    shape="circle"
                    style={{ width: "24px", fontSize: "17px" }}
                    icon={<MinusOutlined />}
                    onClick={() => {
                      this.props.removeFromCartCallBack(item);
                      let nuovoCarrello = this.state.carrelloFinale;
                      this.removeElement(nuovoCarrello, item);
                      this.setState({ carrelloFinale: nuovoCarrello });
                    }}
                  ></Button>
                </Col>
                <Col>
                  <span style={styles.text}>
                    {this.countOccurrences(this.props.carrello, item)}x{" "}
                  </span>
                </Col>
                <Col>
                  <Button
                    style={{ fontSize: "17px" }}
                    type="secondary"
                    shape="circle"
                    style={{ width: "24px", fontSize: "17px" }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.props.addToCartCallBack(item);
                      let carrelloFin = this.state.carrelloFinale;
                      carrelloFin.push(item);
                      this.setState({ carrelloFinale: carrelloFin });
                    }}
                  ></Button>
                </Col>
                <Col flex={1}>
                  <Row justify="space-between">
                    <Col>
                      <span
                        style={{
                          fontSize: "17px",
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        {this.getProductName(item)}
                      </span>
                    </Col>
                    <Col>
                      <span style={styles.text}>
                        {"€ " +
                          (
                            this.getProductPrice(item) *
                            this.countOccurrences(this.props.carrello, item)
                          ).toFixed(2)}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Divider />
          </Row>
        ))}
      </div>
    );
  }
}
const styles = {
  text: {
    fontSize: "17px",
    color: Settings.colors.darkGrey,
    fontWeight: "bold",
  },
};
export default ListaProdottiCarrello;
