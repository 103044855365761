import firebase from "firebase";
import { Functions } from "../Config/Fire";

export async function getResoFromOrderUid(uid_ordine) {
  const getResoFromOrderUid = Functions.httpsCallable("utente-getResoFromOrderUid");
  return await getResoFromOrderUid(uid_ordine);
}

export async function createReso({
  id_ordine,
  id_negozio,
  prodotto,
  uid_ordine,
  richiesta,
  status
}) {
  const createReso = Functions.httpsCallable("utente-createReso");
  return await createReso({
    id_ordine,
    id_negozio,
    prodotto,
    uid_ordine,
    richiesta,
    status
  });
}