// -------------------------------------------------

// setActualComune action
// set info comune
export function setActualComune(payload) {
  return {
    type: "SET_ACTUAL_COMUNE",
    payload: payload,
  };
}
// -------------------------------------------------
