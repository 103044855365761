import React, { Component } from "react";
import { Col, Divider, Input, List, Row, Tooltip, Typography } from "antd";
import Settings from "../Config/Settings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { _getOrderColor, _timeDifference } from "../Utility/utility";

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      filtered_orders: [],
    };
  }

  componentDidMount() {
    this.setState({
      orders: this.props.ordini.orders,
      filtered_orders: this.props.ordini.orders,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps != this.props) {
      this.setState({
        orders: this.props.ordini.orders,
        filtered_orders: this.props.ordini.orders,
      });
    }
  }

  _filterOrders = (keyword) => {
    const filtered_orders = this.state.orders.filter(
      (order) =>
        order.order_title.toLowerCase().includes(keyword.toLowerCase()) ||
        order.order_uid.toLowerCase().includes(keyword.toLowerCase())
    );
    this.setState({ filtered_orders: filtered_orders });
  };

  render() {
    const { filtered_orders } = this.state;
    return (
      <>
        <Col span={24}>
          <Typography.Title level={3} style={styles.title}>
            Ordini
          </Typography.Title>
          <Input
            enterButton={false}
            placeholder="Cerca tra gli ordini..."
            size="large"
            allowClear
            onChange={(e) => this._filterOrders(e.target.value)}
          />
          <Divider />
          <List
            locale={{ emptyText: "Nessun ordine" }}
            itemLayout="horizontal"
            dataSource={filtered_orders}
            renderItem={({
              order_uid,
              order_title,
              last_update,
              order_status,
              to_read,
            }) => (
              <List.Item
                key={order_uid}
                style={{
                  cursor: "pointer",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  borderRadius: "4px",
                  borderBottom: "none",
                  backgroundColor:
                    this.props.match.params.order_uid == order_uid
                      ? Settings.colors.lightCyan
                      : null,
                }}
                onClick={() => this.props.history.push(`/ordini/${order_uid}`)}
              >
                <List.Item.Meta
                  avatar={
                    // <img
                    //   width={66}
                    //   height={50}
                    //   style={{ borderRadius: "4px", overflow: "hidden" }}
                    //   src={placeholder}
                    // />
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        order_status[0].toUpperCase() + order_status.substr(1)
                      }
                    >
                      <div
                        style={{
                          width: "14px",
                          height: "14px",
                          borderRadius: "14px",
                          margin: "16px 0px",
                          backgroundColor: _getOrderColor(order_status),
                        }}
                      />
                    </Tooltip>
                  }
                  title={
                    <Row
                      justify="space-between"
                      gutter={[15, 15]}
                      style={{ margin: 0 }}
                    >
                      <span
                        style={{
                          color: Settings.colors.darkGrey,
                          fontWeight: to_read ? 700 : 500,
                          fontSize: 17,
                        }}
                      >
                        {order_title.length < 26
                          ? order_title
                          : `${order_title.substr(0, 23)}...`}
                      </span>
                    </Row>
                  }
                  description={
                    <Row
                      justify="space-between"
                      gutter={[15, 15]}
                      style={{ margin: 0 }}
                    >
                      <Col style={{ padding: 0 }}>
                        <span
                          style={{
                            color: to_read
                              ? Settings.colors.cyan
                              : Settings.colors.grey,
                            fontWeight: to_read ? 700 : 500,
                            fontSize: 14,
                          }}
                        >
                          ID: {" " + order_uid}
                        </span>
                      </Col>
                      <Col style={{ padding: 0 }}>
                        <span
                          style={{
                            color: Settings.colors.grey,
                            fontSize: 14,
                          }}
                        >
                          {last_update
                            ? _timeDifference(
                              Math.floor(Date.now()),
                              last_update
                            )
                            : null}
                        </span>
                      </Col>
                    </Row>
                  }
                />
              </List.Item>
            )}
          ></List>
        </Col>
      </>
    );
  }
}

const styles = {
  title: {
    color: Settings.colors.darkGrey,
    fontWeight: 700,
  },
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    ordini: state.ordini,
  };
}

export default withRouter(connect(mapStateToProps, null)(OrderList));
