import React from "react";

// Ant design component
import { Row, Col, Button } from "antd";

import { Link } from "react-router-dom";

// import custom components
import Footer from "../Components/Footer";

// import Settings
import Settings from "../Config/Settings";

class InfoComune extends React.Component {
  render() {
    return (
      <>
        {/* Main block */}
        <div
          style={{
            ...styles.main_block,
            background: Settings.colors.darkGrey,
          }}
        >
          {/* Navbar */}
          <div style={styles.navbar}>
            <Row align="middle">
              <Col xs={24} md={12}>
                <Link to="/">
                  <img
                    style={styles.logoGarzone}
                    src={require("../Assets/logo-inline.png")}
                  />
                </Link>
              </Col>
              <Col xs={0} md={12} style={{ textAlign: "right" }}>
                <Button
                  type="link"
                  href={Settings.base_url.negozio}
                  target="_blank"
                  style={{ color: Settings.colors.white }}
                >
                  Sei un negozio?
                </Button>
                <Link to="/per-i-comuni">
                  <Button type="link">Sei un comune?</Button>
                </Link>
              </Col>
            </Row>
          </div>
          {/* Content */}
          <div style={{ padding: "70px 20px" }}>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <h1 style={styles.title}>Garzone per i Comuni</h1>
                <h3 style={styles.subtitle}>
                  Fai ripartire il commercio locale, scegli Garzone e offri ai
                  tuoi cittadini un marketplace della tua città.
                </h3>
                <p style={{ color: Settings.colors.white }}>
                  Per saperne di più contattaci:
                </p>
                <p style={{ color: Settings.colors.white }}>
                  Email:{" "}
                  <a href="mailto:info@garzone.store">info@garzone.store</a>
                </p>
                <p style={{ color: Settings.colors.white }}>
                  Telefono: <a href="tel:+39026592398">026592398</a>
                </p>
              </Col>
            </Row>
          </div>
        </div>

        {/* Content */}
        <div style={{ padding: "70px 20px" }}>
          <Row gutter={[0, 70]}>
            <Col span={24}>
              <span style={styles.bold}>
                Garzone è un progetto di marketing territoriale e sociale
              </span>{" "}
              che ha come principale obiettivo quello di offrire un’opportunità
              di rilancio del commercio locale.
              <br />
              <br />
              E’ un’idea nata inizialmente durante il periodo di lockdown e che
              si è{" "}
              <span style={styles.bold}>
                affiliata al progetto “Solidarietà Digitale” promossa dal
                Ministero dell’Innovazione Tecnologica.
              </span>{" "}
              Successivamente si è poi evoluta sulla base dei consigli di varie
              attività commerciale e di amministrazioni pubbliche.
              <br />
              <br />
              Oggi ci troviamo di fronte a un mondo totalmente cambiato, i
              negozi di vicinato, infatti, a causa della recente pandemia si
              sono trovati a cambiare completamente il loro modello di business
              introducendo così nuovi servizi.{" "}
              <span style={styles.bold}>
                Consegna a domicilio, pick&pay e booking appuntamenti
              </span>{" "}
              sono ormai entrati a far parte della nostra nuova quotidianità.
              <br />
              <br />
              Garzone propone, proprio per questo motivo, una soluzione utile e
              innovativa per gestire in modo digitale tutti questi servizi.{" "}
              <span style={styles.bold}>
                Offre uno strumento che mette in contatto diretto il cittadino
                con tutti i negozi della città
              </span>{" "}
              aggregandoli in un grande marketplace virtuale.
            </Col>
            <Col span={24}>
              <div style={{ textAlign: "center" }}>
                <Button
                  size="large"
                  type="primary"
                  href={require("../Assets/Materiali/progetto-garzone-per-comune.pdf")}
                  target="_blank"
                >
                  Scarica presentazione
                </Button>
              </div>
            </Col>
            <Col span={24}>
              <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe
                  src="https://player.vimeo.com/video/454365468?color=ffffff&byline=0&portrait=0"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </Col>
            <Col span={24}>
              <h2
                style={{
                  fontWeight: "bold",
                  color: Settings.colors.darkGrey,
                }}
              >
                Il marketplace è personalizzato per ogni città?
              </h2>
              Garzone per ogni città aderente al progetto attiverà un
              marketplace personalizzato in cui saranno presenti solo i negozi
              di quel territorio. Il marketplace sarà brandizzato con il logo
              ufficiale del Comune e sarà possibile integrarlo con il sito
              ufficiale dell’Amministrazione Pubblica.
            </Col>
            <Col span={24}>
              <h2
                style={{
                  fontWeight: "bold",
                  color: Settings.colors.darkGrey,
                }}
              >
                Che tipologia di servizi offre Garzone?
              </h2>
              Garzone ha studiato una serie di tools che agevolano la vita del
              negozio di vicinato massimizzando in particolare la gestione di
              alcuni servizi come la consegna a domicilio, il pick&pay e il
              booking appuntamenti. I principali strumenti che Garzone mette a
              disposizione sono:
              <ul style={{ marginTop: "35px", paddingLeft: "20px" }}>
                <li>
                  <span style={styles.bold}>Vetrina negozio: </span>
                  una pagina dove è possibile presentare la propria attività
                  commerciale inserendo il logo, una descrizione e tutte le
                  principali informazioni: indirizzo, orari apertura,
                  connessione ai tuoi social, link al tuo sito…
                </li>
                <br />
                <li>
                  <span style={styles.bold}>Bacheca promozioni: </span>è una
                  sezione dedicata agli annunci promozionali, ti permetterà di
                  incuriosire e conquistare l’attenzione di un potenziale
                  cliente
                </li>
                <br />
                <li>
                  <span style={styles.bold}>
                    Catalogo prodotti e Catalogo Servizi:{" "}
                  </span>
                  come in un vero e-commerce si potrà aggiornare un catalogo
                  prodotti/servizi (foto, descrizione, prezzo, eventuale prezzo
                  scontato). Il cittadino consultando l’offerta commerciale
                  proposta potrà così effettuare degli ordini
                </li>
                <br />
                <li>
                  <span style={styles.bold}>Agenda condivisa: </span>
                  si avrà la possibilità di condividere la propria agenda per
                  così consentire ai clienti di richiedere la disponibilità per
                  un appuntamento
                </li>
                <br />
                <li>
                  <span style={styles.bold}>Chat: </span>
                  la piattaforma permetterà di rimanere in contatto diretto con
                  i propri clienti tramite a un sistema di chat interno al
                  sistema. Sarà il sistema migliore per confermare gli ordini o
                  perfezionare delle richieste
                </li>
                <br />
                <li>
                  <span style={styles.bold}>Gestione ordini: </span>
                  una volta confermato un ordine dal pannello di controllo si
                  potrà gestire avendolo sempre sott’occhio. Si potrà così
                  gestire eventuali consegne a domicilio o il ritiro dei
                  prodotti presso il tuo negozio
                </li>
              </ul>
            </Col>
            <Col span={24}>
              <h2
                style={{
                  fontWeight: "bold",
                  color: Settings.colors.darkGrey,
                }}
              >
                Il Comune può monitorare i negozi iscritti alla piattaforma?
              </h2>
              Garzone ha previsto un pannello di controllo dedicato direttamente
              al Comune per monitorare tutti i negozi che si iscriveranno al
              marketplace. Da questo pannello il Comune potrà decidere se
              attivare o disattivare gli account dei negozi. In aggiunta è
              prevista anche una bacheca di comunicazione diretta con tutti i
              negozi del marketplace per permettere uno scambio di informazioni
              di vario genere.
            </Col>
            <Col span={24}>
              <h2
                style={{
                  fontWeight: "bold",
                  color: Settings.colors.darkGrey,
                }}
              >
                Il Comune come può aderire a Garzone?
              </h2>
              Garzone propone, dopo un iniziale periodo di prova senza nessun
              vincolo da parte del Comune, una formula di noleggio annuale del
              sistema. Il noleggio è calibrato in base al numero potenziale dei
              negozi che si intende iscrivere nel marketplace.
            </Col>
            <Col span={24}>
              <h2
                style={{
                  fontWeight: "bold",
                  color: Settings.colors.darkGrey,
                }}
              >
                Garzone prevede un sistema di assistenza dei negozi?
              </h2>
              Garzone offre un supporto tecnico per tutti quei negozi che sono
              alle prime armi con le nuove tecnologie. All’interno della
              piattaforma ci saranno dei tutorial su come utilizzare al meglio
              il sistema e una casella email per un supporto tecnico. Lo staff
              di Garzone offre ai Comuni anche l’opportunità di organizzare
              anche dei webinar informativi sul mondo digitale in generale e
              nello specifico sull’uso della piattaforma.
            </Col>
          </Row>
        </div>

        <Footer />
      </>
    );
  }
}

const styles = {
  main_block: {
    position: "relative",
    width: "100%",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  bold: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  navbar: {
    height: "48px",
    borderBottom: "1px solid #647A90",
    padding: "6px 20px",
  },
  logoGarzone: {
    height: "35px",
  },
  title: {
    fontSize: "48px",
    color: Settings.colors.white,
    fontWeight: "bold",
    lineHeight: "60px",
  },
  subtitle: {
    color: Settings.colors.white,
    fontWeight: "600",
  },
  cta_text: {
    marginTop: "25px",
    color: Settings.colors.cyan,
    fontWeight: "bold",
    fontSize: "28px",
  },
};

export default InfoComune;
