import React, { Component } from "react";

// import redux
import { connect } from "react-redux";
import firebase from "firebase";

// import actions
import { setActualComune } from "../Actions/ComuniActions";

import { withRouter } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import Loading from "./Loading";

import { Row, Col } from "antd";

import Settings from "../Config/Settings";
import { getComune } from "../Controllers/Comuni";
import SideMenu from "./SideMenu";
import ModaleLoginUtente from "./ModaleLoginUtente";

class Wrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      visible_menu: false,
      logged: false,
    };
  }

  componentDidMount() {
    this._checkComune();
    var user = firebase.auth().currentUser;
    if (user) {
      this.setState({ logged: true });
    } else {
      this.setState({ logged: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.match.params) !==
      JSON.stringify(this.props.match.params)
    ) {
      this._checkComune();
    }
  }

  _checkComune = async () => {
    // get "slug" comune from url
    const { slug } = this.props.match.params;
    if (slug) {
      if (this.props.slug === null || this.props.slug !== slug) {
        // prelevo i dati del comune
        try {
          const comune = await getComune(slug);
          if (comune.data.length === 1) {
            const comune_to_save = {
              id: comune.data[0].id,
              slug: comune.data[0].slug,
              nome: comune.data[0].nome,
              logo: comune.data[0].logo,
              coords: { lat: +comune.data[0].latitudine, lng: +comune.data[0].longitudine }
            };
            this.props.setActualComune(comune_to_save);
            this.setState({ loading: false });
          } else {
            // redirect to 404
            this.props.history.push("/404");
          }
        } catch (e) {
          console.log(e);
          // redirect to 404
          this.props.history.push("/404");
        }
      } else {
        this.setState({ loading: false });
      }
    } else {
      // redirect to 404
      this.props.history.push("/404");
    }
  };

  _closeMenu = () => {
    this.setState({ visible_menu: false });
  };

  _openMenu = () => {
    this.setState({ visible_menu: true });
  };

  render() {
    const { visible_menu, loading } = this.state;
    return (
      <>
        {loading === true ? (
          <div>
            <Loading loading={loading} />
          </div>
        ) : (
          <>
            {/* Header bar */}
            <Header onMenuClick={this._openMenu} history={this.props.history} />
            {/* Menù di navigazione */}
            <SideMenu
              slug={this.props.slug}
              visible_menu={visible_menu}
              _closeMenu={this._closeMenu}
            />
            {/* Contenuto */}
            <Row>
              <Col span={24} style={styles.wrapper}>
                {this.props.children}
              </Col>
            </Row>

            {/* Footer */}
            <Footer />
            <ModaleLoginUtente />
          </>
        )}
      </>
    );
  }
}

const styles = {
  wrapper: {
    padding: "0px 15px",
  },
  menu_item: {
    borderBottom: "1px solid " + Settings.colors.lightGrey,
    padding: "10px 20px",
    color: Settings.colors.darkGrey,
  },
  menu_item_title: {
    fontSize: "18px",
    display: "block",
  },
  menu_item_subtitle: {
    fontSize: "14px",
    color: Settings.colors.red,
    display: "block",
  },
};

function mapStateToProps(state) {
  return {
    id: state.comuni.id,
    slug: state.comuni.slug,
    nome: state.comuni.nome,
    logo: state.comuni.logo,
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setActualComune: (comune) => dispatch(setActualComune(comune)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Wrapper)
);
