import firebase from "firebase";
import { Functions } from "../Config/Fire";

export async function demo() {
  const demo = Functions.httpsCallable("utente-demo");
  return await demo();
}

export async function registraUtente(data) {
  const registraUtente = Functions.httpsCallable("utente-registraUtente");
  return await registraUtente(data);
}
export async function registraUtenteFromGoogle(data) {
  const registraUtenteFromGoogle = Functions.httpsCallable(
    "utente-registraUtenteFromGoogle"
  );
  return await registraUtenteFromGoogle(data);
}
export async function getUtente(uid) {
  const getUtente = Functions.httpsCallable("utente-getUtente");
  return await getUtente(uid);
}

export async function aggiornaUtente(data) {
  const aggiornaUtente = Functions.httpsCallable("utente-aggiornaUtente");
  return await aggiornaUtente(data);
}

export async function updateUserMailchimp(vecchia_mail, nuova_mail) {
  const updateUserMailchimp = Functions.httpsCallable(
    "utente-updateUserMailchimp"
  );
  return await updateUserMailchimp({
    vecchia_mail: vecchia_mail,
    nuova_mail: nuova_mail,
  });
}

export function getUserChatsRef(uid_utente) {
  return firebase.database().ref("users/" + uid_utente + "/chats");
}

export async function getMessages(chat_sql_id) {
  const getMessages = Functions.httpsCallable("utente-getMessages");
  return await getMessages(chat_sql_id);
}
export async function aggiornaPermessi(data) {
  const aggiornaPermessi = Functions.httpsCallable("utente-aggiornaPermessi");
  return await aggiornaPermessi(data);
}
export async function postFirstMessage({
  id_negozio,
  id_utente,
  messaggio,
  type = null,
  extra,
}) {
  const postFirstMessage = Functions.httpsCallable("utente-postFirstMessage");
  return await postFirstMessage({
    id_negozio,
    id_utente,
    messaggio,
    type,
    extra,
  });
}

export async function postMessage({
  chat_uid,
  id_negozio,
  messaggio,
  type = null,
  extra,
}) {
  const postMessage = Functions.httpsCallable("utente-postMessage");
  return await postMessage({ chat_uid, id_negozio, messaggio, type, extra });
}

export async function postIndirizzi({ id, indirizzi }) {
  const postIndirizzi = Functions.httpsCallable("utente-postIndirizzi");
  return await postIndirizzi({ id, indirizzi });
}
export function getUserOrdersRef(uid_utente) {
  return firebase.database().ref("users/" + uid_utente + "/orders");
}
export async function getOrder({ firebaseOrderId, uid }) {
  const getOrder = Functions.httpsCallable("utente-getOrder");
  return await getOrder({ firebaseOrderId, uid });
}

export async function getProdotto({ id }) {
  const getProdotto = Functions.httpsCallable("utente-getProdotto");
  return await getProdotto({ id });
}
export async function changeOrderStatus({ id, status, from, to }) {
  const changeOrderStatus = Functions.httpsCallable("utente-changeOrderStatus");
  return await changeOrderStatus({ id, status, from, to });
}
export async function generaIdPagamentoStripe(orderUid) {
  const generaIdPagamentoStripe = Functions.httpsCallable(
    "utente-generaIdPagamentoStripe"
  );
  return await generaIdPagamentoStripe({ orderUid: orderUid });
}
export async function createOrder({
  idNegozio,
  idCliente,
  infoCliente,
  prodotti,
  note,
  creazione,
  ultimaModifica,
  tipoConsegna,
  indirizzo,
  tipoPagamento,
}) {
  const createOrder = Functions.httpsCallable("utente-createOrder");
  return await createOrder({
    idNegozio,
    idCliente,
    infoCliente,
    prodotti,
    note,
    creazione,
    ultimaModifica,
    tipoConsegna,
    indirizzo,
    tipoPagamento,
  });
}
export async function generaIdNegozioAppuntamenti() {
  const generaIdNegozioAppuntamenti = Functions.httpsCallable(
    "utente-generaIdNegozioAppuntamenti"
  );
  return await generaIdNegozioAppuntamenti();
}
